.desktop,
.tablet.landscape {
  .begin {
    [data-slide='1'] {
      .title {
        .part1 {
          transform: translate3d(4vw,0,0);
          opacity: 0;
        }
        .part2 {
          transform: translate3d(4.2vw,0,0);
          opacity: 0;
        }
        .part3 {
          transform: translate3d(4.4vw,0,0);
          opacity: 0;
        }
      }
      .subtitle {
        opacity: 0;
        transform: translate3d(0,90%,0);
      }
      .word {
        opacity: 0;
        transform: translate3d(0,80%,0);
      }
      .brands {
        .brand {
          transform: translate3d(0,50%,0);
          opacity: 0;
        }
      }
    }
    [data-slide='2'],
    [data-slide='3'],
    [data-slide='4'],
    [data-slide='5'],
    [data-slide='6'],
    [data-slide='7'],
    [data-slide='8'],
    [data-slide='9'],
    [data-slide='10'] {
      display: none;
    }
  }
}

.mobile,
.tablet.portrait {
  .begin {
    [data-slide='1'] {
      #finger-mobile {
        stroke-dashoffset: 516.1923;
      }
      .switcher {
        transform: translate3d(-10%,-7%,0);
        opacity: 0;
      }
      .light {
        opacity: 0;
        box-shadow: 0 0 20vh 10vh rgba(179,180,182,0.6);
        animation: none;
      }
      .key {
        transform: translate3d(-19%,-9%,0);
        opacity: 0;
        .key-lines {
          opacity: 0;
        }
      }
      .key-digits {
        opacity: 0;
      }
      #key_mobile_line1 {
        stroke-dashoffset: 102.4447;
      }
      #key_mobile_line2 {
        stroke-dashoffset: 142.1125;
      }
      #key_mobile_line3 {
        stroke-dashoffset: 199.2661;
      }
      #key_mobile_line4 {
        stroke-dashoffset: 134.7162;
      }
    }
  }
}