.btn {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  display: inline-block;
  &--light {
    border-color: $white;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    padding: 10px 32px;
    color: $white;
    @include media(hover,1025){
      &:hover {
        background-color: $white;
        color: $dark;
      }
    }
  }
  &--square-light {
    background-color: $white;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    text-align: center;
    min-width: 130px;
  }
  &--square-dark {
    background-color: $black;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    text-align: center;
    min-width: 130px;
    color: $white;
  }
  &--dark {
    border-color: $dark;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    padding: 10px 25px;
    color: $dark;
    &.current {
      background-color: $dark;
      color: $white;
    }
    @include media(hover,1025){
      &:hover {
        background-color: $dark;
        color: $white;
      }
    }
  }
}