.grid {
  &.opacity {
    visibility: hidden;
  }
}
.grid-layout {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  & > div {
    width: 100%;
    @include media(hover,681){
      padding: 0 0.8*$padding;
    }
  }
}