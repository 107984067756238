.page-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.75*$padding;
  color: $dark;
  transform: translate3d(0,0,0);
  @include media(hover,1025){
    font-size: 4.3rem;
  }
  @include breakpoints(681,1024){
    font-size: 3.5rem;
  }
  @include media(screen,680){
    font-size: 2.5rem;
  }
}