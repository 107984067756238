[data-elements="2"]{
  position: absolute;
  left: 12.7%;
  top: 7%;
  width: 39%;
  bottom: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  [data-current="5"] & {
    transition: transform 1.2s ease-in-out;
  }
  [data-current="6"] & {
    transform: translate3d(0,-100vh,0);
    transition: transform 1.2s cubic-bezier(.4,.5,0.7,.21), opacity 0.1s ease 1.4s;
  }
  [data-current="7"] & {
    opacity: 0;
  }
  .people-box {
    width: 80%;
    position: relative;
  }
  .part {
    width: 100%;
    padding-top: 92.476%;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.absolute {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .cassette {
    opacity: 0;
    .group {
      [data-current="3"] & {
        transition: all 1s linear;
      }
      [data-current="4"] & {
        transition: all 1s linear;
        transform: translate3d(-1%,-3%,0);
      }
    }
    [data-current="2"] & {
      transition: all 0.2s linear;
    }
    [data-current="3"] & {
      opacity: 1;
      transition: opacity 0.2s linear 0.5s;
    }
    [data-current="4"] & {
      opacity: 1;
    }
  }
  .icons {
    .group {
      transform-origin: 50% 50%;
      opacity: 0;
      [data-current="1"] & {

      }
      [data-current="2"] & {
        transition: opacity 0.2s linear, transform 0.1s ease 0.3s;
        opacity: 0;
      }
      [data-current="3"] & {
        opacity: 1;
        transform: rotate(-10deg);
        transition: transform 0.4s linear 1s, opacity 0.2s linear 0.2s;
      }
      [data-current="4"] & {
        opacity: 1;
        transition: transform 0.2s linear 1.2s;
      }
      [data-current="5"] & {
        opacity: 1;
        transform: rotate(20deg);
        transition: transform 0.4s linear 0.9s, opacity 0.2s linear 0.2s;
      }
      [data-current="6"] & {
        opacity: 1;
        transform: rotate(20deg);
      }

      &-icon {
        opacity: 0;
        [data-current="3"] & {
          opacity: 1;
        }
        [data-current="4"] & {
          opacity: 1;
        }
        [data-current="5"] & {
          opacity: 1;
        }
        [data-current="6"] & {
          opacity: 1;
        }
      }
      #icon01_1,
      #icon01_2 {
        [data-current="3"] & {
          transition: opacity 0.4s ease 0.3s;
        }
      }
      #icon02_1,
      #icon02_2 {
        [data-current="3"] & {
          transition: opacity 0.4s ease 0.4s;
        }
      }
      #icon03_1,
      #icon03_2 {
        [data-current="3"] & {
          transition: opacity 0.4s ease 0.5s;
        }
      }
      #icon04_1,
      #icon04_2 {
        [data-current="3"] & {
          transition: opacity 0.4s ease 0.6s;
        }
      }
      #icon05_1,
      #icon05_2 {
        [data-current="3"] & {
          transition: opacity 0.4s ease 0.7s;
        }
      }
      #icon06_1,
      #icon06_2 {
        [data-current="3"] & {
          transition: opacity 0.4s ease 0.8s;
        }
      }
    }
  }
  .calc {
    .num {
      opacity: 0;
      [data-current="3"] & {
        transition: all 0.3s;
      }
      [data-current="4"] & {
        transition: opacity 0.2s ease 0.5s;
        opacity: 1;
      }
    }
    #num01 {
      [data-current="4"] & {
        animation: calc_press 0.3s linear 0.6s alternate forwards;
      }
    }
    #num02 {
      [data-current="4"] & {
        animation: calc_press 0.3s linear 0.65s alternate forwards;
      }
    }
    #num03 {
      [data-current="4"] & {
        animation: calc_press 0.3s linear 0.7s alternate forwards;
      }
    }
    #num04 {
      [data-current="4"] & {
        animation: calc_press 0.3s linear 0.75s alternate forwards;
      }
    }
    #num05 {
      opacity: 0;
      [data-current="3"] & {
        transition: all 0.3s;
      }
      [data-current="4"] & {
        opacity: 1;
        transition: opacity 0.3s ease 0.8s;
      }
    }
    #vector {
      stroke-dasharray: 20.942;
      stroke-dashoffset: 20.942;
      [data-current="4"] & {
        transition: stroke-dashoffset 0.3s linear 0.6s;
        stroke-dashoffset: 0;
      }
    }
  }
  .key {
    [data-current="4"] & {
      opacity: 0;
      transition: opacity 0.2s ease;
    }
    #keypath1 {
      stroke-dasharray: 110.257;
      stroke-dashoffset: 110.257;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.3s linear 0.7s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #keypath2 {
      stroke-dasharray: 66.565;
      stroke-dashoffset: 66.565;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.3s linear 0.7s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #keypath3 {
      stroke-dasharray: 53.532;
      stroke-dashoffset: 53.532;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.3s linear 0.7s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #keypath4 {
      stroke-dasharray: 20.219;
      stroke-dashoffset: 20.219;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.3s linear 0.7s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #keypath5 {
      stroke-dasharray: 47.874;
      stroke-dashoffset: 47.874;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.3s linear 0.7s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #keypath6 {
      stroke-dasharray: 28.155;
      stroke-dashoffset: 28.155;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.3s linear 0.7s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
  }
  .gadget {
    opacity: 0;
    transform-origin: center 0;
    [data-current="4"] & {
      transition: opacity 0.2s ease, transform 0.1s ease 0.3s;
      transform: scale(0.3);
    }
    [data-current="5"] & {
      opacity: 1;
      transition: all 0.4s ease 0.6s;
    }
    [data-current="6"] & {
      opacity: 1;
    }
  }
  .right-man {
    [data-current="3"] & {
      transition: opacity 0.2s linear 0.5s;
    }
    [data-current="4"] & {
      transition: opacity 0.2s linear;
      opacity: 0;
    }
    #man_right_glass {
      stroke-dasharray: 27.7802;
      stroke-dashoffset: 27.7802;
      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.1s linear 0.6s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_right_hat {
      stroke-dasharray: 166.1476;
      stroke-dashoffset: 166.1476;
      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.3s linear 0.6s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_right_hand_01 {
      stroke-dasharray: 106.7926;
      stroke-dashoffset: 106.7926;
      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.3s linear 0.8s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_right_hand_02 {
      stroke-dasharray: 96.5929;
      stroke-dashoffset: 96.5929;

      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.2s linear 0.9s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_right_hand_03 {
      stroke-dasharray: 220.2593;
      stroke-dashoffset: 220.2593;

      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.3s linear 0.9s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_right_hand_04 {
      stroke-dasharray: 92.5027;
      stroke-dashoffset: 92.5027;

      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.1s linear 1.1s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 1s;
      }
    }


  }
  .left-man {
    #man_left_body {
      stroke-dasharray: 209.985;
      stroke-dashoffset: -209.985;
      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.5s linear 0.2s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        stroke-dashoffset: 0;
      }
      [data-current="5"] & {
        stroke-dashoffset: 0;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #man_left_face {
      stroke-dasharray: 41.6786;
      stroke-dashoffset: 41.6786;
      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.2s linear 0.3s;
        stroke-dashoffset: 5;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.2s linear 0.3s;
        stroke-dashoffset: 0;
      }
      [data-current="5"] & {
        transition: stroke-dashoffset 0.2s linear 0.3s;
        stroke-dashoffset: 5;
      }
      [data-current="6"] & {
        stroke-dashoffset: 5;
      }
    }
    #man_left_hand_01 {
      stroke-dasharray: 209.153;
      stroke-dashoffset: 209.153;
      [data-current="2"] & {
        opacity: 0;
        transition: all 0.2s linear;
      }
      [data-current="3"] & {
        transition: stroke-dashoffset 0.5s linear 0.2s;
        stroke-dashoffset: 0;
      }
      [data-current="4"] & {
        stroke-dashoffset: 0;
        transition: opacity 0.2s ease, stroke-dashoffset 0.2s linear 0.2s;
      }
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.2s ease 0.4s, stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_left_hand_02 {
      stroke-dasharray: 206.088;
      stroke-dashoffset: 206.088;
      [data-current="3"] & {
        transition: stroke-dashoffset 0.3s linear;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.6s linear 0.1s, opacity 0.4s ease;
        stroke-dashoffset: -34;
      }
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.2s ease, stroke-dashoffset 0.1s linear 1s;
      }
    }
    #man_left_fingers01 {
      stroke-dasharray: 9.252;
      stroke-dashoffset: 9.252;
      [data-current="3"] & {
        transition: stroke-dashoffset 0.1s linear;
      }
      [data-current="4"] & {
        transition: stroke-dashoffset 0.1s linear 0.5s;
        stroke-dashoffset: 0;
      }
    }
    #man_left_fingers02 {
      stroke-dasharray: 68.8853;
      stroke-dashoffset: -68.8853;
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.2s linear 0.8s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #man_left_hand_03 {
      stroke-dasharray: 196.3367;
      stroke-dashoffset: 196.3367;
      [data-current="4"] & {
        transition: stroke-dashoffset 0.2s linear;
      }
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.5s linear 0.4s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
    #man_left_hand_04 {
      stroke-dasharray: 211.3337;
      stroke-dashoffset: -211.3337;
      [data-current="4"] & {

      }
      [data-current="5"] & {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0.5s linear 0.2s;
      }
      [data-current="6"] & {
        stroke-dashoffset: 0;
      }
    }
  }
  .sms {
    .text1 {
      opacity: 0;
      [data-current="3"] & {
        transition: opacity 0.2s linear;
      }
      [data-current="4"] & {
        transition: opacity 0.2s linear 0.8s;
        opacity: 1;
      }
    }
    .text2 {
      opacity: 0;
      [data-current="3"] & {
        transition: opacity 0.2s linear;
      }
      [data-current="4"] & {
        transition: opacity 0.2s linear 1s;
        opacity: 1;
      }
    }
    .boxes {
      [data-current="3"] & {
        opacity: 0;
        transition: opacity 0.2s linear;
      }
    }
    #letter1 {
      stroke-dasharray: 35.6524;
      stroke-dashoffset: 35.6524;
      [data-current="4"] & {
        transition: stroke-dashoffset 0.2s linear 0.75s;
        stroke-dashoffset: 0;
      }
    }
    #box1 {
      stroke-dasharray: 57.4683;
      stroke-dashoffset: 57.4683;
      [data-current="4"] & {
        transition: stroke-dashoffset 0.2s linear 0.7s;
        stroke-dashoffset: 0;
      }
    }
    #box2 {
      stroke-dasharray: 100.6875;
      stroke-dashoffset: 100.6875;
      [data-current="4"] & {
        transition: stroke-dashoffset 0.2s linear 1s;
        stroke-dashoffset: 0;
      }
    }
    #box3 {
      stroke-dasharray: 109.895;
      stroke-dashoffset: 109.895;
      [data-current="4"] & {
        transition: stroke-dashoffset 0.2s linear 1.2s;
        stroke-dashoffset: 0;
      }
    }
  }
  .switcher-mini {
    position: absolute;
    left: 10%;
    top: 7%;
    opacity: 0;
    transform: scale(0.66) translate3d(-140%,-2%,0);
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      border-radius: 10px;
      background-color: $dark;
      box-shadow: 2vw 0 7vw 4vw $dark;
    }
    img {
      display: block;
      width: 100vw;
      height: auto;
      max-width: 20vw;
      max-height: 60vh;
    }
    [data-current="4"] & {
      transition: opacity 0.6s ease, transform 0.6s ease;
    }
    [data-current="5"] & {
      opacity: 0.3;
      transition: opacity 0.9s ease 0.5s, transform 0.9s ease 0.5s;
      transform: scale(0.66) translate3d(-135%,2%,0);
    }
    [data-current="6"] & {
      opacity: 0;
      transform: scale(0.66) translate3d(-140%,-2%,0);
      transition: opacity 0.4s ease 0.1s, transform 0.1s ease 1.4s;
    }

  }
  #line01 {
    stroke-dasharray: 56.845;
    stroke-dashoffset: 56.845;
    [data-current="2"] & {
      opacity: 0;
      transition: all 0.2s linear;
    }
    [data-current="3"] & {
      transition: stroke-dashoffset 0.4s linear 0.8s;
      stroke-dashoffset: 0;
    }
    [data-current="4"] & {
      transition: stroke-dashoffset 0.2s linear 1.2s;
      stroke-dashoffset: 16;
    }
  }
  #line02 {
    stroke-dasharray: 30.347;
    stroke-dashoffset: -30.347;
    [data-current="5"] & {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.1s linear 0.7s;
    }
    [data-current="6"] & {
      stroke-dashoffset: 0;
    }
  }
  #line03 {
    stroke-dasharray: 80.455;
    stroke-dashoffset: 80.455;
    [data-current="5"] & {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.4s linear 1s;
    }
    [data-current="6"] & {
      stroke-dashoffset: 0;
    }
  }
}

@keyframes calc_press {
  0% {
    fill: none;
  }
  50% {
    fill: #b3b3b3;
  }
  100% {
    fill: none;
  }
}