.text-box {
  line-height: 1.55;
  margin-bottom: 4rem;
  @include media(hover,681){
    font-size: 1.3rem;
  }
  @include media(screen,680){
    font-size: 1.5rem;
  }
  .single-article & {
    @include media(hover,681){
      padding: 0 0.66*$padding;
    }
  }
  p {
    margin-bottom: 2rem;
    min-height: 1px;
  }
  ul {
    margin-bottom: 2rem;
    li {
      position: relative;
      margin-bottom: 0.5rem;
      padding-left: 20px;
      &:before {
        content: "";
        position: absolute;
        top: 0.85rem;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $dark;
      }
    }
  }
  ol {
    margin-bottom: 2rem;
    counter-reset: item;
    li {
      position: relative;
      margin-bottom: 0.5rem;
      padding-left: 20px;
      &:before {
        position: absolute;
        top: 0.2rem;
        font-size: 1.1rem;
        font-weight: 600;
        left: 0;
        content: counter(item, decimal)'.';
        counter-increment: item;
      }
    }
  }
  img {
    display: block;
    max-width: 100%;
    &.right-align {
      margin-bottom: 1.5rem;
    }
    &.left-align {
      margin-bottom: 1.5rem;
    }
    &:not(.right-align):not(.left-align) {
      width: auto;
      height: auto;
      margin: 0 auto 2rem;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  h1 {font-size: 3.5rem;}
  h2 {font-size: 2.8rem;}
  h3 {font-size: 2.14rem;}
  h4 {font-size: 1.7rem;}
  h5 {font-size: 1.4rem;}
  h6 {font-size: 1.2rem;}
  strong, b {
    font-weight: 700;
  }
  em, i {
    font-style: italic;
  }
  a {
    color: inherit;
    border-bottom: 1px solid;
    @include media(hover,1025){
      &:hover {
        border-bottom-width: 2px;
      }
    }
  }
  .iframe {
    position: relative;
    padding-top: 60%;
    margin-bottom: 2rem;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    width: 100%;
  }
  blockquote {
    position: relative;
    padding: 3rem 0;
    font-size: 1.7rem;
    font-style: italic;
    margin: 4rem auto;
    text-align: center;
    max-width: 600px;
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 90px;
      height: 1px;
      background-color: currentColor;
      left: 0;
      right: 0;
      margin: auto;
    }
    &:before {top: 0;}
    &:after {bottom: 0;}
  }
  .flex-parts {

    @include media(hover,681){
      display: flex;
      align-items: center;
      & > div {
        width: 50%;
        &:first-child {padding-right: 1.5rem}
        &:last-child {padding-left: 1.5rem}
      }
    }

  }
  .quote-parts {

    @include media(hover,1025){
      margin: 0 -80px;
    }
    *:not(img){
      text-align: left;
    }
    @include media(hover,681){
      display: flex;
      align-items: center;
      & > div {
        width: 50%;
        &:first-child {
          padding-right: 1.5rem;
          @include media(hover,1025){
            *:not(img){
              margin-left: 80px;
            }
          }
        }
        &:last-child {
          padding-left: 1.5rem;
          @include media(hover,1025){
            *:not(img){
              margin-right: 80px;
            }
          }
        }
      }
    }

  }
  .right-align {
    @include media(hover,681){
      float: right;
      max-width: calc(50% - 2rem);
      margin-left: 2rem;
    }
    @include media(screen,680){
      width: 100%;
      height: auto;
    }
  }
  .left-align {
    @include media(hover,681){
      float: left;
      max-width: calc(50% - 2rem);
      margin-right: 2rem;
    }
    @include media(screen,680){
      width: 100%;
      height: auto;
    }
  }

}