.desktop,
.tablet.landscape {
  [data-slide='1'] {
    .slide-right {
      display: flex;
      align-items: center;
    }
    .head {
      width: 80%;
      @include media(hover,1481){
        margin-bottom: 5vh;
      }
      @include breakpoints(1201,1480){
        margin-bottom: 3vh;
      }
    }
    .title {
      color: $white;
      span {
        display: block;
      }
      .part1 {
        transition: transform 1.7s ease 1s, opacity 1.7s ease 1.1s;
        opacity: 1;
        transform: translate3d(0,0,0);
      }
      .part2 {
        transition: transform 2s ease 1.3s, opacity 1.7s ease 1.4s;
        opacity: 1;
        transform: translate3d(0,0,0);
      }
      .part3 {
        transition: transform 2.3s ease 1.6s, opacity 1.7s ease 1.7s;
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
    .subtitle {
      color: #bebebf;
      transition: transform 2s ease 1.5s, opacity 1.5s ease-in 2s;
      opacity: 0.8;
      transform: translate3d(0,0,0);
    }
    .word {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16%;
      transition: transform 3s, opacity 3.5s ease-in 0.1s;
      transform: translate3d(0,0,0);
    }
    .brands {
      position: absolute;
      top: 20vh;
      bottom: 26vh;
      right: 2vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: 1921px){
        right: 1vw;
      }
      .brand {
        display: flex;
        align-items: center;
        margin-bottom: 1vh;
        opacity: 0.3;
        transform: translate3d(0,0,0);
        img {
          max-width: 100%;
          width: 7vw;
          height: auto;
          @media screen and (max-height: 46vw) {
            height: 9vh;
            width: auto;
          }
        }
        &:nth-child(1){
          transition: all 1.2s ease 1s;
        }
        &:nth-child(2){
          transition: all 1.2s ease 1.05s;
        }
        &:nth-child(3){
          transition: all 1.2s ease 1.1s;
        }
        &:nth-child(4){
          transition: all 1.2s ease 1.15s;
        }
      }
    }
  }
}

.desktop {
  [data-slide='1'] {
    .slide-img {
      display: none;
    }
  }
}
.tablet.landscape {
  [data-slide='1'] {
    height: 100vh;
    .slide-img {
      position: absolute;
      left: 4.6%;
      top: 9%;
      width: 50%;
      bottom: 4%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .switch-box {
      position: relative;
      display: flex;
      align-items: center;
      .switcher {
        position: relative;
        transition: opacity 2s ease 0.4s, transform 2.5s ease 0.4s;
        img {
          display: block;
          height: auto;
          width: 30vw;
          max-width: 45vh;
        }
      }
      .light {
        left: 27%;
        top: 34%;
        position: absolute;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #b3b4b6;
        transition: box-shadow 0.5s ease, opacity 2s ease 0.5s;
        will-change: transform;
        box-shadow: 0 0 30vh 20vh rgba(179,180,182,0.6);

      }
      .key,
      .key-digits {
        position: absolute;
        left: -27%;
        top: -5%;
        width: 43%;
        padding-top: 103.2%;
        img, svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .key {
        transition: opacity 1.4s ease, transform 1.4s ease 0.5s;
        .key-lines {
          transition: opacity 1s ease 1s;
        }
      }
      .key-digits {
        transition: opacity 1.4s ease 0.5s;
      }
      #key_mobile_line1 {
        stroke-dasharray: 102.4447;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 102.4447;
        }
      }
      #key_mobile_line2 {
        stroke-dasharray: 142.1125;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 142.1125;
        }
      }
      #key_mobile_line3 {
        stroke-dasharray: 199.2661;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 199.2661;
        }
      }
      #key_mobile_line4 {
        stroke-dasharray: 134.7162;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 134.7162;
        }
      }
      .finger {
        position: absolute;
        left: 44%;
        top: 11%;
        width: 76%;
        padding-top: 141.2%;
        svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      #finger-mobile {
        stroke-dasharray: 516.1923;
        stroke-dashoffset: 0;
        transition: all 1.3s linear 0.5s;

      }
    }
  }
}

.tablet {
  [data-slide='1'] {
    .switch-box {
      .light {
        display: none;
      }
    }
  }
}

.mobile,
.tablet.portrait {
  [data-slide='1'] {
    padding: 20px;
    .brands {
      display: none;
    }
    .title {
      color: $white;
      span {
        display: block;
      }
    }
    .subtitle {
      color: #bebebf;
      margin-bottom: 4vh;
    }
    .slide-img {
      padding: 15% 0 20% 0;
      display: flex;
      justify-content: center;
    }
    .switch-box {
      position: relative;
      display: flex;
      align-items: center;
      .switcher {
        position: relative;
        transition: opacity 2s ease 0.4s, transform 2.5s ease 0.4s;
        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 50vw;
        }
      }

      .light {
        left: 27%;
        top: 34%;
        position: absolute;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #b3b4b6;
        transition: box-shadow 0.5s ease, opacity 2s ease 0.5s;
        will-change: transform;
        box-shadow: 0 0 30vh 20vh rgba(179,180,182,0.6);
        animation: light 10s infinite 0.5s alternate forwards;
      }
      .key,
      .key-digits {
        position: absolute;
        left: -27%;
        top: -5%;
        width: 43%;
        padding-top: 103.2%;
        img, svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .key {
        transition: opacity 1.4s ease, transform 1.4s ease 0.5s;
        .key-lines {
          transition: opacity 1s ease 1s;
        }
      }
      .key-digits {
        transition: opacity 1.4s ease 0.5s;
      }
      #key_mobile_line1 {
        stroke-dasharray: 102.4447;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 102.4447;
        }
      }
      #key_mobile_line2 {
        stroke-dasharray: 142.1125;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 142.1125;
        }
      }
      #key_mobile_line3 {
        stroke-dasharray: 199.2661;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 199.2661;
        }
      }
      #key_mobile_line4 {
        stroke-dasharray: 134.7162;
        stroke-dashoffset: 0;
        transition: all 1s linear 0.5s;
        .begin & {
          stroke-dashoffset: 134.7162;
        }
      }
      .finger {
        position: absolute;
        left: 44%;
        top: 11%;
        width: 76%;
        padding-top: 141.2%;
        svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      #finger-mobile {
        stroke-dasharray: 516.1923;
        stroke-dashoffset: 0;
        transition: all 1.3s linear 0.5s;

      }
    }
  }
}