.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease 0.3s;
  pointer-events: none;
  opacity: 0;
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
  svg {
    height: 80px;
    width: 80px;
  }
}