.desktop {
  [data-slide='3'] {
    transform: translate3d(0,100%,0);
    .slide-right {
      display: flex;
      align-items: center;
      padding-left: 75px;
    }
    .head {
      width: 80%;
      @include media(hover,1481){
        margin-bottom: 4vh;
      }
      @include breakpoints(1201,1480){
        margin-bottom: 3vh;
      }
    }
    .title {
      color: $white;
      span {
        display: block;
      }
      .part1 {
        color: #b3b3b3;
      }
      .part2 {
        color: #b3b3b3;
      }
      .part3 {

      }
    }
    .subtitle {
      color: #bebebf;
      opacity: 0.8;
    }
    .word {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 12%;
      transform: translate3d(0,0,0);
    }
    .tablet-light,
    .slide-img {
      display: none;
    }
  }
}

.tablet.landscape {
  [data-slide='3'] {
    padding: 15vh 0 30vh;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      width: 1px;
      background-color: $white;
      left: 50%;
      opacity: 0.2;
    }
    .tablet-light {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 80px 7px rgba($white,0.95);
      position: absolute;
      left: 1px;
      right: 0;
      top: 20vh;
      z-index: 1;
      margin: -4px auto 0;
      animation: small_light 2s infinite linear alternate;
    }
    .slide-right {
      display: flex;
      align-items: center;
      padding-left: 75px;
    }
    .head {
      width: 80%;
      @include media(hover,1481){
        margin-bottom: 4vh;
      }
      @include breakpoints(1201,1480){
        margin-bottom: 3vh;
      }
    }
    .title {
      color: $white;
      span {
        display: block;
      }
      .part1 {
        color: #b3b3b3;
      }
      .part2 {
        color: #b3b3b3;
      }
      .part3 {

      }
    }
    .subtitle {
      color: #bebebf;
      opacity: 0.8;
    }
    .word {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 12%;
      transform: translate3d(0,0,0);
    }
    .slide-img {
      position: absolute;
      left: 2%;
      top: 4%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .people-box {
        width: 90%;
        position: relative;
        max-width: 65vh;
      }
      .part {
        width: 100%;
        padding-top: 92.476%;
        svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &.absolute {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .icons {
        .group {
          transform-origin: 50% 50%;
        }
      }
    }
  }
}

.mobile,
.tablet.portrait {
  [data-slide='3'] {
    padding: 20px;
    margin-bottom: 90px;
    .title {
      color: $white;
      span {
        display: block;
      }
      .part1 {
        color: #b3b3b3;
      }
      .part2 {
        color: #b3b3b3;
      }
    }
    .subtitle {
      color: #bebebf;
    }
    .tablet-light {
      display: none;
    }
    .slide-img {
      padding: 2% 0 10% 0;
      display: flex;
      justify-content: center;
      .people-box {
        width: 90%;
        position: relative;
      }
      .part {
        width: 100%;
        padding-top: 92.476%;
        svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &.absolute {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .icons {
        .group {
          transform-origin: 50% 50%;
        }
      }
    }
    &:after {
      content: "";
    }
  }
}

.tablet,
.mobile {
  [data-slide='3'] {
    .man_left_p1,
    .man_left_p2,
    .man_left_p3,
    .man_right_p1,
    .man_right_p2,
    .man_right_p3,
    .man_right_p4,
    .man_right_p5,
    .man_right_p6 {
      transition: stroke-dashoffset 0.5s linear;
    }
    .lines,
    .icons,
    .cassette {
      opacity: 0;
      transition: opacity 0.5s linear;
    }

    .man_left_p1 {
      stroke-dasharray: 209.985;
      stroke-dashoffset: -209.985;
    }
    .man_left_p2 {
      stroke-dasharray: 41.6786;
      stroke-dashoffset: 41.6786;
    }
    .man_left_p3 {
      stroke-dasharray: 209.153;
      stroke-dashoffset: 209.153;
    }
    .man_right_p1 {
      stroke-dasharray: 92.503;
      stroke-dashoffset: 92.503;
    }
    .man_right_p2 {
      stroke-dasharray: 27.780;
      stroke-dashoffset: 27.780;
    }
    .man_right_p3 {
      stroke-dasharray: 96.592;
      stroke-dashoffset: 96.592;
    }
    .man_right_p4 {
      stroke-dasharray: 106.792;
      stroke-dashoffset: 106.792;
    }
    .man_right_p5 {
      stroke-dasharray: 166.147;
      stroke-dashoffset: 166.147;
    }
    .man_right_p6 {
      stroke-dasharray: 220.259;
      stroke-dashoffset: 220.259;
    }

    .slide-img.active {
      .man_left_p2,
      .man_left_p1,
      .man_left_p3,
      .man_right_p1,
      .man_right_p2,
      .man_right_p3,
      .man_right_p4,
      .man_right_p5,
      .man_right_p6 {
        stroke-dashoffset: 0;
      }
      .lines,
      .icons,
      .cassette {
        opacity: 1;
      }
    }
  }
}