html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

* {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

template,
[hidden] {
  display: none; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  outline: none; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

iframe,
img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

address, caption, cite, code, dfn, th, var {
  font-weight: normal;
  font-style: normal; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font: inherit;
  margin: 0; }

optgroup {
  font-weight: bold; }

button,
input {
  overflow: visible; }

button,
select {
  cursor: pointer; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 0; }

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/*---libs---*/
/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*---styles---*/
/* k=0.3 */
/*
.mobile &,
.tablet & {

}
.desktop & {

  @media screen and (max-height: 46vw) {

  }
}
*/
.header .logo img {
  width: 100%;
  height: 100%;
  display: block; }

* {
  box-sizing: border-box; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

.tableBox {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%; }
  .tableBox > div {
    display: table;
    width: 100%;
    height: 100%; }
    .tableBox > div > div {
      display: table-cell;
      vertical-align: middle;
      height: 100%; }

.content {
  margin: 0 auto;
  display: block; }
  @media screen and (min-width: 1025px) {
    .content {
      padding: 0 50px; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    .content {
      padding: 0 32.5px; } }
  @media screen and (max-width: 680px) {
    .content {
      padding: 0 20px; } }
  .content--lg {
    max-width: 1920px; }
  .content--md {
    max-width: 1710px; }
  .content--sm {
    max-width: 980px; }
  .content--xs {
    max-width: 480px; }

.hide {
  display: none !important; }

.invalid {
  color: rgba(255, 128, 124, 0.5);
  display: flex; }
  .invalid input, .invalid textarea {
    background-color: #ff807d; }

@media screen and (min-width: 768px) {
  .desktop-hidden {
    display: none !important; } }

@media screen and (max-width: 1200px) {
  .tablet-hidden {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .mobile-hidden {
    display: none !important; } }

@font-face {
  font-family: Noto;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSans.ttf"), url("../fonts/NotoSans.woff"), url("../fonts/NotoSans.eot"); }

@font-face {
  font-family: Noto;
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/NotoSans-Italic.ttf"), url("../fonts/NotoSans-Italic.woff"), url("../fonts/NotoSans-Italic.eot"); }

@font-face {
  font-family: Noto;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NotoSans-Bold.ttf"), url("../fonts/NotoSans-Bold.woff"), url("../fonts/NotoSans-Bold.eot"); }

@font-face {
  font-family: Noto;
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/NotoSans-BoldItalic.ttf"), url("../fonts/NotoSans-BoldItalic.woff"), url("../fonts/NotoSans-BoldItalic.eot"); }

html {
  height: 100%; }
  html.ovh {
    overflow: hidden; }
  @media screen and (min-width: 1201px) {
    html {
      font-size: 14px; } }
  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    html {
      font-size: 13px; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    html {
      font-size: 12px; } }
  @media screen and (max-width: 680px) {
    html {
      font-size: 11px; } }

body {
  line-height: 1.4;
  font-weight: 400;
  color: #4d4c4c;
  font-family: 'Noto', sans-serif;
  height: 100%; }

a {
  outline: none;
  transition: all 0.3s; }

svg {
  display: block;
  width: 100%;
  height: 100%; }

#main {
  position: relative;
  width: 100%;
  display: table-row;
  height: 100%; }

@media screen and (min-width: 681px) {
  .main > *:first-child {
    margin-top: 105px; } }

@media screen and (max-width: 680px) {
  .main > *:first-child {
    margin-top: 85px; } }

#wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; }
  #wrapper.no-transition .elements * {
    transition: none !important; }

.container {
  width: 100%;
  display: table;
  min-height: 100vh; }
  .container > .footer {
    display: table-footer-group; }

.dark-theme {
  background-color: #17191f; }

.header {
  background-color: #17191f;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  transition: all 0.2s; }
  .desktop .header.no-bg {
    background-color: transparent; }
    @media screen and (min-width: 1201px) {
      .desktop .header.no-bg .logo {
        transform-origin: left center;
        transform: scale(1.5); } }
  .tablet .header.no-bg {
    background-color: #000; }
    .tablet .header.no-bg:not(.minimized) {
      background-color: transparent; }
      .tablet .header.no-bg:not(.minimized) .header--main {
        height: 120px; }
      .tablet .header.no-bg:not(.minimized) .header--main:after {
        background-color: transparent; }
      .tablet .header.no-bg:not(.minimized) .nav {
        background-color: transparent; }
      .tablet .header.no-bg:not(.minimized) .logo {
        transform-origin: left center;
        transform: scale(1.5); }
  .desktop [data-current="1"] .header.no-bg,
  .tablet.landscape [data-current="1"] .header.no-bg {
    background-color: transparent; }
  .desktop [data-current="2"] .header.no-bg,
  .tablet.landscape [data-current="2"] .header.no-bg, .desktop
  [data-current="3"] .header.no-bg,
  .tablet.landscape
  [data-current="3"] .header.no-bg, .desktop
  [data-current="4"] .header.no-bg,
  .tablet.landscape
  [data-current="4"] .header.no-bg, .desktop
  [data-current="5"] .header.no-bg,
  .tablet.landscape
  [data-current="5"] .header.no-bg, .desktop
  [data-current="6"] .header.no-bg,
  .tablet.landscape
  [data-current="6"] .header.no-bg, .desktop
  [data-current="7"] .header.no-bg,
  .tablet.landscape
  [data-current="7"] .header.no-bg, .desktop
  [data-current="8"] .header.no-bg,
  .tablet.landscape
  [data-current="8"] .header.no-bg, .desktop
  [data-current="9"] .header.no-bg,
  .tablet.landscape
  [data-current="9"] .header.no-bg, .desktop
  [data-current="10"] .header.no-bg,
  .tablet.landscape
  [data-current="10"] .header.no-bg {
    background-color: #000; }
    @media screen and (min-width: 1201px) {
      .desktop [data-current="2"] .header.no-bg .header--main,
      .tablet.landscape [data-current="2"] .header.no-bg .header--main, .desktop
      [data-current="3"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="3"] .header.no-bg .header--main, .desktop
      [data-current="4"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="4"] .header.no-bg .header--main, .desktop
      [data-current="5"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="5"] .header.no-bg .header--main, .desktop
      [data-current="6"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="6"] .header.no-bg .header--main, .desktop
      [data-current="7"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="7"] .header.no-bg .header--main, .desktop
      [data-current="8"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="8"] .header.no-bg .header--main, .desktop
      [data-current="9"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="9"] .header.no-bg .header--main, .desktop
      [data-current="10"] .header.no-bg .header--main,
      .tablet.landscape
      [data-current="10"] .header.no-bg .header--main {
        height: 60px; }
      .desktop [data-current="2"] .header.no-bg .logo,
      .tablet.landscape [data-current="2"] .header.no-bg .logo, .desktop
      [data-current="3"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="3"] .header.no-bg .logo, .desktop
      [data-current="4"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="4"] .header.no-bg .logo, .desktop
      [data-current="5"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="5"] .header.no-bg .logo, .desktop
      [data-current="6"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="6"] .header.no-bg .logo, .desktop
      [data-current="7"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="7"] .header.no-bg .logo, .desktop
      [data-current="8"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="8"] .header.no-bg .logo, .desktop
      [data-current="9"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="9"] .header.no-bg .logo, .desktop
      [data-current="10"] .header.no-bg .logo,
      .tablet.landscape
      [data-current="10"] .header.no-bg .logo {
        transform: scale(1); } }
    @media screen and (max-width: 1200px) {
      .desktop [data-current="2"] .header.no-bg,
      .tablet.landscape [data-current="2"] .header.no-bg, .desktop
      [data-current="3"] .header.no-bg,
      .tablet.landscape
      [data-current="3"] .header.no-bg, .desktop
      [data-current="4"] .header.no-bg,
      .tablet.landscape
      [data-current="4"] .header.no-bg, .desktop
      [data-current="5"] .header.no-bg,
      .tablet.landscape
      [data-current="5"] .header.no-bg, .desktop
      [data-current="6"] .header.no-bg,
      .tablet.landscape
      [data-current="6"] .header.no-bg, .desktop
      [data-current="7"] .header.no-bg,
      .tablet.landscape
      [data-current="7"] .header.no-bg, .desktop
      [data-current="8"] .header.no-bg,
      .tablet.landscape
      [data-current="8"] .header.no-bg, .desktop
      [data-current="9"] .header.no-bg,
      .tablet.landscape
      [data-current="9"] .header.no-bg, .desktop
      [data-current="10"] .header.no-bg,
      .tablet.landscape
      [data-current="10"] .header.no-bg {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5); } }
  .tablet.portrait .header.no-bg,
  .mobile .header.no-bg {
    background-color: #000;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5); }
  .header.no-bg ~ .main > :first-child {
    margin-top: 0; }
  @media screen and (min-width: 1201px) {
    .mobile .header.no-bg ~ .main .landing {
      padding-top: 140px; } }
  @media screen and (max-width: 1200px) {
    .mobile .header.no-bg ~ .main .landing {
      padding-top: 80px; } }
  @media screen and (min-width: 681px) and (max-width: 1200px) {
    .header.no-bg {
      transform: translateY(-45px); }
      .header.no-bg .header--main {
        padding-top: 45px; } }
  @media screen and (max-width: 680px) {
    .header.no-bg {
      transform: translateY(-25px); }
      .header.no-bg .header--main {
        padding-top: 25px; } }
  [data-current="10"] .header.no-bg {
    transform: translateY(-100%);
    transition: 1s ease-in-out; }
  [data-current="9"] .header {
    transition: 1s ease-in-out; }
  [data-current="10"] .header {
    transform: translateY(-100%);
    transition: 1s ease-in-out; }
  .header .logo {
    width: 154px;
    height: 35px;
    position: relative;
    z-index: 2; }
    @media screen and (min-width: 1201px) {
      .header .logo {
        transform-origin: left center;
        transform: scale(1.5); } }
  .header .burger {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 2; }
    .header .burger span {
      width: 40px;
      height: 4px;
      position: absolute;
      border-radius: 4px;
      top: 8px;
      transition: background-color 0s linear .13s;
      background-color: #fff;
      display: block; }
      .header .burger span:before, .header .burger span:after {
        content: "";
        display: block;
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #fff;
        will-change: top; }
      .header .burger span:before {
        top: 10px;
        transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
      .header .burger span:after {
        top: 20px;
        transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    @media screen and (min-width: 1025px) {
      .header .burger {
        display: none; } }
    @media screen and (max-width: 1024px) {
      .header .burger--check:checked ~ .burger span {
        transition-delay: .22s;
        background-color: transparent; }
        .header .burger--check:checked ~ .burger span:before {
          top: 0;
          transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
          transform: translate3d(0, 10px, 0) rotate(45deg); }
        .header .burger--check:checked ~ .burger span:after {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
          transform: translate3d(0, 10px, 0) rotate(-45deg); }
      .header .burger--check:checked ~ .nav {
        transform: translate3d(0, 0, 0); }
        .tablet .header .burger--check:checked ~ .nav {
          background-color: #000; }
          .tablet .header .burger--check:checked ~ .nav:before {
            opacity: 1; } }
  .header.minimized {
    transition: all 0.35s;
    background-color: #000; }
    @media screen and (min-width: 681px) {
      .header.minimized {
        transform: translateY(-45px); } }
    @media screen and (max-width: 680px) {
      .header.minimized {
        transform: translateY(-25px); } }
    @media screen and (min-width: 1201px) {
      .header.minimized .logo {
        transform: scale(1); } }
  .header--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s; }
    .minimized .header--main {
      transition: all 0.35s; }
    @media screen and (min-width: 681px) {
      .header--main {
        height: 105px; }
        .minimized .header--main {
          padding-top: 45px; } }
    @media screen and (max-width: 680px) {
      .header--main {
        height: 85px; }
        .minimized .header--main {
          padding-top: 25px; } }
    @media screen and (max-width: 1024px) {
      .header--main:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        z-index: 1; } }

.footer {
  background-color: #f0f1f3; }
  .landing .footer {
    margin-top: auto;
    width: 100%; }
  .footer--main {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (min-width: 1025px) {
      .footer--main {
        min-height: 160px; }
        .footer--main.right-align {
          justify-content: flex-end; } }
    @media screen and (max-width: 1024px) {
      .footer--main {
        flex-direction: column;
        padding: 25px 0; } }
  @media screen and (max-width: 1024px) {
    .footer--links {
      order: 1;
      margin: 25px 0 0; } }
  @media screen and (min-width: 481px) {
    .footer--links {
      font-size: 0.9rem; } }
  @media screen and (max-width: 480px) {
    .footer--links {
      font-size: 0.8rem; } }
  .footer--links ul {
    display: flex;
    align-items: center; }
  @media screen and (min-width: 481px) {
    .footer--links li:not(:last-child) {
      margin-right: 25px; } }
  @media screen and (max-width: 480px) {
    .footer--links li:not(:last-child) {
      margin-right: 15px; } }
  .footer--links a {
    text-transform: uppercase;
    color: #9a9a9a;
    display: block; }
    @media screen and (min-width: 1025px) {
      .footer--links a:hover {
        color: #4d4c4c; } }

.nav {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 1024px) {
    .header .nav {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #000;
      transform: translate3d(0, -130%, 0);
      transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, background-color 0.2s ease 0.2s;
      flex-direction: column;
      padding: 5rem 32.5px; }
      .tablet .header .nav:before {
        opacity: 0;
        transition: all 0.2s ease 0.2s;
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 120px;
        background-color: #000;
        z-index: 1; } }
  @media screen and (max-width: 680px) {
    .footer .nav {
      flex-direction: column;
      width: 100%;
      padding: 2rem 0; } }
  .nav--menu {
    display: flex;
    align-items: center; }
    .nav--menu li {
      margin-right: 38px; }
    .nav--menu:last-child li:last-child {
      margin-right: 0; }
    .nav--menu a {
      text-transform: uppercase;
      border-bottom: 1px solid transparent; }
      @media screen and (min-width: 1025px) {
        .nav--menu a {
          font-size: 1rem; } }
      @media screen and (max-width: 1024px) {
        .nav--menu a {
          font-size: 1.4rem; } }
      .header .nav--menu a {
        color: #fff; }
        .header .nav--menu a.active {
          border-color: #fff; }
        @media screen and (min-width: 1025px) {
          .header .nav--menu a:hover {
            border-color: #fff; } }
      .footer .nav--menu a {
        color: #17191f; }
        .footer .nav--menu a.active {
          border-color: #17191f; }
        @media screen and (min-width: 1025px) {
          .footer .nav--menu a:hover {
            border-color: #17191f; } }
    @media screen and (max-width: 1024px) {
      .header .nav--menu {
        flex-direction: column; }
        .header .nav--menu li {
          margin: 0 0 2.1rem; } }
    @media screen and (max-width: 680px) {
      .footer .nav--menu {
        flex-direction: column; }
        .footer .nav--menu li {
          margin: 0 0 1.5rem; } }
  .nav .lang {
    position: relative;
    text-transform: uppercase;
    cursor: pointer; }
    @media screen and (min-width: 1025px) {
      .nav .lang {
        margin-right: 38px; } }
    @media screen and (max-width: 1024px) {
      .nav .lang {
        margin-bottom: 15px;
        font-size: 1.4rem; } }
    .nav .lang-sign {
      color: #fff;
      border: 1px solid #fff;
      border-radius: 2px;
      min-width: 34px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px; }
    .nav .lang-select {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      border-radius: 2px;
      border: 1px solid #17191f;
      background-color: #fff;
      color: #17191f;
      transition: all 0.2s; }
      .nav .lang-select a {
        min-width: 34px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        color: inherit; }
        .nav .lang-select a.current {
          background-color: #17191f;
          color: #fff;
          box-shadow: 0 0 0 1px #fff inset;
          border-radius: 2px; }
        .nav .lang-select a:not(.current):hover {
          background-color: #4d4c4c;
          color: #fff; }
    .nav .lang:hover .lang-select {
      opacity: 1;
      pointer-events: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transition: all 0.35s; }
  .modal .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal.active {
    opacity: 1;
    pointer-events: auto; }
  .modal-wrap {
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }
    .modal-wrap .close {
      color: #fff;
      position: absolute;
      top: 25px;
      right: 25px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 40px;
      font-weight: 300;
      cursor: pointer; }
      .mobile .modal-wrap .close {
        top: 5px;
        right: 5px; }
    .mobile .modal-wrap {
      align-items: flex-start;
      overflow: auto; }
  .modal[data-modal="request"] .modal-wrap {
    background-color: #24262f; }
    @media screen and (min-width: 601px) {
      .modal[data-modal="request"] .modal-wrap {
        top: 10px;
        right: 10px;
        left: 10px;
        bottom: 10px; } }
    @media screen and (max-width: 600px) {
      .modal[data-modal="request"] .modal-wrap {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0; } }

.btn {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  display: inline-block; }
  .btn--light {
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    padding: 10px 32px;
    color: #fff; }
    @media screen and (min-width: 1025px) {
      .btn--light:hover {
        background-color: #fff;
        color: #17191f; } }
  .btn--square-light {
    background-color: #fff;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    text-align: center;
    min-width: 130px; }
  .btn--square-dark {
    background-color: #000;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    text-align: center;
    min-width: 130px;
    color: #fff; }
  .btn--dark {
    border-color: #17191f;
    border-width: 1px;
    border-style: solid;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    padding: 10px 25px;
    color: #17191f; }
    .btn--dark.current {
      background-color: #17191f;
      color: #fff; }
    @media screen and (min-width: 1025px) {
      .btn--dark:hover {
        background-color: #17191f;
        color: #fff; } }

.page-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 37.5px;
  color: #17191f;
  transform: translate3d(0, 0, 0); }
  @media screen and (min-width: 1025px) {
    .page-title {
      font-size: 4.3rem; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    .page-title {
      font-size: 3.5rem; } }
  @media screen and (max-width: 680px) {
    .page-title {
      font-size: 2.5rem; } }

.login-form .form--field,
.request-form .form--field {
  margin-bottom: 25px; }

.add-comment .form--field {
  margin-bottom: 15px; }

.form--field .label {
  display: block;
  font-size: 0.86rem;
  margin-bottom: 5px;
  flex-shrink: 0; }
  .login-form .form--field .label,
  .request-form .form--field .label {
    color: #fff; }
  .add-comment .form--field .label {
    color: #000; }

.form--field .input input,
.form--field .input textarea {
  outline: none;
  display: block;
  width: 100%;
  resize: none;
  background-color: transparent;
  border: 1px solid rgba(113, 115, 120, 0.6);
  font-size: 1rem;
  padding: 12px 15px; }
  .login-form .form--field .input input,
  .request-form .form--field .input input, .login-form
  .form--field .input textarea,
  .request-form
  .form--field .input textarea {
    color: #fff;
    margin: 0; }
  .add-comment .form--field .input input, .add-comment
  .form--field .input textarea {
    color: #000; }
  .form--field .input input:focus,
  .form--field .input textarea:focus {
    border-color: #717378; }

.form--field .submit {
  text-align: center; }

.form--field .check {
  position: relative;
  margin-bottom: 15px;
  transition: all 0.2s; }
  .request-form .form--field .check {
    color: rgba(255, 255, 255, 0.5); }
  .add-comment .form--field .check {
    color: rgba(0, 0, 0, 0.5); }
  .form--field .check label {
    position: relative;
    font-size: 0.86rem;
    cursor: pointer; }
  .form--field .check input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none; }
    .request-form .form--field .check input:checked ~ .check-text {
      color: #fff; }
    .add-comment .form--field .check input:checked ~ .check-text {
      color: #000; }
    .request-form .form--field .check input:checked ~ .check-text:before {
      border-color: #fff; }
    .add-comment .form--field .check input:checked ~ .check-text:before {
      border-color: #000; }
    .form--field .check input:checked ~ .check-text:after {
      opacity: 1; }
  .form--field .check-text {
    position: relative;
    padding-left: 24px;
    display: inline-block; }
    .form--field .check-text:before {
      content: "";
      width: 16px;
      height: 16px;
      top: 0;
      position: absolute;
      left: 0; }
      .request-form .form--field .check-text:before {
        border: 1px solid rgba(255, 255, 255, 0.5); }
      .add-comment .form--field .check-text:before {
        border: 1px solid rgba(0, 0, 0, 0.5); }
    .form--field .check-text:after {
      content: "";
      width: 7px;
      height: 7px;
      border-style: none solid solid none;
      border-width: 1px;
      top: 2px;
      position: absolute;
      left: 5px;
      transform: rotate(45deg);
      opacity: 0; }
      .request-form .form--field .check-text:after {
        border-color: white; }
      .add-comment .form--field .check-text:after {
        border-color: black; }
  @media screen and (min-width: 1025px) {
    .request-form .form--field .check:hover {
      color: #fff; }
    .add-comment .form--field .check:hover {
      color: #000; } }

.login-form .form--field .txt {
  color: rgba(255, 255, 255, 0.7); }

.form--field .txt a {
  color: inherit;
  border-bottom: 1px solid; }
  @media screen and (min-width: 1025px) {
    .form--field .txt a:hover {
      border-bottom-width: 2px; } }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500; }
  @media screen and (min-width: 1025px) {
    .pagination {
      margin-bottom: 60px; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    .pagination {
      margin-bottom: 2rem; } }
  @media screen and (max-width: 680px) {
    .pagination {
      margin-bottom: 1rem; } }
  .pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 5px; }
    .pagination li.current .page {
      border-color: #000;
      background-color: #000;
      color: #fff; }
  .pagination span,
  .pagination .page {
    border: 1px solid #a4a4a4;
    border-radius: 50%;
    color: #000;
    position: relative;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 30px; }
    @media screen and (min-width: 1025px) {
      .pagination span:hover,
      .pagination .page:hover {
        border-color: #000;
        background-color: #000;
        color: #fff; } }
  .pagination .next,
  .pagination .prev {
    width: 30px;
    height: 30px;
    color: #4d4c4c; }
    .pagination .next span,
    .pagination .next .page,
    .pagination .prev span,
    .pagination .prev .page {
      overflow: hidden;
      text-indent: 999px;
      white-space: nowrap; }
      .pagination .next span:before,
      .pagination .next .page:before,
      .pagination .prev span:before,
      .pagination .prev .page:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-color: currentColor;
        border-width: 2px;
        border-style: solid solid none none;
        top: 0;
        bottom: 0;
        margin: auto; }
    @media screen and (min-width: 1025px) {
      .pagination .next:hover .page,
      .pagination .prev:hover .page {
        color: #fff; } }
    .pagination .next.disabled,
    .pagination .prev.disabled {
      opacity: 0.5;
      pointer-events: none; }
  .pagination .prev span:before,
  .pagination .prev .page:before {
    right: 9px;
    transform: rotate(-135deg); }
  .pagination .next span:before,
  .pagination .next .page:before {
    left: 9px;
    transform: rotate(45deg); }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #17191f;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease 0.3s;
  pointer-events: none;
  opacity: 0; }
  .loader.show {
    opacity: 1;
    pointer-events: auto; }
  .loader svg {
    height: 80px;
    width: 80px; }

@media screen and (min-width: 1025px) {
  .section {
    padding-top: 66px; } }

@media screen and (min-width: 681px) and (max-width: 1024px) {
  .section {
    padding-top: 57.5px; } }

@media screen and (max-width: 680px) {
  .section {
    padding-top: 50px; } }

.section--blog {
  padding-bottom: 40px; }

.section--article {
  margin-bottom: 3rem; }
  .section--article + .section--blog {
    padding-top: 30px; }

@media screen and (min-width: 1025px) {
  .article {
    margin-bottom: 85px; } }

@media screen and (min-width: 681px) and (max-width: 1024px) {
  .article {
    margin-bottom: 5rem; } }

@media screen and (max-width: 680px) {
  .article {
    margin-bottom: 4rem; } }

@media screen and (min-width: 1201px) {
  .more .article {
    width: 33.3333%;
    padding: 0 40px; } }

@media screen and (min-width: 681px) and (max-width: 1200px) {
  .more .article {
    width: 50%;
    padding: 0 40px; } }

.article--data {
  position: relative;
  padding-left: 33px; }

.article--date {
  position: absolute;
  top: 2rem;
  right: 100%;
  white-space: nowrap;
  color: #a4a4a4;
  font-weight: 700;
  font-size: .86rem;
  text-transform: uppercase;
  transform-origin: top right;
  transform: rotate(-90deg);
  background-color: #fff;
  line-height: 1.1rem;
  padding: 0 15px; }

.article--tag {
  color: #000;
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  line-height: 1.5;
  margin-bottom: 1rem; }

.article--title {
  color: #17191f;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 1.1rem; }
  @media screen and (min-width: 1025px) {
    .article--title {
      font-size: 2.58rem; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    .article--title {
      font-size: 2.2rem; } }
  @media screen and (max-width: 680px) {
    .article--title {
      font-size: 2rem; } }
  .article--title span {
    transition: all 0.3s;
    border-bottom: 2px solid transparent; }

.article--descr {
  color: rgba(23, 25, 31, 0.8);
  font-size: 1.14rem;
  line-height: 1.87; }

.article--img {
  display: flex;
  margin-left: 33px;
  margin-right: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  margin-bottom: 10px; }
  .article--img img {
    max-width: 100%;
    height: auto; }

.article--box {
  display: block;
  color: inherit;
  position: relative; }
  .article--box:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 0.5rem;
    top: 0;
    width: 1px;
    background-color: #a4a4a4; }
  @media screen and (min-width: 1025px) {
    .article--box:hover .article--title span {
      border-color: #17191f; } }

.grid.opacity {
  visibility: hidden; }

.grid-layout {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%; }
  .grid-layout > div {
    width: 100%; }
    @media screen and (min-width: 681px) {
      .grid-layout > div {
        padding: 0 40px; } }

.text-box {
  line-height: 1.55;
  margin-bottom: 4rem; }
  @media screen and (min-width: 681px) {
    .text-box {
      font-size: 1.3rem; } }
  @media screen and (max-width: 680px) {
    .text-box {
      font-size: 1.5rem; } }
  @media screen and (min-width: 681px) {
    .single-article .text-box {
      padding: 0 33px; } }
  .text-box p {
    margin-bottom: 2rem;
    min-height: 1px; }
  .text-box ul {
    margin-bottom: 2rem; }
    .text-box ul li {
      position: relative;
      margin-bottom: 0.5rem;
      padding-left: 20px; }
      .text-box ul li:before {
        content: "";
        position: absolute;
        top: 0.85rem;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #17191f; }
  .text-box ol {
    margin-bottom: 2rem;
    counter-reset: item; }
    .text-box ol li {
      position: relative;
      margin-bottom: 0.5rem;
      padding-left: 20px; }
      .text-box ol li:before {
        position: absolute;
        top: 0.2rem;
        font-size: 1.1rem;
        font-weight: 600;
        left: 0;
        content: counter(item, decimal) ".";
        counter-increment: item; }
  .text-box img {
    display: block;
    max-width: 100%; }
    .text-box img.right-align {
      margin-bottom: 1.5rem; }
    .text-box img.left-align {
      margin-bottom: 1.5rem; }
    .text-box img:not(.right-align):not(.left-align) {
      width: auto;
      height: auto;
      margin: 0 auto 2rem; }
  .text-box h1, .text-box h2, .text-box h3, .text-box h4, .text-box h5, .text-box h6 {
    font-weight: 700;
    margin-bottom: 2rem; }
  .text-box h1 {
    font-size: 3.5rem; }
  .text-box h2 {
    font-size: 2.8rem; }
  .text-box h3 {
    font-size: 2.14rem; }
  .text-box h4 {
    font-size: 1.7rem; }
  .text-box h5 {
    font-size: 1.4rem; }
  .text-box h6 {
    font-size: 1.2rem; }
  .text-box strong, .text-box b {
    font-weight: 700; }
  .text-box em, .text-box i {
    font-style: italic; }
  .text-box a {
    color: inherit;
    border-bottom: 1px solid; }
    @media screen and (min-width: 1025px) {
      .text-box a:hover {
        border-bottom-width: 2px; } }
  .text-box .iframe {
    position: relative;
    padding-top: 60%;
    margin-bottom: 2rem; }
    .text-box .iframe iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .text-box iframe {
    width: 100%; }
  .text-box blockquote {
    position: relative;
    padding: 3rem 0;
    font-size: 1.7rem;
    font-style: italic;
    margin: 4rem auto;
    text-align: center;
    max-width: 600px; }
    .text-box blockquote:after, .text-box blockquote:before {
      content: "";
      position: absolute;
      width: 90px;
      height: 1px;
      background-color: currentColor;
      left: 0;
      right: 0;
      margin: auto; }
    .text-box blockquote:before {
      top: 0; }
    .text-box blockquote:after {
      bottom: 0; }
  @media screen and (min-width: 681px) {
    .text-box .flex-parts {
      display: flex;
      align-items: center; }
      .text-box .flex-parts > div {
        width: 50%; }
        .text-box .flex-parts > div:first-child {
          padding-right: 1.5rem; }
        .text-box .flex-parts > div:last-child {
          padding-left: 1.5rem; } }
  @media screen and (min-width: 1025px) {
    .text-box .quote-parts {
      margin: 0 -80px; } }
  .text-box .quote-parts *:not(img) {
    text-align: left; }
  @media screen and (min-width: 681px) {
    .text-box .quote-parts {
      display: flex;
      align-items: center; }
      .text-box .quote-parts > div {
        width: 50%; }
        .text-box .quote-parts > div:first-child {
          padding-right: 1.5rem; } }
      @media screen and (min-width: 681px) and (min-width: 1025px) {
        .text-box .quote-parts > div:first-child *:not(img) {
          margin-left: 80px; } }
  @media screen and (min-width: 681px) {
        .text-box .quote-parts > div:last-child {
          padding-left: 1.5rem; } }
      @media screen and (min-width: 681px) and (min-width: 1025px) {
        .text-box .quote-parts > div:last-child *:not(img) {
          margin-right: 80px; } }
  @media screen and (min-width: 681px) {
    .text-box .right-align {
      float: right;
      max-width: calc(50% - 2rem);
      margin-left: 2rem; } }
  @media screen and (max-width: 680px) {
    .text-box .right-align {
      width: 100%;
      height: auto; } }
  @media screen and (min-width: 681px) {
    .text-box .left-align {
      float: left;
      max-width: calc(50% - 2rem);
      margin-right: 2rem; } }
  @media screen and (max-width: 680px) {
    .text-box .left-align {
      width: 100%;
      height: auto; } }

.comments-section {
  margin-top: 3rem;
  margin-bottom: 3rem; }
  @media screen and (min-width: 681px) {
    .comments-section {
      padding: 0 33px; } }
  .comments-section .comments > .comment {
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
    margin-bottom: 30px; }
  .comments-section .comments-form {
    display: flex;
    align-items: stretch; }
    @media screen and (min-width: 681px) {
      .comments-section .comments-form {
        margin: 0 -15px 20px; } }
    @media screen and (max-width: 680px) {
      .comments-section .comments-form {
        flex-direction: column;
        margin-bottom: 20px; } }
    .comments-section .comments-form-col {
      width: 100%; }
      @media screen and (min-width: 681px) {
        .comments-section .comments-form-col {
          padding: 0 15px; }
          .comments-section .comments-form-col.col-60 {
            max-width: 60%; }
            .comments-section .comments-form-col.col-60 label {
              display: flex;
              height: 100%;
              flex-direction: column;
              margin: 0; }
            .comments-section .comments-form-col.col-60 .input,
            .comments-section .comments-form-col.col-60 .form--field {
              height: 100%; }
            .comments-section .comments-form-col.col-60 textarea {
              min-height: 100%; }
            .comments-section .comments-form-col.col-60 .form--field:last-child {
              margin-bottom: 0; }
          .comments-section .comments-form-col.col-40 {
            max-width: 40%; }
            .comments-section .comments-form-col.col-40 .form--field:last-child {
              margin-bottom: 0; } }
  .comments-section .comments-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 30px;
    background-color: #f5f5f5;
    padding: 1rem 1.5rem; }
    .comments-section .comments-title a {
      color: inherit;
      text-decoration: underline; }
  .comments-section .comment .comment {
    padding-left: 60px;
    padding-top: 30px;
    position: relative; }
    .comments-section .comment .comment:before {
      content: "";
      width: 30px;
      height: 35px;
      left: 15px;
      top: 15px;
      border-style: none none solid solid;
      border-width: 1px;
      border-color: #CCC;
      position: absolute; }
  .comments-section .comment-head {
    display: flex;
    justify-content: space-between; }
  .comments-section .comment-name {
    color: #4d4c4c;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px; }
  .comments-section .comment-date {
    font-size: 0.86rem;
    color: #b6b6b6;
    margin-bottom: 5px; }
  .comments-section .comment-text {
    margin-top: 15px;
    color: #4d4c4c;
    font-size: 1rem;
    line-height: 1.6; }
  .comments-section .comment-reply {
    font-size: 1rem;
    text-transform: uppercase; }
    .comments-section .comment-reply a {
      color: inherit;
      text-decoration: underline; }
  .comments-section .add-comment .control-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 20px; }
  .comments-section .add-comment .submit {
    display: flex;
    justify-content: center;
    margin-top: 30px; }

.request-form {
  position: relative; }
  .mobile .request-form {
    padding: 20px 0; }
  .request-form--title {
    color: #fff;
    font-size: 2.56rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px; }
  .request-form .submit {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 480px) {
      .request-form .submit {
        flex-direction: column; } }
    .request-form .submit .captcha {
      overflow: hidden; }
      @media screen and (min-width: 481px) {
        .request-form .submit .captcha {
          width: 230px;
          height: 60px; } }
      @media screen and (max-width: 480px) {
        .request-form .submit .captcha {
          margin-bottom: 10px;
          width: 280px; } }
      .request-form .submit .captcha .control-label {
        display: none; }
    @media screen and (min-width: 481px) {
      .request-form .submit .g-recaptcha {
        transform-origin: left top;
        transform: scale(0.75); } }
    @media screen and (max-width: 480px) {
      .request-form .submit .g-recaptcha {
        transform: scale(0.9);
        transform-origin: left center; } }
  .request-form .thanks-box {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #24262f; }
    .request-form .thanks-box .txt {
      height: 100%;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      font-size: 2.56rem;
      font-weight: 600; }
  .request-form.success .thanks-box {
    display: block; }

.login-form--title {
  color: #fff;
  font-size: 2.56rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.2; }

.login-form .submit {
  display: flex;
  justify-content: center; }

.placeholder {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  background-color: #17191f;
  background-repeat: no-repeat;
  background-position: center 0, center 200px;
  background-size: 1920px auto;
  font-family: 'Noto', sans-serif; }
  .placeholder-main {
    color: #fff;
    width: 100%; }
    @media screen and (min-width: 1024px) {
      .placeholder-main {
        padding: 95px 0 140px; } }
    @media screen and (min-width: 680px) and (max-width: 1023px) {
      .placeholder-main {
        padding: 65px 0 75px; } }
    @media screen and (max-width: 679px) {
      .placeholder-main {
        padding: 30px 0 50px; } }
  .placeholder-footer {
    color: #aaa;
    background-color: #fff;
    margin-top: auto;
    width: 100%; }
    @media screen and (min-width: 680px) {
      .placeholder-footer {
        padding: 45px 0;
        font-size: 13px; } }
    @media screen and (max-width: 679px) {
      .placeholder-footer {
        padding: 45px 0 20px;
        font-size: 10px; } }
    .placeholder-footer .banners {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media screen and (min-width: 680px) {
        .placeholder-footer .banners {
          margin-bottom: 120px; } }
      @media screen and (max-width: 679px) {
        .placeholder-footer .banners {
          margin-bottom: 60px; } }
      .placeholder-footer .banners-title {
        text-transform: uppercase;
        font-weight: 700;
        color: #17191f; }
        @media screen and (min-width: 1024px) {
          .placeholder-footer .banners-title {
            font-size: 48px;
            margin-bottom: 35px; } }
        @media screen and (min-width: 680px) and (max-width: 1023px) {
          .placeholder-footer .banners-title {
            font-size: 36px;
            margin-bottom: 30px; } }
        @media screen and (max-width: 679px) {
          .placeholder-footer .banners-title {
            font-size: 24px;
            margin-bottom: 25px; } }
      @media screen and (min-width: 680px) {
        .placeholder-footer .banners li:not(:last-child) {
          margin-right: 45px; } }
      @media screen and (max-width: 679px) {
        .placeholder-footer .banners li:not(:last-child) {
          margin-right: 25px; } }
      .placeholder-footer .banners li img {
        max-width: 100%;
        height: auto; }
    .placeholder-footer .copyright {
      text-align: center; }
  .placeholder-content {
    margin: 0 auto;
    max-width: 990px;
    overflow: hidden; }
    @media screen and (min-width: 680px) {
      .placeholder-content {
        padding: 0 50px; } }
    @media screen and (max-width: 679px) {
      .placeholder-content {
        padding: 0 25px; } }
  @media screen and (min-width: 1024px) {
    .placeholder .company-main-info {
      margin-bottom: 230px; } }
  @media screen and (min-width: 680px) and (max-width: 1023px) {
    .placeholder .company-main-info {
      margin-bottom: 130px; } }
  @media screen and (max-width: 679px) {
    .placeholder .company-main-info {
      margin-bottom: 75px; } }
  .placeholder .company-main-info header {
    text-align: center;
    line-height: 1.6; }
    @media screen and (min-width: 1024px) {
      .placeholder .company-main-info header {
        font-size: 20px;
        margin-bottom: 105px; } }
    @media screen and (min-width: 680px) and (max-width: 1023px) {
      .placeholder .company-main-info header {
        font-size: 14px;
        margin-bottom: 80px; } }
    @media screen and (max-width: 679px) {
      .placeholder .company-main-info header {
        font-size: 12px;
        margin-bottom: 60px; } }
  .placeholder .company-main-info .logo {
    margin: 0 auto 85px; }
    @media screen and (min-width: 1024px) {
      .placeholder .company-main-info .logo {
        width: 445px; } }
    @media screen and (min-width: 680px) and (max-width: 1023px) {
      .placeholder .company-main-info .logo {
        width: 360px; } }
    @media screen and (max-width: 679px) {
      .placeholder .company-main-info .logo {
        width: 250px;
        margin-bottom: 50px; } }
    .placeholder .company-main-info .logo img {
      display: block;
      width: 100%;
      height: auto; }
  .placeholder .company-main-info p {
    line-height: 1.4;
    margin-bottom: 20px; }
    @media screen and (min-width: 1024px) {
      .placeholder .company-main-info p {
        font-size: 18px; } }
    @media screen and (min-width: 680px) and (max-width: 1023px) {
      .placeholder .company-main-info p {
        font-size: 15px; } }
    @media screen and (max-width: 679px) {
      .placeholder .company-main-info p {
        font-size: 13px; } }
  @media screen and (min-width: 1024px) {
    .placeholder .company-list {
      padding-right: 230px;
      font-size: 18px; } }
  @media screen and (min-width: 680px) and (max-width: 1023px) {
    .placeholder .company-list {
      font-size: 15px; } }
  @media screen and (max-width: 679px) {
    .placeholder .company-list {
      font-size: 13px; } }
  .placeholder .company-list li {
    padding-left: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
    position: relative; }
    .placeholder .company-list li:before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-radius: 50%; }
  .placeholder .company-descr strong {
    font-weight: 700; }
  .placeholder .company-advantageous {
    position: relative; }
    @media screen and (min-width: 1024px) {
      .placeholder .company-advantageous {
        padding-left: 260px; } }
    .placeholder .company-advantageous header {
      position: relative;
      line-height: 1.5; }
      @media screen and (min-width: 680px) {
        .placeholder .company-advantageous header {
          padding-left: 120px;
          margin-bottom: 80px; } }
      @media screen and (max-width: 679px) {
        .placeholder .company-advantageous header {
          padding-left: 50px;
          margin-bottom: 50px; } }
      .placeholder .company-advantageous header:before {
        content: "2";
        position: absolute;
        font-weight: 700;
        line-height: 1;
        left: 0;
        top: 50%;
        color: #7d8188;
        transform: translateY(-50%); }
        @media screen and (min-width: 680px) {
          .placeholder .company-advantageous header:before {
            font-size: 150px; } }
        @media screen and (max-width: 679px) {
          .placeholder .company-advantageous header:before {
            font-size: 70px; } }
    .placeholder .company-advantageous article {
      position: relative;
      padding: 10px;
      line-height: 1.5;
      margin-bottom: 40px; }
      @media screen and (min-width: 1024px) {
        .placeholder .company-advantageous article {
          font-size: 30px; } }
      @media screen and (min-width: 680px) and (max-width: 1023px) {
        .placeholder .company-advantageous article {
          font-size: 24px; } }
      @media screen and (max-width: 679px) {
        .placeholder .company-advantageous article {
          font-size: 20px; } }
      .placeholder .company-advantageous article:before {
        content: "";
        position: absolute;
        top: 0;
        left: 8px;
        width: 105px;
        height: 3px;
        background-color: #fff; }
    .placeholder .company-advantageous .adv-title {
      text-transform: uppercase;
      font-weight: 700; }
      @media screen and (min-width: 1024px) {
        .placeholder .company-advantageous .adv-title {
          font-size: 48px; } }
      @media screen and (min-width: 680px) and (max-width: 1023px) {
        .placeholder .company-advantageous .adv-title {
          font-size: 36px; } }
      @media screen and (max-width: 679px) {
        .placeholder .company-advantageous .adv-title {
          font-size: 24px; } }
      .placeholder .company-advantageous .adv-title-text {
        text-transform: uppercase; }
        @media screen and (min-width: 1024px) {
          .placeholder .company-advantageous .adv-title-text {
            font-size: 36px; } }
        @media screen and (min-width: 680px) and (max-width: 1023px) {
          .placeholder .company-advantageous .adv-title-text {
            font-size: 28px; } }
        @media screen and (max-width: 679px) {
          .placeholder .company-advantageous .adv-title-text {
            font-size: 18px; } }
    .placeholder .company-advantageous .fingerprint {
      position: absolute;
      opacity: 0.1; }
      @media screen and (min-width: 1024px) {
        .placeholder .company-advantageous .fingerprint {
          left: 36px;
          top: -70px;
          bottom: -46px; } }
      @media screen and (min-width: 680px) and (max-width: 1023px) {
        .placeholder .company-advantageous .fingerprint {
          right: 0;
          top: -70px;
          bottom: -46px; } }
      @media screen and (max-width: 679px) {
        .placeholder .company-advantageous .fingerprint {
          right: 0;
          top: -15px;
          bottom: 0; } }
      .placeholder .company-advantageous .fingerprint img {
        width: auto;
        height: 100%;
        display: block; }

.blog--filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  @media screen and (max-width: 1024px) {
    .blog--filters {
      flex-direction: column; } }

@media screen and (min-width: 1025px) {
  .blog--tags {
    margin-bottom: 66px; } }

@media screen and (min-width: 681px) and (max-width: 1024px) {
  .blog--tags {
    margin-bottom: 50px; } }

@media screen and (max-width: 680px) {
  .blog--tags {
    margin-bottom: 37.5px; } }

.blog--tags ul {
  display: flex;
  align-items: center;
  flex-flow: row wrap; }

.blog--tags li {
  white-space: nowrap; }
  @media screen and (min-width: 1201px) {
    .blog--tags li {
      margin: 0 20px 20px 0; } }
  @media screen and (min-width: 681px) and (max-width: 1200px) {
    .blog--tags li {
      margin: 0 12.5px 12.5px 0; } }
  @media screen and (max-width: 680px) {
    .blog--tags li {
      margin: 0 8.33333px 8.33333px 0; } }

@media screen and (max-width: 1024px) {
  .blog--tags {
    order: 1; } }

@media screen and (max-width: 680px) {
  .blog--tags .btn--dark {
    padding: 7px 10px;
    font-size: 0.85rem; } }

.blog--search {
  min-width: 250px; }
  @media screen and (min-width: 1025px) {
    .blog--search {
      margin-left: 25px; } }
  @media screen and (max-width: 1024px) {
    .blog--search {
      margin-bottom: 25px;
      margin-left: auto; } }
  @media screen and (min-width: 681px) {
    .blog--search {
      width: 30%; } }
  @media screen and (max-width: 680px) {
    .blog--search {
      width: 100%; } }
  .blog--search form {
    width: 100%; }
  .blog--search-field {
    width: 100%;
    display: flex;
    position: relative; }
    .blog--search-field input {
      width: 100%;
      border-color: #17191f;
      border-width: 1px;
      border-style: solid;
      border-radius: 50px;
      outline: none;
      font-size: 1rem;
      line-height: 1;
      padding: 8px 50px 8px 20px;
      color: #17191f; }
      .has-text .blog--search-field input {
        padding-right: 70px; }
    .blog--search-field .submit {
      position: absolute;
      top: 0;
      margin: auto;
      bottom: 0;
      right: 0;
      display: flex;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
      border-radius: 50%;
      width: calc(1rem + 27px);
      height: calc(1rem + 20px);
      color: #17191f;
      padding: 3px 10px 3px 3px; }
    .blog--search-field .reset {
      position: absolute;
      background: transparent;
      border: none;
      right: calc(1rem + 30px);
      margin: auto;
      top: 0;
      bottom: 0;
      width: 25px;
      height: 25px;
      line-height: 22px;
      font-size: 2.2rem;
      color: #4d4c4c;
      opacity: 0;
      outline: none;
      pointer-events: none; }
    .has-text .blog--search-field .reset {
      opacity: 1;
      pointer-events: auto; }

.blog--articles .grid {
  position: relative;
  flex-flow: row wrap; }
  @media screen and (min-width: 1025px) {
    .blog--articles .grid {
      margin: 0 -40px; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    .blog--articles .grid {
      margin: 0 -32.5px; } }

.blog--articles.more .grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.blog--articles.more .article {
  margin-bottom: 45px; }

.about {
  background-color: #17191f;
  background-attachment: fixed;
  background-position: center 20rem;
  background-size: 100% auto;
  background-repeat: no-repeat; }
  .about-head {
    position: relative; }
    @media screen and (min-width: 1025px) {
      .about-head {
        margin-bottom: 10rem; }
        .about-head.team-head {
          margin-bottom: 8rem; } }
    @media screen and (max-width: 1024px) {
      .about-head {
        margin-bottom: 5rem;
        margin-top: 2rem; }
        .about-head.team-head {
          margin-bottom: 8rem; } }
    .about-head .page-title {
      color: #fff; }
    .about-head .bg-word {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.05);
      line-height: 1;
      transform: translateY(-45%); }
      @media screen and (min-width: 1025px) {
        .about-head .bg-word {
          font-size: 13rem; } }
      @media screen and (min-width: 681px) and (max-width: 1024px) {
        .about-head .bg-word {
          font-size: 8rem; } }
      @media screen and (max-width: 680px) {
        .about-head .bg-word {
          font-size: 6rem; } }
    .about-head .moving-word {
      transition: opacity 0.6s ease; }
      .about-head .moving-word.opacity {
        opacity: 0; }
  .about-text {
    font-size: 1.6rem;
    line-height: 2; }
    @media screen and (min-width: 1025px) {
      .about-text {
        margin-bottom: 8rem; } }
    @media screen and (max-width: 1024px) {
      .about-text {
        margin-bottom: 5rem; } }
    .about-text .content {
      position: relative;
      max-width: 720px;
      color: rgba(255, 255, 255, 0.8); }
    .about-text .fingerprint {
      position: absolute;
      opacity: 0.6;
      right: 101%;
      top: 5rem;
      height: 22.5rem;
      width: auto; }
    .about-text p {
      margin-bottom: 3rem; }
  .about-team {
    position: relative; }
    .about-team--list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap; }
    @media screen and (min-width: 1201px) {
      .about-team--item {
        width: 33.3333%;
        max-width: 33.3333%;
        padding: 0 55px;
        margin-bottom: 8rem; } }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .about-team--item {
        width: 50%;
        max-width: 50%;
        padding: 0 30px;
        margin-bottom: 6rem; } }
    @media screen and (max-width: 767px) {
      .about-team--item {
        width: 100%;
        max-width: 480px;
        padding: 0 30px;
        margin: 0 auto 5rem; } }
    .about-team--item .figure {
      width: 100%;
      margin-bottom: 2rem; }
      .about-team--item .figure img {
        display: block;
        width: 100%; }
    .about-team--person-name {
      color: #fff;
      font-weight: 600;
      margin-bottom: 1rem; }
      @media screen and (min-width: 1025px) {
        .about-team--person-name {
          font-size: 2.16rem; } }
      @media screen and (max-width: 1024px) {
        .about-team--person-name {
          font-size: 2rem; } }
    .about-team--person-position {
      font-size: 1.152rem;
      color: #b3b3b3;
      line-height: 1.75; }
    .about-team--person-text {
      font-size: 1.152rem;
      color: #b3b3b3;
      line-height: 1.75; }

.single-article--data {
  position: relative; }
  @media screen and (min-width: 681px) {
    .single-article--data {
      padding-left: 33px;
      padding-right: 33px;
      padding-bottom: 2.5rem; } }
  @media screen and (max-width: 680px) {
    .single-article--data {
      margin-bottom: 2rem; } }

.single-article--figure {
  margin-bottom: 2.5rem; }
  @media screen and (min-width: 681px) {
    .single-article--figure {
      padding: 0 33px; } }
  .single-article--figure img {
    display: block;
    width: 100%;
    height: auto; }

.single-article--date {
  white-space: nowrap;
  color: #a4a4a4;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1rem; }
  @media screen and (min-width: 681px) {
    .single-article--date {
      font-size: .86rem;
      top: 3rem;
      position: absolute;
      right: 100%;
      transform-origin: top right;
      transform: rotate(-90deg);
      background-color: #fff;
      padding-left: 15px; } }
  @media screen and (max-width: 680px) {
    .single-article--date {
      padding-top: 1rem;
      display: block;
      font-size: 1.1rem; } }

.single-article--tag {
  color: #000;
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  line-height: 1.5; }
  .single-article--tag a {
    color: inherit; }

.single-article--title {
  color: #17191f;
  font-weight: 700;
  line-height: 1.3; }
  @media screen and (min-width: 1025px) {
    .single-article--title {
      font-size: 4.3rem; } }
  @media screen and (min-width: 681px) and (max-width: 1024px) {
    .single-article--title {
      font-size: 3.5rem; } }
  @media screen and (max-width: 680px) {
    .single-article--title {
      font-size: 2.5rem; } }
  .single-article--title span {
    transition: all 0.3s;
    border-bottom: 2px solid transparent; }

.single-article--actions {
  border-top: 1px solid #9a9a9a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 3rem; }
  @media screen and (min-width: 681px) {
    .single-article--actions {
      margin-left: 33px;
      margin-right: 33px; } }
  @media screen and (max-width: 680px) {
    .single-article--actions {
      flex-wrap: wrap; } }
  .single-article--actions .prev-page,
  .single-article--actions .next-page {
    text-transform: uppercase; }
    @media screen and (min-width: 481px) {
      .single-article--actions .prev-page,
      .single-article--actions .next-page {
        width: 150px; } }
    @media screen and (max-width: 480px) {
      .single-article--actions .prev-page,
      .single-article--actions .next-page {
        font-size: 0.8rem;
        width: 100px; } }
    .single-article--actions .prev-page a,
    .single-article--actions .next-page a {
      color: #4d4c4c;
      font-weight: 700;
      position: relative;
      padding: 0 15px; }
      .single-article--actions .prev-page a:before,
      .single-article--actions .next-page a:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-color: currentColor;
        border-width: 2px;
        border-style: solid solid none none;
        top: 0;
        bottom: 0;
        margin: auto; }
  .single-article--actions .prev-page {
    text-align: left; }
    .single-article--actions .prev-page a:before {
      left: 0;
      transform: rotate(-135deg); }
  .single-article--actions .next-page {
    text-align: right; }
    .single-article--actions .next-page a:before {
      right: 0;
      transform: rotate(45deg); }
  .single-article--actions .socials {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 680px) {
      .single-article--actions .socials {
        order: 1;
        width: 100%;
        margin-top: 20px;
        justify-content: center; } }
    .single-article--actions .socials li {
      margin: 0 5px; }
    .single-article--actions .socials a {
      width: 30px;
      height: 30px;
      border: 1px solid #a4a4a4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000; }
      .single-article--actions .socials a.fb {
        padding: 5px; }
      .single-article--actions .socials a.in {
        padding: 2px; }
      .single-article--actions .socials a.tw {
        padding: 5px; }
      @media screen and (min-width: 1025px) {
        .single-article--actions .socials a:hover {
          border-color: #000;
          background-color: #000;
          color: #fff; } }
    .single-article--actions .socials .icon {
      fill: currentColor; }

.error-logo {
  margin: 0 auto 2rem; }
  @media screen and (min-width: 681px) {
    .error-logo {
      width: 300px;
      height: 141px; } }
  @media screen and (max-width: 680px) {
    .error-logo {
      width: 220px;
      height: 103px; } }

.error-text {
  text-align: center;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 2rem; }

.error-link {
  margin-bottom: 5rem;
  text-align: center; }

.right-digit {
  stroke-dasharray: 131.384;
  stroke-dashoffset: 0;
  opacity: 0;
  animation: right_digit 4s linear 2s infinite; }

.left-digit {
  stroke-dasharray: 85.507;
  stroke-dashoffset: 0;
  animation: left_digit 4s linear infinite; }

.left-digit-in {
  opacity: 0;
  animation: left_digit_in 4s linear infinite; }

.ltr-string {
  animation: ltr_line 4s linear infinite;
  stroke-dasharray: 42.389;
  stroke-dashoffset: 0; }

.rtl-string {
  animation: rtl_line 4s linear 2s infinite;
  stroke-dasharray: 49.511;
  stroke-dashoffset: 0;
  opacity: 0; }

@keyframes ltr_line {
  0% {
    stroke-dashoffset: 42.389; }
  25% {
    stroke-dashoffset: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes left_digit {
  0% {
    stroke-dashoffset: 85.507; }
  25% {
    stroke-dashoffset: 85.507; }
  50% {
    stroke-dashoffset: 0; }
  100% {
    opacity: 0; } }

@keyframes left_digit_in {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes rtl_line {
  0% {
    stroke-dashoffset: 49.511;
    opacity: 1; }
  25% {
    stroke-dashoffset: 0;
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes right_digit {
  0% {
    stroke-dashoffset: 131.384;
    opacity: 1; }
  25% {
    stroke-dashoffset: 131.384;
    opacity: 1; }
  50% {
    stroke-dashoffset: 0;
    opacity: 1; }
  100% {
    opacity: 0; } }

.landing {
  width: 100%;
  overflow: hidden;
  background-color: #17191f; }
  .desktop .landing {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%; }
  .tablet.portrait .landing {
    padding: 80px 0 0; }
  .mobile .landing {
    padding: 60px 0 0; }
  .desktop .landing .slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    transition: transform 1.4s ease-in-out; }
    .desktop .landing .slide[data-slide='10'] {
      max-width: none; }
    .desktop .landing .slide-left {
      width: 50%;
      height: 100%;
      padding: 25px; }
    .desktop .landing .slide-right {
      width: 50%;
      height: 100%;
      padding: 1.4vw; }
  .tablet.landscape .landing .slide {
    position: relative;
    margin: auto;
    width: 100vw;
    display: flex;
    transition: transform 1.4s ease-in-out; }
    .tablet.landscape .landing .slide[data-slide='10'] {
      max-width: none; }
    .tablet.landscape .landing .slide-left {
      width: 50%;
      height: 100%;
      padding: 25px; }
    .tablet.landscape .landing .slide-right {
      width: 50%;
      height: 100%;
      padding: 1.4vw; }
  .mobile .landing .slide,
  .tablet.portrait .landing .slide {
    position: relative; }
    .mobile .landing .slide:after,
    .tablet.portrait .landing .slide:after {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 100%;
      margin-top: 45px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5); }

[data-slide='1'] .title,
[data-slide='3'] .title,
[data-slide='4'] .title,
[data-slide='5'] .title,
[data-slide='6'] .title,
[data-slide='7'] .title,
[data-slide='8'] .title,
[data-slide='9'] .title {
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 600; }
  .mobile [data-slide='1'] .title,
  .tablet [data-slide='1'] .title, .mobile
  [data-slide='3'] .title,
  .tablet
  [data-slide='3'] .title, .mobile
  [data-slide='4'] .title,
  .tablet
  [data-slide='4'] .title, .mobile
  [data-slide='5'] .title,
  .tablet
  [data-slide='5'] .title, .mobile
  [data-slide='6'] .title,
  .tablet
  [data-slide='6'] .title, .mobile
  [data-slide='7'] .title,
  .tablet
  [data-slide='7'] .title, .mobile
  [data-slide='8'] .title,
  .tablet
  [data-slide='8'] .title, .mobile
  [data-slide='9'] .title,
  .tablet
  [data-slide='9'] .title {
    margin-bottom: 4vh; }
    @media screen and (min-width: 1481px) {
      .mobile [data-slide='1'] .title,
      .tablet [data-slide='1'] .title, .mobile
      [data-slide='3'] .title,
      .tablet
      [data-slide='3'] .title, .mobile
      [data-slide='4'] .title,
      .tablet
      [data-slide='4'] .title, .mobile
      [data-slide='5'] .title,
      .tablet
      [data-slide='5'] .title, .mobile
      [data-slide='6'] .title,
      .tablet
      [data-slide='6'] .title, .mobile
      [data-slide='7'] .title,
      .tablet
      [data-slide='7'] .title, .mobile
      [data-slide='8'] .title,
      .tablet
      [data-slide='8'] .title, .mobile
      [data-slide='9'] .title,
      .tablet
      [data-slide='9'] .title {
        font-size: 6.2vh; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .mobile [data-slide='1'] .title,
      .tablet [data-slide='1'] .title, .mobile
      [data-slide='3'] .title,
      .tablet
      [data-slide='3'] .title, .mobile
      [data-slide='4'] .title,
      .tablet
      [data-slide='4'] .title, .mobile
      [data-slide='5'] .title,
      .tablet
      [data-slide='5'] .title, .mobile
      [data-slide='6'] .title,
      .tablet
      [data-slide='6'] .title, .mobile
      [data-slide='7'] .title,
      .tablet
      [data-slide='7'] .title, .mobile
      [data-slide='8'] .title,
      .tablet
      [data-slide='8'] .title, .mobile
      [data-slide='9'] .title,
      .tablet
      [data-slide='9'] .title {
        font-size: 5vh; } }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .mobile [data-slide='1'] .title,
      .tablet [data-slide='1'] .title, .mobile
      [data-slide='3'] .title,
      .tablet
      [data-slide='3'] .title, .mobile
      [data-slide='4'] .title,
      .tablet
      [data-slide='4'] .title, .mobile
      [data-slide='5'] .title,
      .tablet
      [data-slide='5'] .title, .mobile
      [data-slide='6'] .title,
      .tablet
      [data-slide='6'] .title, .mobile
      [data-slide='7'] .title,
      .tablet
      [data-slide='7'] .title, .mobile
      [data-slide='8'] .title,
      .tablet
      [data-slide='8'] .title, .mobile
      [data-slide='9'] .title,
      .tablet
      [data-slide='9'] .title {
        font-size: 4vh; } }
    @media screen and (max-width: 767px) {
      .mobile [data-slide='1'] .title,
      .tablet [data-slide='1'] .title, .mobile
      [data-slide='3'] .title,
      .tablet
      [data-slide='3'] .title, .mobile
      [data-slide='4'] .title,
      .tablet
      [data-slide='4'] .title, .mobile
      [data-slide='5'] .title,
      .tablet
      [data-slide='5'] .title, .mobile
      [data-slide='6'] .title,
      .tablet
      [data-slide='6'] .title, .mobile
      [data-slide='7'] .title,
      .tablet
      [data-slide='7'] .title, .mobile
      [data-slide='8'] .title,
      .tablet
      [data-slide='8'] .title, .mobile
      [data-slide='9'] .title,
      .tablet
      [data-slide='9'] .title {
        font-size: 7.8vw; } }
  @media screen and (max-width: 767px) {
    .tablet [data-slide='1'] .title, .tablet
    [data-slide='3'] .title, .tablet
    [data-slide='4'] .title, .tablet
    [data-slide='5'] .title, .tablet
    [data-slide='6'] .title, .tablet
    [data-slide='7'] .title, .tablet
    [data-slide='8'] .title, .tablet
    [data-slide='9'] .title {
      font-size: 5.4vw; } }
  .desktop [data-slide='1'] .title, .desktop
  [data-slide='3'] .title, .desktop
  [data-slide='4'] .title, .desktop
  [data-slide='5'] .title, .desktop
  [data-slide='6'] .title, .desktop
  [data-slide='7'] .title, .desktop
  [data-slide='8'] .title, .desktop
  [data-slide='9'] .title {
    font-size: 3vw;
    margin-bottom: 2vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='1'] .title, .desktop
      [data-slide='3'] .title, .desktop
      [data-slide='4'] .title, .desktop
      [data-slide='5'] .title, .desktop
      [data-slide='6'] .title, .desktop
      [data-slide='7'] .title, .desktop
      [data-slide='8'] .title, .desktop
      [data-slide='9'] .title {
        font-size: 6.5vh;
        margin-bottom: 4vh; } }

.tablet [data-slide='3'] .title,
.mobile [data-slide='3'] .title, .tablet
[data-slide='4'] .title,
.mobile
[data-slide='4'] .title, .tablet
[data-slide='5'] .title,
.mobile
[data-slide='5'] .title, .tablet
[data-slide='6'] .title,
.mobile
[data-slide='6'] .title, .tablet
[data-slide='7'] .title,
.mobile
[data-slide='7'] .title, .tablet
[data-slide='8'] .title,
.mobile
[data-slide='8'] .title, .tablet
[data-slide='9'] .title,
.mobile
[data-slide='9'] .title {
  transition: all 0.5s; }
  .tablet [data-slide='3'] .title:not(.active),
  .mobile [data-slide='3'] .title:not(.active), .tablet
  [data-slide='4'] .title:not(.active),
  .mobile
  [data-slide='4'] .title:not(.active), .tablet
  [data-slide='5'] .title:not(.active),
  .mobile
  [data-slide='5'] .title:not(.active), .tablet
  [data-slide='6'] .title:not(.active),
  .mobile
  [data-slide='6'] .title:not(.active), .tablet
  [data-slide='7'] .title:not(.active),
  .mobile
  [data-slide='7'] .title:not(.active), .tablet
  [data-slide='8'] .title:not(.active),
  .mobile
  [data-slide='8'] .title:not(.active), .tablet
  [data-slide='9'] .title:not(.active),
  .mobile
  [data-slide='9'] .title:not(.active) {
    opacity: 0;
    transform: translateY(50%); }

[data-slide='1'] .subtitle,
[data-slide='3'] .subtitle,
[data-slide='4'] .subtitle,
[data-slide='5'] .subtitle {
  line-height: 1.9; }
  @media screen and (min-width: 1481px) {
    .mobile [data-slide='1'] .subtitle,
    .tablet [data-slide='1'] .subtitle, .mobile
    [data-slide='3'] .subtitle,
    .tablet
    [data-slide='3'] .subtitle, .mobile
    [data-slide='4'] .subtitle,
    .tablet
    [data-slide='4'] .subtitle, .mobile
    [data-slide='5'] .subtitle,
    .tablet
    [data-slide='5'] .subtitle {
      font-size: 1.7vh; } }
  @media screen and (min-width: 1201px) and (max-width: 1480px) {
    .mobile [data-slide='1'] .subtitle,
    .tablet [data-slide='1'] .subtitle, .mobile
    [data-slide='3'] .subtitle,
    .tablet
    [data-slide='3'] .subtitle, .mobile
    [data-slide='4'] .subtitle,
    .tablet
    [data-slide='4'] .subtitle, .mobile
    [data-slide='5'] .subtitle,
    .tablet
    [data-slide='5'] .subtitle {
      font-size: 1.7vh; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .mobile [data-slide='1'] .subtitle,
    .tablet [data-slide='1'] .subtitle, .mobile
    [data-slide='3'] .subtitle,
    .tablet
    [data-slide='3'] .subtitle, .mobile
    [data-slide='4'] .subtitle,
    .tablet
    [data-slide='4'] .subtitle, .mobile
    [data-slide='5'] .subtitle,
    .tablet
    [data-slide='5'] .subtitle {
      font-size: 1.7vh; } }
  @media screen and (max-width: 767px) {
    .mobile [data-slide='1'] .subtitle,
    .tablet [data-slide='1'] .subtitle, .mobile
    [data-slide='3'] .subtitle,
    .tablet
    [data-slide='3'] .subtitle, .mobile
    [data-slide='4'] .subtitle,
    .tablet
    [data-slide='4'] .subtitle, .mobile
    [data-slide='5'] .subtitle,
    .tablet
    [data-slide='5'] .subtitle {
      font-size: 3.8vw; } }
  @media screen and (max-width: 767px) {
    .tablet.portrait [data-slide='1'] .subtitle, .tablet.portrait
    [data-slide='3'] .subtitle, .tablet.portrait
    [data-slide='4'] .subtitle, .tablet.portrait
    [data-slide='5'] .subtitle {
      font-size: 3vw; } }
  .desktop [data-slide='1'] .subtitle, .desktop
  [data-slide='3'] .subtitle, .desktop
  [data-slide='4'] .subtitle, .desktop
  [data-slide='5'] .subtitle {
    font-size: 0.85vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='1'] .subtitle, .desktop
      [data-slide='3'] .subtitle, .desktop
      [data-slide='4'] .subtitle, .desktop
      [data-slide='5'] .subtitle {
        font-size: 1.6vh; } }
  @media screen and (max-width: 1200px) {
    [data-slide='1'] .subtitle,
    [data-slide='3'] .subtitle,
    [data-slide='4'] .subtitle,
    [data-slide='5'] .subtitle {
      line-height: 1.7; } }
  @media screen and (max-width: 767px) {
    [data-slide='1'] .subtitle br,
    [data-slide='3'] .subtitle br,
    [data-slide='4'] .subtitle br,
    [data-slide='5'] .subtitle br {
      display: none; } }

[data-slide='1'] .word,
[data-slide='3'] .word,
[data-slide='4'] .word {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.15; }
  @media screen and (min-width: 1481px) {
    .mobile [data-slide='1'] .word,
    .tablet [data-slide='1'] .word, .mobile
    [data-slide='3'] .word,
    .tablet
    [data-slide='3'] .word, .mobile
    [data-slide='4'] .word,
    .tablet
    [data-slide='4'] .word {
      font-size: 19vh; } }
  @media screen and (min-width: 1201px) and (max-width: 1480px) {
    .mobile [data-slide='1'] .word,
    .tablet [data-slide='1'] .word, .mobile
    [data-slide='3'] .word,
    .tablet
    [data-slide='3'] .word, .mobile
    [data-slide='4'] .word,
    .tablet
    [data-slide='4'] .word {
      font-size: 14vh; } }
  @media screen and (max-width: 1200px) {
    .mobile [data-slide='1'] .word,
    .tablet [data-slide='1'] .word, .mobile
    [data-slide='3'] .word,
    .tablet
    [data-slide='3'] .word, .mobile
    [data-slide='4'] .word,
    .tablet
    [data-slide='4'] .word {
      font-size: 10vh; } }
  .desktop [data-slide='1'] .word, .desktop
  [data-slide='3'] .word, .desktop
  [data-slide='4'] .word {
    font-size: 9.5vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='1'] .word, .desktop
      [data-slide='3'] .word, .desktop
      [data-slide='4'] .word {
        font-size: 19vh; } }

.mobile .word,
.tablet.portrait .word {
  display: none; }

[data-slide='2'] .list,
[data-slide='5'] .list {
  line-height: 1.9; }
  @media screen and (min-width: 1481px) {
    .mobile [data-slide='2'] .list,
    .tablet [data-slide='2'] .list, .mobile
    [data-slide='5'] .list,
    .tablet
    [data-slide='5'] .list {
      font-size: 1.7vh; } }
  @media screen and (min-width: 1201px) and (max-width: 1480px) {
    .mobile [data-slide='2'] .list,
    .tablet [data-slide='2'] .list, .mobile
    [data-slide='5'] .list,
    .tablet
    [data-slide='5'] .list {
      font-size: 1.7vh; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .mobile [data-slide='2'] .list,
    .tablet [data-slide='2'] .list, .mobile
    [data-slide='5'] .list,
    .tablet
    [data-slide='5'] .list {
      font-size: 1.7vh; } }
  @media screen and (max-width: 767px) {
    .mobile [data-slide='2'] .list,
    .tablet [data-slide='2'] .list, .mobile
    [data-slide='5'] .list,
    .tablet
    [data-slide='5'] .list {
      font-size: 3.8vw; } }
  @media screen and (max-width: 767px) {
    .tablet.portrait [data-slide='2'] .list, .tablet.portrait
    [data-slide='5'] .list {
      font-size: 3vw; } }
  .desktop [data-slide='2'] .list, .desktop
  [data-slide='5'] .list {
    font-size: 0.85vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='2'] .list, .desktop
      [data-slide='5'] .list {
        font-size: 1.6vh; } }
  @media screen and (min-width: 1481px) {
    [data-slide='2'] .list,
    [data-slide='5'] .list {
      max-width: 80%; } }
  @media screen and (min-width: 1201px) and (max-width: 1480px) {
    [data-slide='2'] .list,
    [data-slide='5'] .list {
      max-width: 80%; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    [data-slide='2'] .list,
    [data-slide='5'] .list {
      line-height: 1.7;
      max-width: 80%; } }
  @media screen and (max-width: 767px) {
    [data-slide='2'] .list,
    [data-slide='5'] .list {
      line-height: 1.7; } }
  [data-slide='2'] .list li,
  [data-slide='5'] .list li {
    position: relative; }
    .tablet.portrait [data-slide='2'] .list li, .tablet.portrait
    [data-slide='5'] .list li {
      margin: 0.5rem 0; }
      @media screen and (min-width: 768px) {
        .tablet.portrait [data-slide='2'] .list li, .tablet.portrait
        [data-slide='5'] .list li {
          padding-left: 35px; }
          .tablet.portrait [data-slide='2'] .list li:before, .tablet.portrait
          [data-slide='5'] .list li:before {
            width: 22px;
            top: 1.4vh; } }
      @media screen and (max-width: 767px) {
        .tablet.portrait [data-slide='2'] .list li, .tablet.portrait
        [data-slide='5'] .list li {
          padding-left: 20px; }
          .tablet.portrait [data-slide='2'] .list li:before, .tablet.portrait
          [data-slide='5'] .list li:before {
            width: 15px;
            top: 2.5vw; } }
    .mobile [data-slide='2'] .list li, .mobile
    [data-slide='5'] .list li {
      margin: 0.5rem 0; }
      @media screen and (min-width: 768px) {
        .mobile [data-slide='2'] .list li, .mobile
        [data-slide='5'] .list li {
          padding-left: 35px; }
          .mobile [data-slide='2'] .list li:before, .mobile
          [data-slide='5'] .list li:before {
            width: 22px;
            top: 1vh; } }
      @media screen and (max-width: 767px) {
        .mobile [data-slide='2'] .list li, .mobile
        [data-slide='5'] .list li {
          padding-left: 20px; }
          .mobile [data-slide='2'] .list li:before, .mobile
          [data-slide='5'] .list li:before {
            width: 15px;
            top: 3vw; } }
    .tablet.landscape [data-slide='2'] .list li, .tablet.landscape
    [data-slide='5'] .list li {
      margin: 0.55vw 0;
      padding-left: 1vw; }
      .tablet.landscape [data-slide='2'] .list li:before, .tablet.landscape
      [data-slide='5'] .list li:before {
        width: 0.3vw;
        top: 1.5vh;
        height: 0.3vw;
        border-radius: 50%; }
      @media screen and (max-height: 46vw) {
        .tablet.landscape [data-slide='2'] .list li, .tablet.landscape
        [data-slide='5'] .list li {
          margin: 1.2vh 0; }
          .tablet.landscape [data-slide='2'] .list li:before, .tablet.landscape
          [data-slide='5'] .list li:before {
            top: 1.5vh; } }
    .desktop [data-slide='2'] .list li, .desktop
    [data-slide='5'] .list li {
      margin: 0.55vw 0;
      padding-left: 1vw; }
      .desktop [data-slide='2'] .list li:before, .desktop
      [data-slide='5'] .list li:before {
        width: 0.3vw;
        top: .65vw;
        height: 0.3vw;
        border-radius: 50%; }
      @media screen and (max-height: 46vw) {
        .desktop [data-slide='2'] .list li, .desktop
        [data-slide='5'] .list li {
          margin: 1.2vh 0; }
          .desktop [data-slide='2'] .list li:before, .desktop
          [data-slide='5'] .list li:before {
            top: 1.5vh; } }
    [data-slide='2'] .list li:before,
    [data-slide='5'] .list li:before {
      content: "";
      position: absolute;
      left: 0;
      height: 1px;
      background-color: #bebebf; }

.item-title {
  font-weight: 700;
  color: #fff; }
  .mobile .item-title,
  .tablet.portrait .item-title {
    font-size: 5vw;
    margin-bottom: 2vw; }
  .tablet.landscape .item-title {
    margin-bottom: 1.6vh; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape .item-title {
        font-size: 3.2vh; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape .item-title {
        font-size: 2.4vh; } }
    @media screen and (max-width: 1200px) {
      .tablet.landscape .item-title {
        font-size: 2.2vh; } }
  .desktop .item-title {
    font-size: 1.65vw;
    margin-bottom: 0.7vw; }
    @media screen and (max-height: 46vw) {
      .desktop .item-title {
        font-size: 3.5vh;
        margin-bottom: 2vh; } }

.item-subtitle {
  color: #bebebf; }
  .mobile .item-subtitle,
  .tablet.portrait .item-subtitle {
    font-size: 3.8vw;
    line-height: 1.7; }
  .tablet.landscape .item-subtitle {
    line-height: 1.9; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape .item-subtitle {
        font-size: 1.7vh; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape .item-subtitle {
        font-size: 1.7vh; } }
    @media screen and (max-width: 1200px) {
      .tablet.landscape .item-subtitle {
        font-size: 1.7vh;
        line-height: 1.7; } }
  .desktop .item-subtitle {
    line-height: 1.9;
    font-size: 0.85vw; }
    @media screen and (max-height: 46vw) {
      .desktop .item-subtitle {
        font-size: 1.6vh; } }

.desktop .elements {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto; }

.mobile .elements,
.tablet .elements {
  display: none; }

.desktop .elements-fullwidth {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto; }

.mobile .elements-fullwidth,
.tablet .elements-fullwidth {
  display: none; }

[data-elements="1"] {
  position: absolute;
  left: 4.6%;
  top: 50px;
  width: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; }
  [data-elements="1"] .switch-box {
    position: relative; }
    [data-current="2"] [data-elements="1"] .switch-box {
      transition: 1.2s ease 0.2s; }
    [data-current="3"] [data-elements="1"] .switch-box,
    [data-current="4"] [data-elements="1"] .switch-box,
    [data-current="5"] [data-elements="1"] .switch-box,
    [data-current="6"] [data-elements="1"] .switch-box,
    [data-current="7"] [data-elements="1"] .switch-box,
    [data-current="8"] [data-elements="1"] .switch-box,
    [data-current="9"] [data-elements="1"] .switch-box,
    [data-current="10"] [data-elements="1"] .switch-box {
      transition: 0.2s ease;
      opacity: 0; }
  [data-elements="1"] .switcher {
    position: relative;
    transition: opacity 2s ease 0.4s, transform 2.5s ease 0.4s;
    transform: translate3d(0, 0, 0);
    opacity: 1; }
    .begin [data-elements="1"] .switcher {
      transform: translate3d(-10%, -7%, 0);
      opacity: 0; }
    [data-elements="1"] .switcher img {
      display: block;
      width: auto;
      height: auto;
      max-width: 20vw;
      max-height: 60vh;
      width: 100vw; }
  [data-elements="1"] .key,
  [data-elements="1"] .key-digits {
    position: absolute;
    left: -27%;
    top: -5%;
    width: 43%;
    padding-top: 103.2%;
    transform: translate3d(0, 0, 0);
    opacity: 1; }
    [data-current="3"] [data-elements="1"] .key,
    [data-current="4"] [data-elements="1"] .key,
    [data-current="5"] [data-elements="1"] .key,
    [data-current="6"] [data-elements="1"] .key,
    [data-current="7"] [data-elements="1"] .key,
    [data-current="8"] [data-elements="1"] .key,
    [data-current="9"] [data-elements="1"] .key,
    [data-current="10"] [data-elements="1"] .key, [data-current="3"]
    [data-elements="1"] .key-digits,
    [data-current="4"]
    [data-elements="1"] .key-digits,
    [data-current="5"]
    [data-elements="1"] .key-digits,
    [data-current="6"]
    [data-elements="1"] .key-digits,
    [data-current="7"]
    [data-elements="1"] .key-digits,
    [data-current="8"]
    [data-elements="1"] .key-digits,
    [data-current="9"]
    [data-elements="1"] .key-digits,
    [data-current="10"]
    [data-elements="1"] .key-digits {
      opacity: 0; }
    [data-elements="1"] .key img, [data-elements="1"] .key svg,
    [data-elements="1"] .key-digits img,
    [data-elements="1"] .key-digits svg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  [data-elements="1"] .key {
    transition: opacity 0.3s ease 0.5s, transform 2.7s ease 0.6s; }
    [data-elements="1"] .key .key-lines {
      transition: opacity 1s ease 1s; }
    .begin [data-elements="1"] .key {
      transform: translate3d(-19%, -9%, 0);
      opacity: 0; }
      .begin [data-elements="1"] .key .key-lines {
        opacity: 0; }
    [data-current="1"] [data-elements="1"] .key {
      transition: opacity 1.4s ease, transform 1.4s; }
    [data-current="2"] [data-elements="1"] .key {
      transition: opacity 1.4s ease, transform 1.4s;
      transform: translate3d(-19%, -9%, 0);
      opacity: 0; }
  [data-elements="1"] .key-digits {
    transition: opacity 1s ease 1s; }
    .begin [data-elements="1"] .key-digits {
      opacity: 0; }
    [data-current="2"] [data-elements="1"] .key-digits {
      transition: opacity 1.4s ease;
      opacity: 0; }
  [data-elements="1"] #key_line1 {
    stroke-dasharray: 102.4447;
    stroke-dashoffset: 0;
    transition: all 1s linear 0.5s; }
    .begin [data-elements="1"] #key_line1 {
      stroke-dashoffset: 102.4447; }
  [data-elements="1"] #key_line2 {
    stroke-dasharray: 142.1125;
    stroke-dashoffset: 0;
    transition: all 1s linear 0.5s; }
    .begin [data-elements="1"] #key_line2 {
      stroke-dashoffset: 142.1125; }
  [data-elements="1"] #key_line3 {
    stroke-dasharray: 199.2661;
    stroke-dashoffset: 0;
    transition: all 1s linear 0.5s; }
    .begin [data-elements="1"] #key_line3 {
      stroke-dashoffset: 199.2661; }
  [data-elements="1"] #key_line4 {
    stroke-dasharray: 134.7162;
    stroke-dashoffset: 0;
    transition: all 1s linear 0.5s; }
    .begin [data-elements="1"] #key_line4 {
      stroke-dashoffset: 134.7162; }
  [data-elements="1"] .light {
    left: 27%;
    top: 34%;
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #b3b4b6;
    transition: box-shadow 0.5s ease, opacity 1.4s ease 0.5s;
    will-change: transform;
    opacity: 1;
    box-shadow: 0 0 15vw 10vw rgba(179, 180, 182, 0.6);
    animation: light 10s infinite 0.5s alternate forwards; }
    .begin [data-elements="1"] .light {
      opacity: 0;
      box-shadow: 0 0 10vw 5vw rgba(179, 180, 182, 0.6);
      animation: none; }
    [data-current="5"] [data-elements="1"] .light {
      opacity: 0; }
  [data-elements="1"] .finger {
    position: absolute;
    left: 44%;
    top: 11%;
    width: 76%;
    padding-top: 141.2%; }
    [data-elements="1"] .finger svg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    [data-current="3"] [data-elements="1"] .finger,
    [data-current="4"] [data-elements="1"] .finger,
    [data-current="5"] [data-elements="1"] .finger,
    [data-current="6"] [data-elements="1"] .finger,
    [data-current="7"] [data-elements="1"] .finger,
    [data-current="8"] [data-elements="1"] .finger,
    [data-current="9"] [data-elements="1"] .finger,
    [data-current="10"] [data-elements="1"] .finger {
      transition: 0.2s ease;
      opacity: 0; }
  [data-elements="1"] #finger {
    stroke-dasharray: 516.1923;
    stroke-dashoffset: 0;
    transition: all 1.8s linear 0.5s; }
    .begin [data-elements="1"] #finger {
      stroke-dashoffset: 516.1923; }
    [data-current="1"] [data-elements="1"] #finger {
      transition: all 1.3s linear; }
    [data-current="2"] [data-elements="1"] #finger {
      transition: all 1.3s linear;
      stroke-dashoffset: 516.1923; }
    [data-current="3"] [data-elements="1"] #finger {
      stroke-dashoffset: 516.1923; }

@keyframes light {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.2); } }

[data-elements="2"] {
  position: absolute;
  left: 12.7%;
  top: 7%;
  width: 39%;
  bottom: 4%;
  display: flex;
  align-items: center;
  justify-content: center; }
  [data-current="5"] [data-elements="2"] {
    transition: transform 1.2s ease-in-out; }
  [data-current="6"] [data-elements="2"] {
    transform: translate3d(0, -100vh, 0);
    transition: transform 1.2s cubic-bezier(0.4, 0.5, 0.7, 0.21), opacity 0.1s ease 1.4s; }
  [data-current="7"] [data-elements="2"] {
    opacity: 0; }
  [data-elements="2"] .people-box {
    width: 80%;
    position: relative; }
  [data-elements="2"] .part {
    width: 100%;
    padding-top: 92.476%; }
    [data-elements="2"] .part svg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    [data-elements="2"] .part.absolute {
      position: absolute;
      top: 0;
      left: 0; }
  [data-elements="2"] .cassette {
    opacity: 0; }
    [data-current="3"] [data-elements="2"] .cassette .group {
      transition: all 1s linear; }
    [data-current="4"] [data-elements="2"] .cassette .group {
      transition: all 1s linear;
      transform: translate3d(-1%, -3%, 0); }
    [data-current="2"] [data-elements="2"] .cassette {
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .cassette {
      opacity: 1;
      transition: opacity 0.2s linear 0.5s; }
    [data-current="4"] [data-elements="2"] .cassette {
      opacity: 1; }
  [data-elements="2"] .icons .group {
    transform-origin: 50% 50%;
    opacity: 0; }
    [data-current="2"] [data-elements="2"] .icons .group {
      transition: opacity 0.2s linear, transform 0.1s ease 0.3s;
      opacity: 0; }
    [data-current="3"] [data-elements="2"] .icons .group {
      opacity: 1;
      transform: rotate(-10deg);
      transition: transform 0.4s linear 1s, opacity 0.2s linear 0.2s; }
    [data-current="4"] [data-elements="2"] .icons .group {
      opacity: 1;
      transition: transform 0.2s linear 1.2s; }
    [data-current="5"] [data-elements="2"] .icons .group {
      opacity: 1;
      transform: rotate(20deg);
      transition: transform 0.4s linear 0.9s, opacity 0.2s linear 0.2s; }
    [data-current="6"] [data-elements="2"] .icons .group {
      opacity: 1;
      transform: rotate(20deg); }
    [data-elements="2"] .icons .group-icon {
      opacity: 0; }
      [data-current="3"] [data-elements="2"] .icons .group-icon {
        opacity: 1; }
      [data-current="4"] [data-elements="2"] .icons .group-icon {
        opacity: 1; }
      [data-current="5"] [data-elements="2"] .icons .group-icon {
        opacity: 1; }
      [data-current="6"] [data-elements="2"] .icons .group-icon {
        opacity: 1; }
    [data-current="3"] [data-elements="2"] .icons .group #icon01_1, [data-current="3"]
    [data-elements="2"] .icons .group #icon01_2 {
      transition: opacity 0.4s ease 0.3s; }
    [data-current="3"] [data-elements="2"] .icons .group #icon02_1, [data-current="3"]
    [data-elements="2"] .icons .group #icon02_2 {
      transition: opacity 0.4s ease 0.4s; }
    [data-current="3"] [data-elements="2"] .icons .group #icon03_1, [data-current="3"]
    [data-elements="2"] .icons .group #icon03_2 {
      transition: opacity 0.4s ease 0.5s; }
    [data-current="3"] [data-elements="2"] .icons .group #icon04_1, [data-current="3"]
    [data-elements="2"] .icons .group #icon04_2 {
      transition: opacity 0.4s ease 0.6s; }
    [data-current="3"] [data-elements="2"] .icons .group #icon05_1, [data-current="3"]
    [data-elements="2"] .icons .group #icon05_2 {
      transition: opacity 0.4s ease 0.7s; }
    [data-current="3"] [data-elements="2"] .icons .group #icon06_1, [data-current="3"]
    [data-elements="2"] .icons .group #icon06_2 {
      transition: opacity 0.4s ease 0.8s; }
  [data-elements="2"] .calc .num {
    opacity: 0; }
    [data-current="3"] [data-elements="2"] .calc .num {
      transition: all 0.3s; }
    [data-current="4"] [data-elements="2"] .calc .num {
      transition: opacity 0.2s ease 0.5s;
      opacity: 1; }
  [data-current="4"] [data-elements="2"] .calc #num01 {
    animation: calc_press 0.3s linear 0.6s alternate forwards; }
  [data-current="4"] [data-elements="2"] .calc #num02 {
    animation: calc_press 0.3s linear 0.65s alternate forwards; }
  [data-current="4"] [data-elements="2"] .calc #num03 {
    animation: calc_press 0.3s linear 0.7s alternate forwards; }
  [data-current="4"] [data-elements="2"] .calc #num04 {
    animation: calc_press 0.3s linear 0.75s alternate forwards; }
  [data-elements="2"] .calc #num05 {
    opacity: 0; }
    [data-current="3"] [data-elements="2"] .calc #num05 {
      transition: all 0.3s; }
    [data-current="4"] [data-elements="2"] .calc #num05 {
      opacity: 1;
      transition: opacity 0.3s ease 0.8s; }
  [data-elements="2"] .calc #vector {
    stroke-dasharray: 20.942;
    stroke-dashoffset: 20.942; }
    [data-current="4"] [data-elements="2"] .calc #vector {
      transition: stroke-dashoffset 0.3s linear 0.6s;
      stroke-dashoffset: 0; }
  [data-current="4"] [data-elements="2"] .key {
    opacity: 0;
    transition: opacity 0.2s ease; }
  [data-elements="2"] .key #keypath1 {
    stroke-dasharray: 110.257;
    stroke-dashoffset: 110.257; }
    [data-current="5"] [data-elements="2"] .key #keypath1 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.3s linear 0.7s; }
    [data-current="6"] [data-elements="2"] .key #keypath1 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .key #keypath2 {
    stroke-dasharray: 66.565;
    stroke-dashoffset: 66.565; }
    [data-current="5"] [data-elements="2"] .key #keypath2 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.3s linear 0.7s; }
    [data-current="6"] [data-elements="2"] .key #keypath2 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .key #keypath3 {
    stroke-dasharray: 53.532;
    stroke-dashoffset: 53.532; }
    [data-current="5"] [data-elements="2"] .key #keypath3 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.3s linear 0.7s; }
    [data-current="6"] [data-elements="2"] .key #keypath3 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .key #keypath4 {
    stroke-dasharray: 20.219;
    stroke-dashoffset: 20.219; }
    [data-current="5"] [data-elements="2"] .key #keypath4 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.3s linear 0.7s; }
    [data-current="6"] [data-elements="2"] .key #keypath4 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .key #keypath5 {
    stroke-dasharray: 47.874;
    stroke-dashoffset: 47.874; }
    [data-current="5"] [data-elements="2"] .key #keypath5 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.3s linear 0.7s; }
    [data-current="6"] [data-elements="2"] .key #keypath5 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .key #keypath6 {
    stroke-dasharray: 28.155;
    stroke-dashoffset: 28.155; }
    [data-current="5"] [data-elements="2"] .key #keypath6 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.3s linear 0.7s; }
    [data-current="6"] [data-elements="2"] .key #keypath6 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .gadget {
    opacity: 0;
    transform-origin: center 0; }
    [data-current="4"] [data-elements="2"] .gadget {
      transition: opacity 0.2s ease, transform 0.1s ease 0.3s;
      transform: scale(0.3); }
    [data-current="5"] [data-elements="2"] .gadget {
      opacity: 1;
      transition: all 0.4s ease 0.6s; }
    [data-current="6"] [data-elements="2"] .gadget {
      opacity: 1; }
  [data-current="3"] [data-elements="2"] .right-man {
    transition: opacity 0.2s linear 0.5s; }
  [data-current="4"] [data-elements="2"] .right-man {
    transition: opacity 0.2s linear;
    opacity: 0; }
  [data-elements="2"] .right-man #man_right_glass {
    stroke-dasharray: 27.7802;
    stroke-dashoffset: 27.7802; }
    [data-current="2"] [data-elements="2"] .right-man #man_right_glass {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .right-man #man_right_glass {
      transition: stroke-dashoffset 0.1s linear 0.6s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .right-man #man_right_glass {
      transition: stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .right-man #man_right_hat {
    stroke-dasharray: 166.1476;
    stroke-dashoffset: 166.1476; }
    [data-current="2"] [data-elements="2"] .right-man #man_right_hat {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .right-man #man_right_hat {
      transition: stroke-dashoffset 0.3s linear 0.6s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .right-man #man_right_hat {
      transition: stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .right-man #man_right_hand_01 {
    stroke-dasharray: 106.7926;
    stroke-dashoffset: 106.7926; }
    [data-current="2"] [data-elements="2"] .right-man #man_right_hand_01 {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .right-man #man_right_hand_01 {
      transition: stroke-dashoffset 0.3s linear 0.8s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .right-man #man_right_hand_01 {
      transition: stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .right-man #man_right_hand_02 {
    stroke-dasharray: 96.5929;
    stroke-dashoffset: 96.5929; }
    [data-current="2"] [data-elements="2"] .right-man #man_right_hand_02 {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .right-man #man_right_hand_02 {
      transition: stroke-dashoffset 0.2s linear 0.9s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .right-man #man_right_hand_02 {
      transition: stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .right-man #man_right_hand_03 {
    stroke-dasharray: 220.2593;
    stroke-dashoffset: 220.2593; }
    [data-current="2"] [data-elements="2"] .right-man #man_right_hand_03 {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .right-man #man_right_hand_03 {
      transition: stroke-dashoffset 0.3s linear 0.9s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .right-man #man_right_hand_03 {
      transition: stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .right-man #man_right_hand_04 {
    stroke-dasharray: 92.5027;
    stroke-dashoffset: 92.5027; }
    [data-current="2"] [data-elements="2"] .right-man #man_right_hand_04 {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .right-man #man_right_hand_04 {
      transition: stroke-dashoffset 0.1s linear 1.1s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .right-man #man_right_hand_04 {
      transition: stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .left-man #man_left_body {
    stroke-dasharray: 209.985;
    stroke-dashoffset: -209.985; }
    [data-current="2"] [data-elements="2"] .left-man #man_left_body {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .left-man #man_left_body {
      transition: stroke-dashoffset 0.5s linear 0.2s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .left-man #man_left_body {
      stroke-dashoffset: 0; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_body {
      stroke-dashoffset: 0; }
    [data-current="6"] [data-elements="2"] .left-man #man_left_body {
      stroke-dashoffset: 0; }
  [data-elements="2"] .left-man #man_left_face {
    stroke-dasharray: 41.6786;
    stroke-dashoffset: 41.6786; }
    [data-current="2"] [data-elements="2"] .left-man #man_left_face {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .left-man #man_left_face {
      transition: stroke-dashoffset 0.2s linear 0.3s;
      stroke-dashoffset: 5; }
    [data-current="4"] [data-elements="2"] .left-man #man_left_face {
      transition: stroke-dashoffset 0.2s linear 0.3s;
      stroke-dashoffset: 0; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_face {
      transition: stroke-dashoffset 0.2s linear 0.3s;
      stroke-dashoffset: 5; }
    [data-current="6"] [data-elements="2"] .left-man #man_left_face {
      stroke-dashoffset: 5; }
  [data-elements="2"] .left-man #man_left_hand_01 {
    stroke-dasharray: 209.153;
    stroke-dashoffset: 209.153; }
    [data-current="2"] [data-elements="2"] .left-man #man_left_hand_01 {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] .left-man #man_left_hand_01 {
      transition: stroke-dashoffset 0.5s linear 0.2s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] .left-man #man_left_hand_01 {
      stroke-dashoffset: 0;
      transition: opacity 0.2s ease, stroke-dashoffset 0.2s linear 0.2s; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_hand_01 {
      opacity: 0;
      transition: opacity 0.2s ease 0.4s, stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .left-man #man_left_hand_02 {
    stroke-dasharray: 206.088;
    stroke-dashoffset: 206.088; }
    [data-current="3"] [data-elements="2"] .left-man #man_left_hand_02 {
      transition: stroke-dashoffset 0.3s linear; }
    [data-current="4"] [data-elements="2"] .left-man #man_left_hand_02 {
      transition: stroke-dashoffset 0.6s linear 0.1s, opacity 0.4s ease;
      stroke-dashoffset: -34; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_hand_02 {
      opacity: 0;
      transition: opacity 0.2s ease, stroke-dashoffset 0.1s linear 1s; }
  [data-elements="2"] .left-man #man_left_fingers01 {
    stroke-dasharray: 9.252;
    stroke-dashoffset: 9.252; }
    [data-current="3"] [data-elements="2"] .left-man #man_left_fingers01 {
      transition: stroke-dashoffset 0.1s linear; }
    [data-current="4"] [data-elements="2"] .left-man #man_left_fingers01 {
      transition: stroke-dashoffset 0.1s linear 0.5s;
      stroke-dashoffset: 0; }
  [data-elements="2"] .left-man #man_left_fingers02 {
    stroke-dasharray: 68.8853;
    stroke-dashoffset: -68.8853; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_fingers02 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.2s linear 0.8s; }
    [data-current="6"] [data-elements="2"] .left-man #man_left_fingers02 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .left-man #man_left_hand_03 {
    stroke-dasharray: 196.3367;
    stroke-dashoffset: 196.3367; }
    [data-current="4"] [data-elements="2"] .left-man #man_left_hand_03 {
      transition: stroke-dashoffset 0.2s linear; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_hand_03 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.5s linear 0.4s; }
    [data-current="6"] [data-elements="2"] .left-man #man_left_hand_03 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .left-man #man_left_hand_04 {
    stroke-dasharray: 211.3337;
    stroke-dashoffset: -211.3337; }
    [data-current="5"] [data-elements="2"] .left-man #man_left_hand_04 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.5s linear 0.2s; }
    [data-current="6"] [data-elements="2"] .left-man #man_left_hand_04 {
      stroke-dashoffset: 0; }
  [data-elements="2"] .sms .text1 {
    opacity: 0; }
    [data-current="3"] [data-elements="2"] .sms .text1 {
      transition: opacity 0.2s linear; }
    [data-current="4"] [data-elements="2"] .sms .text1 {
      transition: opacity 0.2s linear 0.8s;
      opacity: 1; }
  [data-elements="2"] .sms .text2 {
    opacity: 0; }
    [data-current="3"] [data-elements="2"] .sms .text2 {
      transition: opacity 0.2s linear; }
    [data-current="4"] [data-elements="2"] .sms .text2 {
      transition: opacity 0.2s linear 1s;
      opacity: 1; }
  [data-current="3"] [data-elements="2"] .sms .boxes {
    opacity: 0;
    transition: opacity 0.2s linear; }
  [data-elements="2"] .sms #letter1 {
    stroke-dasharray: 35.6524;
    stroke-dashoffset: 35.6524; }
    [data-current="4"] [data-elements="2"] .sms #letter1 {
      transition: stroke-dashoffset 0.2s linear 0.75s;
      stroke-dashoffset: 0; }
  [data-elements="2"] .sms #box1 {
    stroke-dasharray: 57.4683;
    stroke-dashoffset: 57.4683; }
    [data-current="4"] [data-elements="2"] .sms #box1 {
      transition: stroke-dashoffset 0.2s linear 0.7s;
      stroke-dashoffset: 0; }
  [data-elements="2"] .sms #box2 {
    stroke-dasharray: 100.6875;
    stroke-dashoffset: 100.6875; }
    [data-current="4"] [data-elements="2"] .sms #box2 {
      transition: stroke-dashoffset 0.2s linear 1s;
      stroke-dashoffset: 0; }
  [data-elements="2"] .sms #box3 {
    stroke-dasharray: 109.895;
    stroke-dashoffset: 109.895; }
    [data-current="4"] [data-elements="2"] .sms #box3 {
      transition: stroke-dashoffset 0.2s linear 1.2s;
      stroke-dashoffset: 0; }
  [data-elements="2"] .switcher-mini {
    position: absolute;
    left: 10%;
    top: 7%;
    opacity: 0;
    transform: scale(0.66) translate3d(-140%, -2%, 0); }
    [data-elements="2"] .switcher-mini:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      border-radius: 10px;
      background-color: #17191f;
      box-shadow: 2vw 0 7vw 4vw #17191f; }
    [data-elements="2"] .switcher-mini img {
      display: block;
      width: 100vw;
      height: auto;
      max-width: 20vw;
      max-height: 60vh; }
    [data-current="4"] [data-elements="2"] .switcher-mini {
      transition: opacity 0.6s ease, transform 0.6s ease; }
    [data-current="5"] [data-elements="2"] .switcher-mini {
      opacity: 0.3;
      transition: opacity 0.9s ease 0.5s, transform 0.9s ease 0.5s;
      transform: scale(0.66) translate3d(-135%, 2%, 0); }
    [data-current="6"] [data-elements="2"] .switcher-mini {
      opacity: 0;
      transform: scale(0.66) translate3d(-140%, -2%, 0);
      transition: opacity 0.4s ease 0.1s, transform 0.1s ease 1.4s; }
  [data-elements="2"] #line01 {
    stroke-dasharray: 56.845;
    stroke-dashoffset: 56.845; }
    [data-current="2"] [data-elements="2"] #line01 {
      opacity: 0;
      transition: all 0.2s linear; }
    [data-current="3"] [data-elements="2"] #line01 {
      transition: stroke-dashoffset 0.4s linear 0.8s;
      stroke-dashoffset: 0; }
    [data-current="4"] [data-elements="2"] #line01 {
      transition: stroke-dashoffset 0.2s linear 1.2s;
      stroke-dashoffset: 16; }
  [data-elements="2"] #line02 {
    stroke-dasharray: 30.347;
    stroke-dashoffset: -30.347; }
    [data-current="5"] [data-elements="2"] #line02 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.1s linear 0.7s; }
    [data-current="6"] [data-elements="2"] #line02 {
      stroke-dashoffset: 0; }
  [data-elements="2"] #line03 {
    stroke-dasharray: 80.455;
    stroke-dashoffset: 80.455; }
    [data-current="5"] [data-elements="2"] #line03 {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.4s linear 1s; }
    [data-current="6"] [data-elements="2"] #line03 {
      stroke-dashoffset: 0; }

@keyframes calc_press {
  0% {
    fill: none; }
  50% {
    fill: #b3b3b3; }
  100% {
    fill: none; } }

[data-elements="3"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  overflow: hidden; }
  [data-elements="3"] .line {
    height: 0;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 105vh; }
    [data-current="1"] [data-elements="3"] .line {
      top: 105vh;
      height: 0;
      transition: top 0.7s linear, height 0.1s ease 1.3s; }
    [data-current="2"] [data-elements="3"] .line {
      top: 33vh;
      height: 100vh;
      transform: translate3d(0, 0, 0);
      transition: height 0.4s ease 1.1s, transform 1.1s ease; }
    [data-current="3"] [data-elements="3"] .line {
      height: 100vh;
      top: 33vh;
      transform: translate3d(0, -33vh, 0);
      transition: transform 1.1s ease; }
    [data-current="4"] [data-elements="3"] .line {
      height: 100vh;
      top: 33vh;
      transform: translate3d(0, -33vh, 0); }
    [data-current="5"] [data-elements="3"] .line {
      height: 100vh;
      top: 33vh;
      transform: translate3d(0, -33vh, 0);
      transition: height 0.6s ease 0.6s; }
    [data-current="6"] [data-elements="3"] .line {
      top: 0;
      height: 24vh;
      opacity: 1;
      transition: all 0.8s ease-in, opacity 0.1s ease 0.8s; }
    [data-current="7"] [data-elements="3"] .line {
      opacity: 0; }
    [data-current="8"] [data-elements="3"] .line {
      top: auto;
      bottom: 0;
      height: 100vh;
      transform: translate3d(0, 100vh, 0);
      transition: transform 1.4s ease-in-out, opacity 0.01s ease 1.3s; }
      [data-current="8"] [data-elements="3"] .line.not-complited {
        top: 0;
        bottom: auto;
        height: 20vh; }
    [data-current="9"] [data-elements="3"] .line {
      top: auto;
      bottom: 0;
      height: 100vh;
      transition: transform 1.4s ease-in-out; }
      [data-current="9"] [data-elements="3"] .line.not-complited {
        top: 0;
        bottom: auto;
        height: 20vh; }
    [data-current="10"] [data-elements="3"] .line {
      top: auto;
      bottom: 0;
      height: 100vh; }
  [data-elements="3"] .light {
    opacity: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 0;
    right: 0;
    top: 33vh;
    z-index: 1;
    margin: -5px auto 0;
    animation: small_light 2s infinite linear alternate; }
    [data-current="1"] [data-elements="3"] .light {
      top: 105vh;
      transition: top 0.7s linear, opacity 0.1s ease 1.3s; }
    [data-current="2"] [data-elements="3"] .light {
      opacity: 1;
      transition: opacity 1s ease 1s; }
    [data-current="3"] [data-elements="3"] .light {
      opacity: 1; }
    [data-current="4"] [data-elements="3"] .light {
      opacity: 1; }
    [data-current="5"] [data-elements="3"] .light {
      opacity: 1;
      transition: top 0.6s ease 0.6s; }
    [data-current="6"] [data-elements="3"] .light {
      opacity: 1;
      top: 24vh;
      transition: top 0.5s ease, opacity 0.2s ease 1s; }
    [data-current="7"] [data-elements="3"] .light {
      opacity: 0;
      top: 24vh;
      margin-top: -10vh;
      transition: opacity 0.2s ease, margin-top 0.65s ease; }
    [data-current="8"] [data-elements="3"] .light {
      opacity: 0;
      top: 120vh;
      margin-top: -10vh;
      transition: opacity 0.1s ease 0.3s, margin-top 0.65s ease, top 0.1s ease 1.4s; }
      [data-current="8"] [data-elements="3"] .light.not-complited {
        top: 20vh; }
    [data-current="9"] [data-elements="3"] .light {
      opacity: 1;
      top: 20vh;
      transition: top 0.9s ease-in-out 0.3s, opacity 0.1s ease 0.1s; }
      [data-current="9"] [data-elements="3"] .light.not-complited {
        top: 20vh;
        transition: opacity 0.1s ease 0.8s, margin-top 0.65s ease, top 0.1s ease 1.4s; }
    [data-current="10"] [data-elements="3"] .light {
      z-index: 2;
      opacity: 0;
      top: 20vh;
      transition: top 0.9s ease-in-out 0.1s, opacity 0.1s ease 0.8s; }
  [data-elements="3"] .boxes_application {
    position: absolute;
    top: 24vh;
    left: 50px;
    right: 50px;
    bottom: 16vh;
    background-color: #17191f;
    opacity: 0; }
    [data-elements="3"] .boxes_application:after {
      content: "";
      left: 0;
      right: 0;
      top: 100%;
      transform: translate3d(0, 16vh, 0);
      height: 24vh;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: auto;
      position: absolute; }
    [data-current="5"] [data-elements="3"] .boxes_application {
      transition: opacity 0.3s ease; }
    [data-current="6"] [data-elements="3"] .boxes_application {
      opacity: 1;
      transition: opacity 0.01s ease 0.9s, transform 1.2s ease-in-out; }
    [data-current="7"] [data-elements="3"] .boxes_application {
      opacity: 1;
      transform: translate3d(0, -100vh, 0);
      transition: transform 1.4s ease-in-out, opacity 0.05s ease 1.3s; }
    [data-current="8"] [data-elements="3"] .boxes_application {
      transform: translate3d(0, -100vh, 0); }
    [data-elements="3"] .boxes_application .box1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
      background-color: #17191f;
      border-width: 1px;
      border-style: solid solid none solid;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_application .box1 {
          border-radius: 21vh 21vh 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_application .box1 {
          border-radius: 16vh 16vh 0 0; } }
      @media screen and (min-width: 1921px) {
        .portrait [data-elements="3"] .boxes_application .box1 {
          border-radius: 10vh 10vh 0 0; } }
      [data-current="5"] [data-elements="3"] .boxes_application .box1 {
        opacity: 0;
        transition: opacity 0.3s; }
    [data-elements="3"] .boxes_application .box2 {
      position: absolute;
      left: 0;
      top: 50%;
      right: 75%;
      bottom: 0;
      background-color: #17191f;
      border-width: 1px;
      border-style: none none solid solid;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_application .box2 {
          border-radius: 0 0 0 21vh; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_application .box2 {
          border-radius: 0 0 0 16vh; } }
      @media screen and (min-width: 1921px) {
        .portrait [data-elements="3"] .boxes_application .box2 {
          border-radius: 0 0 0 10vh; } }
      [data-current="5"] [data-elements="3"] .boxes_application .box2 {
        opacity: 0;
        transition: opacity 0.3s; }
    [data-elements="3"] .boxes_application .box3 {
      position: absolute;
      right: 0;
      top: 50%;
      left: 75%;
      bottom: 0;
      background-color: #17191f;
      border-width: 1px;
      border-style: none solid solid none;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_application .box3 {
          border-radius: 0 0 21vh 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_application .box3 {
          border-radius: 0 0 16vh 0; } }
      @media screen and (min-width: 1921px) {
        .portrait [data-elements="3"] .boxes_application .box3 {
          border-radius: 0 0 10vh 0; } }
      [data-current="5"] [data-elements="3"] .boxes_application .box3 {
        opacity: 0;
        transition: opacity 0.3s; }
    [data-elements="3"] .boxes_application .box4 {
      position: absolute;
      right: 50%;
      top: 100%;
      left: 25%;
      height: 100px;
      background-color: #17191f;
      border-width: 1px;
      border-style: solid solid none none;
      border-color: rgba(255, 255, 255, 0.2);
      margin-top: -1px;
      margin-right: -0.5px; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_application .box4 {
          border-radius: 0 100px 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_application .box4 {
          border-radius: 0 50px 0 0; } }
      [data-current="5"] [data-elements="3"] .boxes_application .box4 {
        opacity: 0;
        transition: opacity 0.3s; }
    [data-elements="3"] .boxes_application .box5 {
      position: absolute;
      left: 50%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: #17191f;
      border-width: 1px;
      border-style: solid none none solid;
      border-color: rgba(255, 255, 255, 0.2);
      margin-top: -1px;
      margin-left: -0.5px; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_application .box5 {
          border-radius: 100px 0 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_application .box5 {
          border-radius: 50px 0 0 0; } }
      [data-current="5"] [data-elements="3"] .boxes_application .box5 {
        opacity: 0;
        transition: opacity 0.3s; }
    [data-elements="3"] .boxes_application .box6 {
      position: absolute;
      left: 25%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: #17191f;
      margin-top: -1px; }
      [data-elements="3"] .boxes_application .box6:before {
        content: "";
        top: 100%;
        left: 0;
        right: 0;
        margin: auto;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        height: 0;
        opacity: 0; }
        [data-current="5"] [data-elements="3"] .boxes_application .box6:before {
          transition: height 0.1s ease 1s, opacity 0.3s ease; }
        [data-current="6"] [data-elements="3"] .boxes_application .box6:before {
          opacity: 1;
          height: calc(16vh - 100px);
          transition: height 0.2s ease 1.4s; }
        [data-current="7"] [data-elements="3"] .boxes_application .box6:before {
          opacity: 1;
          height: calc(16vh - 100px); }
    [data-elements="3"] .boxes_application .light1_block {
      position: absolute;
      opacity: 0;
      height: 21vh;
      width: 2px;
      background-color: transparent;
      margin-left: -1px;
      left: 50%;
      transition: all 0.2s;
      transform-origin: bottom center;
      top: 0;
      z-index: 2; }
      [data-current="6"] [data-elements="3"] .boxes_application .light1_block {
        opacity: 1;
        left: 35vh;
        transition: opacity 0.4s ease 1.1s, left 0.1s linear 1.2s; }
      [data-current="7"] [data-elements="3"] .boxes_application .light1_block {
        left: 35vh;
        opacity: 0;
        transition: opacity 0.05s ease 0.3s;
        animation: leftLightAnimation linear 0.3s forwards; }
      [data-elements="3"] .boxes_application .light1_block .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px 0 0 -4px;
        z-index: 2; }
    [data-elements="3"] .boxes_application .light1_block_part2 {
      position: absolute;
      opacity: 0;
      height: 100px;
      width: 2px;
      background-color: transparent;
      margin-left: -1px;
      left: calc(50% - 100px);
      transition: all 0.1s;
      transform-origin: bottom center;
      top: 100%;
      z-index: 2; }
      [data-current="7"] [data-elements="3"] .boxes_application .light1_block_part2 {
        opacity: 1;
        margin-top: calc(60vh - 100px);
        transition: opacity 0.05s ease 0.3s, transform 0.1s linear 0.3s, margin 0.2s ease 0.4s;
        transform: rotate(90deg); }
      [data-elements="3"] .boxes_application .light1_block_part2 .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px 0 0 -4px;
        z-index: 2; }
        [data-current="7"] [data-elements="3"] .boxes_application .light1_block_part2 .lighter {
          opacity: 0;
          transition: opacity 0.05s ease 0.55s; }
    [data-elements="3"] .boxes_application .light2_block {
      position: absolute;
      opacity: 0;
      height: 21vh;
      width: 2px;
      background-color: transparent;
      margin-right: -1px;
      right: 50%;
      transition: all 0.2s;
      transform-origin: bottom center;
      top: 0;
      z-index: 2; }
      [data-current="6"] [data-elements="3"] .boxes_application .light2_block {
        opacity: 1;
        right: 35vh;
        transition: opacity 0.4s ease 1.1s, right 0.1s linear 1.2s; }
      [data-current="7"] [data-elements="3"] .boxes_application .light2_block {
        right: 35vh;
        opacity: 0;
        transition: opacity 0.05s ease 0.3s;
        animation: rightLightAnimation linear 0.3s forwards; }
      [data-elements="3"] .boxes_application .light2_block .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px -4px 0 0;
        z-index: 2; }
    [data-elements="3"] .boxes_application .light2_block_part2 {
      position: absolute;
      opacity: 0;
      height: 100px;
      width: 2px;
      background-color: transparent;
      margin-right: -1px;
      right: calc(50% - 100px);
      transition: all 0.1s;
      transform-origin: bottom center;
      top: 100%;
      z-index: 2; }
      [data-current="7"] [data-elements="3"] .boxes_application .light2_block_part2 {
        opacity: 1;
        margin-top: calc(60vh - 100px);
        transition: opacity 0.05s ease 0.3s, transform 0.1s linear 0.3s, margin 0.2s ease 0.4s;
        transform: rotate(-90deg); }
      [data-elements="3"] .boxes_application .light2_block_part2 .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px 0 0 -4px;
        z-index: 2; }
        [data-current="7"] [data-elements="3"] .boxes_application .light2_block_part2 .lighter {
          opacity: 0;
          transition: opacity 0.05s ease 0.55s; }
    [data-elements="3"] .boxes_application .light1,
    [data-elements="3"] .boxes_application .light2 {
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
      position: absolute;
      animation: small_light 2s infinite linear alternate;
      top: 0;
      margin: -5px 0 0;
      z-index: 2; }
      [data-current="6"] [data-elements="3"] .boxes_application .light1, [data-current="6"]
      [data-elements="3"] .boxes_application .light2 {
        opacity: 1; }
    [data-elements="3"] .boxes_application .light1 {
      left: 50%;
      margin-left: -5px; }
      [data-current="6"] [data-elements="3"] .boxes_application .light1 {
        left: 18vw;
        transition: opacity 0.4s ease 1.1s, left 0.1s linear 1.2s; }
    [data-elements="3"] .boxes_application .light2 {
      right: 50%;
      margin-right: -5px; }
      [data-current="6"] [data-elements="3"] .boxes_application .light2 {
        right: 18vw;
        transition: opacity 0.4s ease 1.1s, right 0.1s linear 1.2s; }
  [data-elements="3"] .gates_application {
    position: absolute;
    top: 24vh;
    left: 50px;
    right: 50px;
    bottom: 16vh;
    opacity: 0; }
    [data-current="6"] [data-elements="3"] .gates_application {
      opacity: 1;
      transition: opacity 0.01s ease 0.9s; }
    [data-current="7"] [data-elements="3"] .gates_application {
      opacity: 0; }
    [data-elements="3"] .gates_application .gate1 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      left: 32vh; }
      [data-current="6"] [data-elements="3"] .gates_application .gate1 {
        width: 0;
        transition: width 0.1s linear 1.2s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate1 {
        opacity: 0; }
    [data-elements="3"] .gates_application .gate2 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      right: 32vh; }
      [data-current="6"] [data-elements="3"] .gates_application .gate2 {
        width: 0;
        transition: width 0.1s linear 1.2s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate2 {
        opacity: 0; }
    [data-elements="3"] .gates_application .gate3 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right bottom; }
      [data-current="6"] [data-elements="3"] .gates_application .gate3 {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.3s, opacity 0.05s linear 1.3s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate3 {
        opacity: 0; }
    [data-elements="3"] .gates_application .gate4 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: left bottom; }
      [data-current="6"] [data-elements="3"] .gates_application .gate4 {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.3s, opacity 0.05s linear 1.3s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate4 {
        opacity: 0; }
    [data-elements="3"] .gates_application .gate5 {
      position: absolute;
      background-color: #17191f;
      top: 32vh;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right top; }
      [data-current="6"] [data-elements="3"] .gates_application .gate5 {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.35s, opacity 0.05s linear 1.35s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate5 {
        opacity: 0; }
    [data-elements="3"] .gates_application .gate6 {
      position: absolute;
      background-color: #17191f;
      top: 32vh;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: right top; }
      [data-current="6"] [data-elements="3"] .gates_application .gate6 {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.35s, opacity 0.05s linear 1.35s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate6 {
        opacity: 0; }
    [data-elements="3"] .gates_application .gate7 {
      position: absolute;
      background-color: #17191f;
      bottom: -17vh;
      margin-bottom: 2px;
      left: 32vh;
      right: 32vh;
      height: 17vh; }
      [data-current="6"] [data-elements="3"] .gates_application .gate7 {
        height: 0;
        opacity: 0;
        transition: height 0.1s linear 1.4s, opacity 0.05s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .gates_application .gate7 {
        opacity: 0; }
  [data-elements="3"] .boxes_offer {
    position: absolute;
    top: 24vh;
    left: 50px;
    right: 50px;
    bottom: 16vh;
    background-color: #17191f;
    opacity: 0; }
    [data-current="5"] [data-elements="3"] .boxes_offer {
      opacity: 1;
      transform: translate3d(0, 100vh, 0); }
    [data-current="6"] [data-elements="3"] .boxes_offer {
      transition: opacity 0.3s ease, transform 1.4s ease-in-out;
      opacity: 1;
      transform: translate3d(0, 100vh, 0); }
    [data-current="7"] [data-elements="3"] .boxes_offer {
      opacity: 1;
      transition: opacity 0.01s ease 0.9s, transform 1.4s ease-in-out; }
    [data-current="8"] [data-elements="3"] .boxes_offer {
      opacity: 1;
      transform: translate3d(0, -100vh, 0);
      transition: transform 1.4s ease-in-out, opacity 0.05s ease 1.3s; }
    [data-elements="3"] .boxes_offer .box1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
      background-color: #17191f;
      border-width: 1px;
      border-style: solid solid none solid;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_offer .box1 {
          border-radius: 21vh 21vh 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_offer .box1 {
          border-radius: 16vh 16vh 0 0; } }
      @media screen and (min-width: 1921px) {
        .portrait [data-elements="3"] .boxes_offer .box1 {
          border-radius: 10vh 10vh 0 0; } }
    [data-elements="3"] .boxes_offer .box2 {
      position: absolute;
      left: 0;
      top: 50%;
      right: 75%;
      bottom: 0;
      background-color: #17191f;
      border-width: 1px;
      border-style: none none solid solid;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_offer .box2 {
          border-radius: 0 0 0 21vh; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_offer .box2 {
          border-radius: 0 0 0 16vh; } }
      @media screen and (min-width: 1921px) {
        .portrait [data-elements="3"] .boxes_offer .box2 {
          border-radius: 0 0 0 10vh; } }
    [data-elements="3"] .boxes_offer .box3 {
      position: absolute;
      right: 0;
      top: 50%;
      left: 75%;
      bottom: 0;
      background-color: #17191f;
      border-width: 1px;
      border-style: none solid solid none;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_offer .box3 {
          border-radius: 0 0 21vh 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_offer .box3 {
          border-radius: 0 0 16vh 0; } }
      @media screen and (min-width: 1921px) {
        .portrait [data-elements="3"] .boxes_offer .box3 {
          border-radius: 0 0 10vh 0; } }
    [data-elements="3"] .boxes_offer .box4 {
      position: absolute;
      right: 50%;
      top: 100%;
      left: 25%;
      height: 100px;
      background-color: #17191f;
      border-width: 1px;
      border-style: solid solid none none;
      border-color: rgba(255, 255, 255, 0.2);
      margin-top: -1px;
      margin-right: -0.5px; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_offer .box4 {
          border-radius: 0 100px 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_offer .box4 {
          border-radius: 0 50px 0 0; } }
    [data-elements="3"] .boxes_offer .box5 {
      position: absolute;
      left: 50%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: #17191f;
      border-width: 1px;
      border-style: solid none none solid;
      border-color: rgba(255, 255, 255, 0.2);
      margin-top: -1px;
      margin-left: -0.5px; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_offer .box5 {
          border-radius: 100px 0 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_offer .box5 {
          border-radius: 50px 0 0 0; } }
    [data-elements="3"] .boxes_offer .box6 {
      position: absolute;
      left: 25%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: #17191f;
      margin-top: -1px; }
      [data-elements="3"] .boxes_offer .box6:before {
        content: "";
        top: 100px;
        left: 0;
        right: 0;
        margin: auto;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        height: 0;
        opacity: 0; }
        [data-current="6"] [data-elements="3"] .boxes_offer .box6:before {
          transition: height 0.1s ease 1s, opacity 0.3s ease; }
        [data-current="7"] [data-elements="3"] .boxes_offer .box6:before {
          opacity: 1;
          height: 20vh;
          transition: height 0.2s ease 1.4s; }
        [data-current="8"] [data-elements="3"] .boxes_offer .box6:before {
          opacity: 1;
          height: 19vh; }
    [data-elements="3"] .boxes_offer .gate1 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      left: 32vh; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate1 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate1 {
        width: 0;
        transition: width 0.15s linear 0.8s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate1 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .gate2 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      right: 32vh; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate2 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate2 {
        width: 0;
        transition: width 0.15s linear 0.8s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate2 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .gate3 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right bottom; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate3 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate3 {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.1s linear 0.95s, opacity 0.05s linear 1.05s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate3 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .gate4 {
      position: absolute;
      background-color: #17191f;
      top: 0;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: left bottom; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate4 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate4 {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.1s linear 0.95s, opacity 0.05s linear 1.05s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate4 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .gate5 {
      position: absolute;
      background-color: #17191f;
      top: 32vh;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right top; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate5 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate5 {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.1s linear 1.05s, opacity 0.05s linear 1.15s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate5 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .gate6 {
      position: absolute;
      background-color: #17191f;
      top: 32vh;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: right top; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate6 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate6 {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.1s linear 1.05s, opacity 0.05s linear 1.15s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate6 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .gate7 {
      position: absolute;
      background-color: #17191f;
      bottom: -17vh;
      margin-bottom: 2px;
      left: 32vh;
      right: 32vh;
      height: 17vh; }
      [data-current="6"] [data-elements="3"] .boxes_offer .gate7 {
        transition: all 0.01s linear 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .gate7 {
        height: 0;
        opacity: 0;
        transition: height 0.2s linear 1.15s, opacity 0.05s linear 1.35s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .gate7 {
        opacity: 0; }
    [data-elements="3"] .boxes_offer .light0,
    [data-elements="3"] .boxes_offer .light1,
    [data-elements="3"] .boxes_offer .light2 {
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
      position: absolute;
      animation: small_light 2s infinite linear alternate;
      top: 0;
      margin: -5px 0 0;
      z-index: 2; }
      [data-current="6"] [data-elements="3"] .boxes_offer .light0, [data-current="6"]
      [data-elements="3"] .boxes_offer .light1, [data-current="6"]
      [data-elements="3"] .boxes_offer .light2 {
        transition: all 0.1s ease 1.4s; }
      [data-current="7"] [data-elements="3"] .boxes_offer .light0,
      [data-current="8"] [data-elements="3"] .boxes_offer .light0, [data-current="7"]
      [data-elements="3"] .boxes_offer .light1,
      [data-current="8"]
      [data-elements="3"] .boxes_offer .light1, [data-current="7"]
      [data-elements="3"] .boxes_offer .light2,
      [data-current="8"]
      [data-elements="3"] .boxes_offer .light2 {
        opacity: 1; }
    [data-elements="3"] .boxes_offer .light0 {
      left: 50%;
      margin-left: -5px; }
      [data-current="6"] [data-elements="3"] .boxes_offer .light0 {
        opacity: 1; }
    [data-elements="3"] .boxes_offer .light1 {
      left: 50%;
      margin-left: -5px; }
      [data-current="7"] [data-elements="3"] .boxes_offer .light1 {
        left: 18vw;
        transition: opacity 0.4s ease 0.8s, left 0.1s linear 0.8s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .light1 {
        transition: opacity 0.1s ease 1.3s, left 0.1s linear 1.3s; }
    [data-elements="3"] .boxes_offer .light2 {
      right: 50%;
      margin-right: -5px; }
      [data-current="7"] [data-elements="3"] .boxes_offer .light2 {
        right: 18vw;
        transition: opacity 0.4s ease 0.8s, right 0.1s linear 0.8s; }
      [data-current="8"] [data-elements="3"] .boxes_offer .light2 {
        transition: opacity 0.1s ease 1.3s, right 0.1s linear 1.3s; }
  [data-elements="3"] .boxes_timeline {
    position: absolute;
    top: 24vh;
    left: 0;
    right: 0;
    bottom: 16vh;
    opacity: 0;
    z-index: 1; }
    [data-elements="3"] .boxes_timeline:before {
      content: "";
      left: 0;
      right: 0;
      bottom: 100%;
      height: 24vh;
      background-color: #17191f;
      margin: auto;
      position: absolute; }
    [data-current="7"] [data-elements="3"] .boxes_timeline {
      opacity: 1;
      transform: translate3d(0, 100vh, 0);
      transition: transform 1.2s ease-in-out, opacity 0.1s ease 1.3s; }
    [data-current="8"] [data-elements="3"] .boxes_timeline {
      opacity: 1;
      transition: transform 1.4s ease-in-out; }
    [data-current="9"] [data-elements="3"] .boxes_timeline {
      z-index: 1;
      opacity: 1;
      transform: translate3d(0, -100vh, 0);
      transition: transform 1.4s ease-in-out, opacity 0.1s ease-in-out 1.3s; }
    [data-current="10"] [data-elements="3"] .boxes_timeline {
      opacity: 0;
      transform: translate3d(0, -100vh, 0);
      transition: opacity 0.6s ease-in-out 0.4s; }
    [data-elements="3"] .boxes_timeline .box1 {
      position: absolute;
      left: -1px;
      margin-right: -0.5px;
      right: 50%;
      bottom: 100%;
      height: 24vh;
      background-color: #17191f;
      border-width: 1px;
      border-style: none solid solid none;
      border-color: rgba(255, 255, 255, 0.2); }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_timeline .box1 {
          border-radius: 0 0 100px 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_timeline .box1 {
          border-radius: 0 0 50px 0; } }
      [data-elements="3"] .boxes_timeline .box1 .gate1 {
        position: absolute;
        background-color: #17191f;
        top: 0;
        height: 21vh;
        width: 21vh;
        right: -1vh;
        transform-origin: left top; }
        [data-current="7"] [data-elements="3"] .boxes_timeline .box1 .gate1 {
          transition: all 0.01s linear 1.4s; }
        [data-current="8"] [data-elements="3"] .boxes_timeline .box1 .gate1 {
          transform: rotate(90deg);
          opacity: 0;
          transition: transform 0.5s linear 0.2s, opacity 0.01s ease 0.7s; }
        [data-current="9"] [data-elements="3"] .boxes_timeline .box1 .gate1 {
          transform: rotate(90deg);
          opacity: 0; }
      [data-elements="3"] .boxes_timeline .box1 .gate2 {
        position: absolute;
        background-color: #17191f;
        bottom: -1vh;
        height: 2vh;
        left: 0;
        width: calc(100% - 20vh); }
        [data-current="7"] [data-elements="3"] .boxes_timeline .box1 .gate2 {
          transition: all 0.01s linear 1.4s; }
        [data-current="8"] [data-elements="3"] .boxes_timeline .box1 .gate2 {
          width: 0;
          opacity: 0;
          transition: width 0.2s linear 0.7s, opacity 0.01s ease 1.3s; }
        [data-current="9"] [data-elements="3"] .boxes_timeline .box1 .gate2 {
          width: 0;
          opacity: 0; }
    [data-elements="3"] .boxes_timeline .box2 {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0; }
      [data-elements="3"] .boxes_timeline .box2:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.2); }
        [data-current="7"] [data-elements="3"] .boxes_timeline .box2:after {
          transition: all 0.01s linear 1.4s; }
        [data-current="8"] [data-elements="3"] .boxes_timeline .box2:after {
          width: 100%;
          transition: width 0.3s linear 0.9s; }
        [data-current="9"] [data-elements="3"] .boxes_timeline .box2:after {
          width: 100%; }
    [data-elements="3"] .boxes_timeline .box3 {
      position: absolute;
      left: 50%;
      right: -1px;
      top: 100%;
      height: 20vh;
      border-width: 1px;
      border-style: solid none none solid;
      border-color: rgba(255, 255, 255, 0.2);
      margin-left: -0.5px; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes_timeline .box3 {
          border-radius: 100px 0 0 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes_timeline .box3 {
          border-radius: 50px 0 0 0; } }
      [data-elements="3"] .boxes_timeline .box3 .gate3 {
        position: absolute;
        background-color: #17191f;
        top: -1vh;
        height: 2vh;
        left: 20vh;
        width: calc(100% - 20vh); }
        [data-current="7"] [data-elements="3"] .boxes_timeline .box3 .gate3 {
          transition: all 0.01s linear 1.4s; }
        [data-current="8"] [data-elements="3"] .boxes_timeline .box3 .gate3 {
          width: 0;
          opacity: 0;
          transition: width 0.2s linear 1.2s, opacity 0.01s ease 1.4s; }
        [data-current="9"] [data-elements="3"] .boxes_timeline .box3 .gate3 {
          width: 0;
          opacity: 0; }
      [data-elements="3"] .boxes_timeline .box3 .gate4 {
        position: absolute;
        background-color: #17191f;
        top: -1vh;
        height: 21vh;
        width: 21vh;
        left: -1vh;
        transform-origin: right bottom; }
        [data-current="7"] [data-elements="3"] .boxes_timeline .box3 .gate4 {
          transition: all 0.01s linear 1.4s; }
        [data-current="8"] [data-elements="3"] .boxes_timeline .box3 .gate4 {
          transform: rotate(-90deg);
          opacity: 0;
          transition: transform 0.1s linear 1.4s, opacity 0.01s ease 1.5s; }
        [data-current="9"] [data-elements="3"] .boxes_timeline .box3 .gate4 {
          transform: rotate(-90deg);
          opacity: 0; }
    [data-elements="3"] .boxes_timeline .light0 {
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
      position: absolute;
      animation: small_light 2s infinite linear alternate;
      top: -11vh;
      z-index: 2;
      left: 50%;
      margin-left: -5px; }
      [data-current="7"] [data-elements="3"] .boxes_timeline .light0,
      [data-current="8"] [data-elements="3"] .boxes_timeline .light0,
      [data-current="9"] [data-elements="3"] .boxes_timeline .light0 {
        opacity: 1; }
  [data-elements="3"] .boxes_before_footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 1.4s ease-in-out;
    z-index: 1; }
    [data-current="10"] [data-elements="3"] .boxes_before_footer {
      transform: translate3d(0, -100vh, 0); }
  [data-elements="3"] .boxes-rounds {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 175px;
    background-color: #fff;
    transform: translate3d(0, 100%, 0);
    z-index: 3; }
    [data-current="9"] [data-elements="3"] .boxes-rounds {
      transform: translate3d(0, 150px, 0);
      transition: 1s ease-in-out; }
    [data-current="10"] [data-elements="3"] .boxes-rounds {
      transform: translate3d(0, 25px, 0);
      transition: 1s ease-in-out; }
    [data-elements="3"] .boxes-rounds:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 75px;
      margin: auto;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 1; }
    [data-elements="3"] .boxes-rounds .left {
      position: absolute;
      left: 0;
      top: 0;
      right: 50%;
      height: 125px;
      background-color: #17191f;
      margin: 0 -0.5px 0 0; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes-rounds .left {
          border-radius: 0 0 75px 0; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes-rounds .left {
          border-radius: 0 0 50px 0; } }
    [data-elements="3"] .boxes-rounds .right {
      position: absolute;
      right: -1px;
      top: 0;
      left: 50%;
      height: 125px;
      background-color: #17191f;
      margin: 0 0 0 -0.5px; }
      @media screen and (min-width: 1201px) {
        [data-elements="3"] .boxes-rounds .right {
          border-radius: 0 0 0 75px; } }
      @media screen and (max-width: 1200px) {
        [data-elements="3"] .boxes-rounds .right {
          border-radius: 0 0 0 50px; } }

@keyframes small_light {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.2); } }

@keyframes leftLightAnimation {
  0% {
    left: 21vh;
    top: 0; }
  25% {
    top: 0;
    left: 21vh;
    transform: rotate(-90deg); }
  50% {
    top: 18vh;
    left: 21vh;
    transform: rotate(-90deg); }
  75% {
    top: 18vh;
    left: 21vh;
    transform: rotate(-180deg); }
  100% {
    top: 18vh;
    left: calc(50% - 100px);
    transform: rotate(-180deg); } }

@keyframes rightLightAnimation {
  0% {
    right: 21vh;
    top: 0; }
  25% {
    top: 0;
    right: 21vh;
    transform: rotate(90deg); }
  50% {
    top: 18vh;
    right: 21vh;
    transform: rotate(90deg); }
  75% {
    top: 18vh;
    right: 21vh;
    transform: rotate(180deg); }
  100% {
    top: 18vh;
    right: calc(50% - 100px);
    transform: rotate(180deg); } }

.desktop [data-slide='1'] .slide-right,
.tablet.landscape [data-slide='1'] .slide-right {
  display: flex;
  align-items: center; }

.desktop [data-slide='1'] .head,
.tablet.landscape [data-slide='1'] .head {
  width: 80%; }
  @media screen and (min-width: 1481px) {
    .desktop [data-slide='1'] .head,
    .tablet.landscape [data-slide='1'] .head {
      margin-bottom: 5vh; } }
  @media screen and (min-width: 1201px) and (max-width: 1480px) {
    .desktop [data-slide='1'] .head,
    .tablet.landscape [data-slide='1'] .head {
      margin-bottom: 3vh; } }

.desktop [data-slide='1'] .title,
.tablet.landscape [data-slide='1'] .title {
  color: #fff; }
  .desktop [data-slide='1'] .title span,
  .tablet.landscape [data-slide='1'] .title span {
    display: block; }
  .desktop [data-slide='1'] .title .part1,
  .tablet.landscape [data-slide='1'] .title .part1 {
    transition: transform 1.7s ease 1s, opacity 1.7s ease 1.1s;
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .desktop [data-slide='1'] .title .part2,
  .tablet.landscape [data-slide='1'] .title .part2 {
    transition: transform 2s ease 1.3s, opacity 1.7s ease 1.4s;
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .desktop [data-slide='1'] .title .part3,
  .tablet.landscape [data-slide='1'] .title .part3 {
    transition: transform 2.3s ease 1.6s, opacity 1.7s ease 1.7s;
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.desktop [data-slide='1'] .subtitle,
.tablet.landscape [data-slide='1'] .subtitle {
  color: #bebebf;
  transition: transform 2s ease 1.5s, opacity 1.5s ease-in 2s;
  opacity: 0.8;
  transform: translate3d(0, 0, 0); }

.desktop [data-slide='1'] .word,
.tablet.landscape [data-slide='1'] .word {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16%;
  transition: transform 3s, opacity 3.5s ease-in 0.1s;
  transform: translate3d(0, 0, 0); }

.desktop [data-slide='1'] .brands,
.tablet.landscape [data-slide='1'] .brands {
  position: absolute;
  top: 20vh;
  bottom: 26vh;
  right: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 1921px) {
    .desktop [data-slide='1'] .brands,
    .tablet.landscape [data-slide='1'] .brands {
      right: 1vw; } }
  .desktop [data-slide='1'] .brands .brand,
  .tablet.landscape [data-slide='1'] .brands .brand {
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
    opacity: 0.3;
    transform: translate3d(0, 0, 0); }
    .desktop [data-slide='1'] .brands .brand img,
    .tablet.landscape [data-slide='1'] .brands .brand img {
      max-width: 100%;
      width: 7vw;
      height: auto; }
      @media screen and (max-height: 46vw) {
        .desktop [data-slide='1'] .brands .brand img,
        .tablet.landscape [data-slide='1'] .brands .brand img {
          height: 9vh;
          width: auto; } }
    .desktop [data-slide='1'] .brands .brand:nth-child(1),
    .tablet.landscape [data-slide='1'] .brands .brand:nth-child(1) {
      transition: all 1.2s ease 1s; }
    .desktop [data-slide='1'] .brands .brand:nth-child(2),
    .tablet.landscape [data-slide='1'] .brands .brand:nth-child(2) {
      transition: all 1.2s ease 1.05s; }
    .desktop [data-slide='1'] .brands .brand:nth-child(3),
    .tablet.landscape [data-slide='1'] .brands .brand:nth-child(3) {
      transition: all 1.2s ease 1.1s; }
    .desktop [data-slide='1'] .brands .brand:nth-child(4),
    .tablet.landscape [data-slide='1'] .brands .brand:nth-child(4) {
      transition: all 1.2s ease 1.15s; }

.desktop [data-slide='1'] .slide-img {
  display: none; }

.tablet.landscape [data-slide='1'] {
  height: 100vh; }
  .tablet.landscape [data-slide='1'] .slide-img {
    position: absolute;
    left: 4.6%;
    top: 9%;
    width: 50%;
    bottom: 4%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .tablet.landscape [data-slide='1'] .switch-box {
    position: relative;
    display: flex;
    align-items: center; }
    .tablet.landscape [data-slide='1'] .switch-box .switcher {
      position: relative;
      transition: opacity 2s ease 0.4s, transform 2.5s ease 0.4s; }
      .tablet.landscape [data-slide='1'] .switch-box .switcher img {
        display: block;
        height: auto;
        width: 30vw;
        max-width: 45vh; }
    .tablet.landscape [data-slide='1'] .switch-box .light {
      left: 27%;
      top: 34%;
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #b3b4b6;
      transition: box-shadow 0.5s ease, opacity 2s ease 0.5s;
      will-change: transform;
      box-shadow: 0 0 30vh 20vh rgba(179, 180, 182, 0.6); }
    .tablet.landscape [data-slide='1'] .switch-box .key,
    .tablet.landscape [data-slide='1'] .switch-box .key-digits {
      position: absolute;
      left: -27%;
      top: -5%;
      width: 43%;
      padding-top: 103.2%; }
      .tablet.landscape [data-slide='1'] .switch-box .key img, .tablet.landscape [data-slide='1'] .switch-box .key svg,
      .tablet.landscape [data-slide='1'] .switch-box .key-digits img,
      .tablet.landscape [data-slide='1'] .switch-box .key-digits svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
    .tablet.landscape [data-slide='1'] .switch-box .key {
      transition: opacity 1.4s ease, transform 1.4s ease 0.5s; }
      .tablet.landscape [data-slide='1'] .switch-box .key .key-lines {
        transition: opacity 1s ease 1s; }
    .tablet.landscape [data-slide='1'] .switch-box .key-digits {
      transition: opacity 1.4s ease 0.5s; }
    .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line1 {
      stroke-dasharray: 102.4447;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line1 {
        stroke-dashoffset: 102.4447; }
    .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line2 {
      stroke-dasharray: 142.1125;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line2 {
        stroke-dashoffset: 142.1125; }
    .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line3 {
      stroke-dasharray: 199.2661;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line3 {
        stroke-dashoffset: 199.2661; }
    .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line4 {
      stroke-dasharray: 134.7162;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .tablet.landscape [data-slide='1'] .switch-box #key_mobile_line4 {
        stroke-dashoffset: 134.7162; }
    .tablet.landscape [data-slide='1'] .switch-box .finger {
      position: absolute;
      left: 44%;
      top: 11%;
      width: 76%;
      padding-top: 141.2%; }
      .tablet.landscape [data-slide='1'] .switch-box .finger svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
    .tablet.landscape [data-slide='1'] .switch-box #finger-mobile {
      stroke-dasharray: 516.1923;
      stroke-dashoffset: 0;
      transition: all 1.3s linear 0.5s; }

.tablet [data-slide='1'] .switch-box .light {
  display: none; }

.mobile [data-slide='1'],
.tablet.portrait [data-slide='1'] {
  padding: 20px; }
  .mobile [data-slide='1'] .brands,
  .tablet.portrait [data-slide='1'] .brands {
    display: none; }
  .mobile [data-slide='1'] .title,
  .tablet.portrait [data-slide='1'] .title {
    color: #fff; }
    .mobile [data-slide='1'] .title span,
    .tablet.portrait [data-slide='1'] .title span {
      display: block; }
  .mobile [data-slide='1'] .subtitle,
  .tablet.portrait [data-slide='1'] .subtitle {
    color: #bebebf;
    margin-bottom: 4vh; }
  .mobile [data-slide='1'] .slide-img,
  .tablet.portrait [data-slide='1'] .slide-img {
    padding: 15% 0 20% 0;
    display: flex;
    justify-content: center; }
  .mobile [data-slide='1'] .switch-box,
  .tablet.portrait [data-slide='1'] .switch-box {
    position: relative;
    display: flex;
    align-items: center; }
    .mobile [data-slide='1'] .switch-box .switcher,
    .tablet.portrait [data-slide='1'] .switch-box .switcher {
      position: relative;
      transition: opacity 2s ease 0.4s, transform 2.5s ease 0.4s; }
      .mobile [data-slide='1'] .switch-box .switcher img,
      .tablet.portrait [data-slide='1'] .switch-box .switcher img {
        display: block;
        width: auto;
        height: auto;
        max-width: 50vw; }
    .mobile [data-slide='1'] .switch-box .light,
    .tablet.portrait [data-slide='1'] .switch-box .light {
      left: 27%;
      top: 34%;
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #b3b4b6;
      transition: box-shadow 0.5s ease, opacity 2s ease 0.5s;
      will-change: transform;
      box-shadow: 0 0 30vh 20vh rgba(179, 180, 182, 0.6);
      animation: light 10s infinite 0.5s alternate forwards; }
    .mobile [data-slide='1'] .switch-box .key,
    .mobile [data-slide='1'] .switch-box .key-digits,
    .tablet.portrait [data-slide='1'] .switch-box .key,
    .tablet.portrait [data-slide='1'] .switch-box .key-digits {
      position: absolute;
      left: -27%;
      top: -5%;
      width: 43%;
      padding-top: 103.2%; }
      .mobile [data-slide='1'] .switch-box .key img, .mobile [data-slide='1'] .switch-box .key svg,
      .mobile [data-slide='1'] .switch-box .key-digits img,
      .mobile [data-slide='1'] .switch-box .key-digits svg,
      .tablet.portrait [data-slide='1'] .switch-box .key img,
      .tablet.portrait [data-slide='1'] .switch-box .key svg,
      .tablet.portrait [data-slide='1'] .switch-box .key-digits img,
      .tablet.portrait [data-slide='1'] .switch-box .key-digits svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
    .mobile [data-slide='1'] .switch-box .key,
    .tablet.portrait [data-slide='1'] .switch-box .key {
      transition: opacity 1.4s ease, transform 1.4s ease 0.5s; }
      .mobile [data-slide='1'] .switch-box .key .key-lines,
      .tablet.portrait [data-slide='1'] .switch-box .key .key-lines {
        transition: opacity 1s ease 1s; }
    .mobile [data-slide='1'] .switch-box .key-digits,
    .tablet.portrait [data-slide='1'] .switch-box .key-digits {
      transition: opacity 1.4s ease 0.5s; }
    .mobile [data-slide='1'] .switch-box #key_mobile_line1,
    .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line1 {
      stroke-dasharray: 102.4447;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .mobile [data-slide='1'] .switch-box #key_mobile_line1, .begin
      .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line1 {
        stroke-dashoffset: 102.4447; }
    .mobile [data-slide='1'] .switch-box #key_mobile_line2,
    .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line2 {
      stroke-dasharray: 142.1125;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .mobile [data-slide='1'] .switch-box #key_mobile_line2, .begin
      .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line2 {
        stroke-dashoffset: 142.1125; }
    .mobile [data-slide='1'] .switch-box #key_mobile_line3,
    .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line3 {
      stroke-dasharray: 199.2661;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .mobile [data-slide='1'] .switch-box #key_mobile_line3, .begin
      .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line3 {
        stroke-dashoffset: 199.2661; }
    .mobile [data-slide='1'] .switch-box #key_mobile_line4,
    .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line4 {
      stroke-dasharray: 134.7162;
      stroke-dashoffset: 0;
      transition: all 1s linear 0.5s; }
      .begin .mobile [data-slide='1'] .switch-box #key_mobile_line4, .begin
      .tablet.portrait [data-slide='1'] .switch-box #key_mobile_line4 {
        stroke-dashoffset: 134.7162; }
    .mobile [data-slide='1'] .switch-box .finger,
    .tablet.portrait [data-slide='1'] .switch-box .finger {
      position: absolute;
      left: 44%;
      top: 11%;
      width: 76%;
      padding-top: 141.2%; }
      .mobile [data-slide='1'] .switch-box .finger svg,
      .tablet.portrait [data-slide='1'] .switch-box .finger svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
    .mobile [data-slide='1'] .switch-box #finger-mobile,
    .tablet.portrait [data-slide='1'] .switch-box #finger-mobile {
      stroke-dasharray: 516.1923;
      stroke-dashoffset: 0;
      transition: all 1.3s linear 0.5s; }

.desktop [data-slide='2'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='2'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 50px; }
  .desktop [data-slide='2'] .list {
    color: #bebebf; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='2'] .list {
        margin-bottom: 7vh;
        max-width: 65%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='2'] .list {
        margin-bottom: 4vh;
        max-width: 75%; } }

.desktop [data-slide='2'] .tablet-light,
.desktop [data-slide='2'] .tab-lines,
.desktop [data-slide='2'] .slide-img {
  display: none; }

.tablet.landscape [data-slide='2'] {
  padding: 26vh 0; }
  .tablet.landscape [data-slide='2']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 10vh;
    width: 1px;
    background-color: #fff;
    opacity: 0.2;
    left: 50%; }
  .tablet.landscape [data-slide='2'] .tablet-light {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 1px;
    right: 0;
    top: 10vh;
    z-index: 1;
    margin: -4px auto 0;
    animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='2'] .slide-img {
    position: absolute;
    left: 2%;
    top: 9%;
    width: 50%;
    bottom: 4%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .tablet.landscape [data-slide='2'] .switch-box {
    position: relative;
    display: flex;
    align-items: center; }
    .tablet.landscape [data-slide='2'] .switch-box .switcher {
      position: relative;
      transition: opacity 2s ease 0.4s, transform 2.5s ease 0.4s; }
      .tablet.landscape [data-slide='2'] .switch-box .switcher img {
        display: block;
        height: auto;
        width: 30vw;
        max-width: 45vh; }
    .tablet.landscape [data-slide='2'] .switch-box .light {
      left: 27%;
      top: 34%;
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #b3b4b6;
      transition: box-shadow 0.5s ease, opacity 2s ease 0.5s;
      will-change: box-shadow;
      box-shadow: 0 0 30vh 20vh rgba(179, 180, 182, 0.6); }
  .tablet.landscape [data-slide='2'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 50px; }
  .tablet.landscape [data-slide='2'] .list {
    color: #bebebf; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape [data-slide='2'] .list {
        margin-bottom: 7vh;
        max-width: 65%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape [data-slide='2'] .list {
        margin-bottom: 4vh;
        max-width: 75%; } }

.tablet [data-slide='2'] .switch-box .light {
  display: none; }

.mobile [data-slide='2'],
.tablet.portrait [data-slide='2'] {
  padding: 0 20px 20px;
  margin-bottom: 90px; }
  .mobile [data-slide='2'] .list,
  .tablet.portrait [data-slide='2'] .list {
    color: #bebebf; }
  .mobile [data-slide='2'] .tablet-light,
  .mobile [data-slide='2'] .tab-lines,
  .mobile [data-slide='2'] .slide-img,
  .tablet.portrait [data-slide='2'] .tablet-light,
  .tablet.portrait [data-slide='2'] .tab-lines,
  .tablet.portrait [data-slide='2'] .slide-img {
    display: none; }
  .mobile [data-slide='2']:after,
  .tablet.portrait [data-slide='2']:after {
    content: ""; }

.desktop [data-slide='3'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='3'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 75px; }
  .desktop [data-slide='3'] .head {
    width: 80%; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='3'] .head {
        margin-bottom: 4vh; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='3'] .head {
        margin-bottom: 3vh; } }
  .desktop [data-slide='3'] .title {
    color: #fff; }
    .desktop [data-slide='3'] .title span {
      display: block; }
    .desktop [data-slide='3'] .title .part1 {
      color: #b3b3b3; }
    .desktop [data-slide='3'] .title .part2 {
      color: #b3b3b3; }
  .desktop [data-slide='3'] .subtitle {
    color: #bebebf;
    opacity: 0.8; }
  .desktop [data-slide='3'] .word {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12%;
    transform: translate3d(0, 0, 0); }
  .desktop [data-slide='3'] .tablet-light,
  .desktop [data-slide='3'] .slide-img {
    display: none; }

.tablet.landscape [data-slide='3'] {
  padding: 15vh 0 30vh; }
  .tablet.landscape [data-slide='3']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 1px;
    background-color: #fff;
    left: 50%;
    opacity: 0.2; }
  .tablet.landscape [data-slide='3'] .tablet-light {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 1px;
    right: 0;
    top: 20vh;
    z-index: 1;
    margin: -4px auto 0;
    animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='3'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 75px; }
  .tablet.landscape [data-slide='3'] .head {
    width: 80%; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape [data-slide='3'] .head {
        margin-bottom: 4vh; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape [data-slide='3'] .head {
        margin-bottom: 3vh; } }
  .tablet.landscape [data-slide='3'] .title {
    color: #fff; }
    .tablet.landscape [data-slide='3'] .title span {
      display: block; }
    .tablet.landscape [data-slide='3'] .title .part1 {
      color: #b3b3b3; }
    .tablet.landscape [data-slide='3'] .title .part2 {
      color: #b3b3b3; }
  .tablet.landscape [data-slide='3'] .subtitle {
    color: #bebebf;
    opacity: 0.8; }
  .tablet.landscape [data-slide='3'] .word {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12%;
    transform: translate3d(0, 0, 0); }
  .tablet.landscape [data-slide='3'] .slide-img {
    position: absolute;
    left: 2%;
    top: 4%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tablet.landscape [data-slide='3'] .slide-img .people-box {
      width: 90%;
      position: relative;
      max-width: 65vh; }
    .tablet.landscape [data-slide='3'] .slide-img .part {
      width: 100%;
      padding-top: 92.476%; }
      .tablet.landscape [data-slide='3'] .slide-img .part svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .tablet.landscape [data-slide='3'] .slide-img .part.absolute {
        position: absolute;
        top: 0;
        left: 0; }
    .tablet.landscape [data-slide='3'] .slide-img .icons .group {
      transform-origin: 50% 50%; }

.mobile [data-slide='3'],
.tablet.portrait [data-slide='3'] {
  padding: 20px;
  margin-bottom: 90px; }
  .mobile [data-slide='3'] .title,
  .tablet.portrait [data-slide='3'] .title {
    color: #fff; }
    .mobile [data-slide='3'] .title span,
    .tablet.portrait [data-slide='3'] .title span {
      display: block; }
    .mobile [data-slide='3'] .title .part1,
    .tablet.portrait [data-slide='3'] .title .part1 {
      color: #b3b3b3; }
    .mobile [data-slide='3'] .title .part2,
    .tablet.portrait [data-slide='3'] .title .part2 {
      color: #b3b3b3; }
  .mobile [data-slide='3'] .subtitle,
  .tablet.portrait [data-slide='3'] .subtitle {
    color: #bebebf; }
  .mobile [data-slide='3'] .tablet-light,
  .tablet.portrait [data-slide='3'] .tablet-light {
    display: none; }
  .mobile [data-slide='3'] .slide-img,
  .tablet.portrait [data-slide='3'] .slide-img {
    padding: 2% 0 10% 0;
    display: flex;
    justify-content: center; }
    .mobile [data-slide='3'] .slide-img .people-box,
    .tablet.portrait [data-slide='3'] .slide-img .people-box {
      width: 90%;
      position: relative; }
    .mobile [data-slide='3'] .slide-img .part,
    .tablet.portrait [data-slide='3'] .slide-img .part {
      width: 100%;
      padding-top: 92.476%; }
      .mobile [data-slide='3'] .slide-img .part svg,
      .tablet.portrait [data-slide='3'] .slide-img .part svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .mobile [data-slide='3'] .slide-img .part.absolute,
      .tablet.portrait [data-slide='3'] .slide-img .part.absolute {
        position: absolute;
        top: 0;
        left: 0; }
    .mobile [data-slide='3'] .slide-img .icons .group,
    .tablet.portrait [data-slide='3'] .slide-img .icons .group {
      transform-origin: 50% 50%; }
  .mobile [data-slide='3']:after,
  .tablet.portrait [data-slide='3']:after {
    content: ""; }

.tablet [data-slide='3'] .man_left_p1,
.tablet [data-slide='3'] .man_left_p2,
.tablet [data-slide='3'] .man_left_p3,
.tablet [data-slide='3'] .man_right_p1,
.tablet [data-slide='3'] .man_right_p2,
.tablet [data-slide='3'] .man_right_p3,
.tablet [data-slide='3'] .man_right_p4,
.tablet [data-slide='3'] .man_right_p5,
.tablet [data-slide='3'] .man_right_p6,
.mobile [data-slide='3'] .man_left_p1,
.mobile [data-slide='3'] .man_left_p2,
.mobile [data-slide='3'] .man_left_p3,
.mobile [data-slide='3'] .man_right_p1,
.mobile [data-slide='3'] .man_right_p2,
.mobile [data-slide='3'] .man_right_p3,
.mobile [data-slide='3'] .man_right_p4,
.mobile [data-slide='3'] .man_right_p5,
.mobile [data-slide='3'] .man_right_p6 {
  transition: stroke-dashoffset 0.5s linear; }

.tablet [data-slide='3'] .lines,
.tablet [data-slide='3'] .icons,
.tablet [data-slide='3'] .cassette,
.mobile [data-slide='3'] .lines,
.mobile [data-slide='3'] .icons,
.mobile [data-slide='3'] .cassette {
  opacity: 0;
  transition: opacity 0.5s linear; }

.tablet [data-slide='3'] .man_left_p1,
.mobile [data-slide='3'] .man_left_p1 {
  stroke-dasharray: 209.985;
  stroke-dashoffset: -209.985; }

.tablet [data-slide='3'] .man_left_p2,
.mobile [data-slide='3'] .man_left_p2 {
  stroke-dasharray: 41.6786;
  stroke-dashoffset: 41.6786; }

.tablet [data-slide='3'] .man_left_p3,
.mobile [data-slide='3'] .man_left_p3 {
  stroke-dasharray: 209.153;
  stroke-dashoffset: 209.153; }

.tablet [data-slide='3'] .man_right_p1,
.mobile [data-slide='3'] .man_right_p1 {
  stroke-dasharray: 92.503;
  stroke-dashoffset: 92.503; }

.tablet [data-slide='3'] .man_right_p2,
.mobile [data-slide='3'] .man_right_p2 {
  stroke-dasharray: 27.780;
  stroke-dashoffset: 27.780; }

.tablet [data-slide='3'] .man_right_p3,
.mobile [data-slide='3'] .man_right_p3 {
  stroke-dasharray: 96.592;
  stroke-dashoffset: 96.592; }

.tablet [data-slide='3'] .man_right_p4,
.mobile [data-slide='3'] .man_right_p4 {
  stroke-dasharray: 106.792;
  stroke-dashoffset: 106.792; }

.tablet [data-slide='3'] .man_right_p5,
.mobile [data-slide='3'] .man_right_p5 {
  stroke-dasharray: 166.147;
  stroke-dashoffset: 166.147; }

.tablet [data-slide='3'] .man_right_p6,
.mobile [data-slide='3'] .man_right_p6 {
  stroke-dasharray: 220.259;
  stroke-dashoffset: 220.259; }

.tablet [data-slide='3'] .slide-img.active .man_left_p2,
.tablet [data-slide='3'] .slide-img.active .man_left_p1,
.tablet [data-slide='3'] .slide-img.active .man_left_p3,
.tablet [data-slide='3'] .slide-img.active .man_right_p1,
.tablet [data-slide='3'] .slide-img.active .man_right_p2,
.tablet [data-slide='3'] .slide-img.active .man_right_p3,
.tablet [data-slide='3'] .slide-img.active .man_right_p4,
.tablet [data-slide='3'] .slide-img.active .man_right_p5,
.tablet [data-slide='3'] .slide-img.active .man_right_p6,
.mobile [data-slide='3'] .slide-img.active .man_left_p2,
.mobile [data-slide='3'] .slide-img.active .man_left_p1,
.mobile [data-slide='3'] .slide-img.active .man_left_p3,
.mobile [data-slide='3'] .slide-img.active .man_right_p1,
.mobile [data-slide='3'] .slide-img.active .man_right_p2,
.mobile [data-slide='3'] .slide-img.active .man_right_p3,
.mobile [data-slide='3'] .slide-img.active .man_right_p4,
.mobile [data-slide='3'] .slide-img.active .man_right_p5,
.mobile [data-slide='3'] .slide-img.active .man_right_p6 {
  stroke-dashoffset: 0; }

.tablet [data-slide='3'] .slide-img.active .lines,
.tablet [data-slide='3'] .slide-img.active .icons,
.tablet [data-slide='3'] .slide-img.active .cassette,
.mobile [data-slide='3'] .slide-img.active .lines,
.mobile [data-slide='3'] .slide-img.active .icons,
.mobile [data-slide='3'] .slide-img.active .cassette {
  opacity: 1; }

.desktop [data-slide='4'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='4'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 75px; }
  .desktop [data-slide='4'] .head {
    width: 80%; }
  .desktop [data-slide='4'] .title {
    color: #fff; }
    .desktop [data-slide='4'] .title span {
      display: block; }
    .desktop [data-slide='4'] .title .part1 {
      color: #b3b3b3; }
    .desktop [data-slide='4'] .title .part2 {
      color: #b3b3b3; }
  .desktop [data-slide='4'] .subtitle {
    color: #bebebf;
    opacity: 0.8; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='4'] .subtitle {
        max-width: 70%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='4'] .subtitle {
        max-width: 90%; } }
  .desktop [data-slide='4'] .word {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13%;
    transform: translate3d(0, 0, 0); }
  .desktop [data-slide='4'] .tablet-light,
  .desktop [data-slide='4'] .slide-img {
    display: none; }

.tablet.landscape [data-slide='4'] {
  padding: 15vh 0 30vh; }
  .tablet.landscape [data-slide='4']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 1px;
    background-color: #fff;
    left: 50%;
    opacity: 0.2; }
  .tablet.landscape [data-slide='4'] .tablet-light {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 1px;
    right: 0;
    top: 20vh;
    z-index: 1;
    margin: -4px auto 0;
    animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='4'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 75px; }
  .tablet.landscape [data-slide='4'] .head {
    width: 80%; }
  .tablet.landscape [data-slide='4'] .title {
    color: #fff; }
    .tablet.landscape [data-slide='4'] .title span {
      display: block; }
    .tablet.landscape [data-slide='4'] .title .part1 {
      color: #b3b3b3; }
    .tablet.landscape [data-slide='4'] .title .part2 {
      color: #b3b3b3; }
  .tablet.landscape [data-slide='4'] .subtitle {
    color: #bebebf;
    opacity: 0.8; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape [data-slide='4'] .subtitle {
        max-width: 70%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape [data-slide='4'] .subtitle {
        max-width: 90%; } }
  .tablet.landscape [data-slide='4'] .word {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13%;
    transform: translate3d(0, 0, 0); }
  .tablet.landscape [data-slide='4'] .slide-img {
    position: absolute;
    left: 2%;
    top: 4%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tablet.landscape [data-slide='4'] .slide-img .people-box {
      width: 90%;
      position: relative;
      max-width: 65vh; }
    .tablet.landscape [data-slide='4'] .slide-img .part {
      width: 100%;
      padding-top: 92.476%; }
      .tablet.landscape [data-slide='4'] .slide-img .part svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .tablet.landscape [data-slide='4'] .slide-img .part.absolute {
        position: absolute;
        top: 0;
        left: 0; }
    .tablet.landscape [data-slide='4'] .slide-img .cassette .group {
      transform: translate3d(-1%, -3%, 0); }
    .tablet.landscape [data-slide='4'] .slide-img .icons .group {
      transform-origin: 50% 50%;
      transform: rotate(10deg); }
    .tablet.landscape [data-slide='4'] .slide-img .lines .line01 {
      stroke-dasharray: 56.845;
      stroke-dashoffset: 16; }

.mobile [data-slide='4'],
.tablet.portrait [data-slide='4'] {
  padding: 20px;
  margin-bottom: 90px; }
  .mobile [data-slide='4'] .title,
  .tablet.portrait [data-slide='4'] .title {
    color: #fff; }
    .mobile [data-slide='4'] .title span,
    .tablet.portrait [data-slide='4'] .title span {
      display: block; }
    .mobile [data-slide='4'] .title .part1,
    .tablet.portrait [data-slide='4'] .title .part1 {
      color: #b3b3b3; }
    .mobile [data-slide='4'] .title .part2,
    .tablet.portrait [data-slide='4'] .title .part2 {
      color: #b3b3b3; }
  .mobile [data-slide='4'] .subtitle,
  .tablet.portrait [data-slide='4'] .subtitle {
    color: #bebebf; }
  .mobile [data-slide='4'] .slide-img,
  .tablet.portrait [data-slide='4'] .slide-img {
    padding: 2% 0 10% 0;
    display: flex;
    justify-content: center; }
    .mobile [data-slide='4'] .slide-img .people-box,
    .tablet.portrait [data-slide='4'] .slide-img .people-box {
      width: 90%;
      position: relative; }
    .mobile [data-slide='4'] .slide-img .part,
    .tablet.portrait [data-slide='4'] .slide-img .part {
      width: 100%;
      padding-top: 92.476%; }
      .mobile [data-slide='4'] .slide-img .part svg,
      .tablet.portrait [data-slide='4'] .slide-img .part svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .mobile [data-slide='4'] .slide-img .part.absolute,
      .tablet.portrait [data-slide='4'] .slide-img .part.absolute {
        position: absolute;
        top: 0;
        left: 0; }
    .mobile [data-slide='4'] .slide-img .cassette .group,
    .tablet.portrait [data-slide='4'] .slide-img .cassette .group {
      transform: translate3d(-1%, -3%, 0); }
    .mobile [data-slide='4'] .slide-img .icons .group,
    .tablet.portrait [data-slide='4'] .slide-img .icons .group {
      transform-origin: 50% 50%;
      transform: rotate(10deg); }
    .mobile [data-slide='4'] .slide-img .lines .line01,
    .tablet.portrait [data-slide='4'] .slide-img .lines .line01 {
      stroke-dasharray: 56.845;
      stroke-dashoffset: 16; }
  .mobile [data-slide='4'] .tablet-light,
  .tablet.portrait [data-slide='4'] .tablet-light {
    display: none; }
  .mobile [data-slide='4']:after,
  .tablet.portrait [data-slide='4']:after {
    content: ""; }

.tablet [data-slide='4'] .man_left_p1,
.tablet [data-slide='4'] .man_left_p2,
.tablet [data-slide='4'] .man_left_p3,
.tablet [data-slide='4'] .man_left_p4,
.tablet [data-slide='4'] .man_left_p5,
.mobile [data-slide='4'] .man_left_p1,
.mobile [data-slide='4'] .man_left_p2,
.mobile [data-slide='4'] .man_left_p3,
.mobile [data-slide='4'] .man_left_p4,
.mobile [data-slide='4'] .man_left_p5 {
  transition: stroke-dashoffset 0.5s linear; }

.tablet [data-slide='4'] .lines,
.tablet [data-slide='4'] .icons,
.tablet [data-slide='4'] .cassette,
.tablet [data-slide='4'] .sms,
.tablet [data-slide='4'] .calc,
.mobile [data-slide='4'] .lines,
.mobile [data-slide='4'] .icons,
.mobile [data-slide='4'] .cassette,
.mobile [data-slide='4'] .sms,
.mobile [data-slide='4'] .calc {
  opacity: 0;
  transition: opacity 0.5s linear; }

.tablet [data-slide='4'] .man_left_p1,
.mobile [data-slide='4'] .man_left_p1 {
  stroke-dasharray: 209.985;
  stroke-dashoffset: -209.985; }

.tablet [data-slide='4'] .man_left_p2,
.mobile [data-slide='4'] .man_left_p2 {
  stroke-dasharray: 41.6786;
  stroke-dashoffset: 41.6786; }

.tablet [data-slide='4'] .man_left_p3,
.mobile [data-slide='4'] .man_left_p3 {
  stroke-dasharray: 209.153;
  stroke-dashoffset: 209.153; }

.tablet [data-slide='4'] .man_left_p4,
.mobile [data-slide='4'] .man_left_p4 {
  stroke-dasharray: 206.088;
  stroke-dashoffset: 206.088; }

.tablet [data-slide='4'] .man_left_p5,
.mobile [data-slide='4'] .man_left_p5 {
  stroke-dasharray: 9.252;
  stroke-dashoffset: 9.252; }

.tablet [data-slide='4'] .slide-img.active .man_left_p2,
.tablet [data-slide='4'] .slide-img.active .man_left_p1,
.tablet [data-slide='4'] .slide-img.active .man_left_p3,
.tablet [data-slide='4'] .slide-img.active .man_left_p4,
.tablet [data-slide='4'] .slide-img.active .man_left_p5,
.mobile [data-slide='4'] .slide-img.active .man_left_p2,
.mobile [data-slide='4'] .slide-img.active .man_left_p1,
.mobile [data-slide='4'] .slide-img.active .man_left_p3,
.mobile [data-slide='4'] .slide-img.active .man_left_p4,
.mobile [data-slide='4'] .slide-img.active .man_left_p5 {
  stroke-dashoffset: 0; }

.tablet [data-slide='4'] .slide-img.active .lines,
.tablet [data-slide='4'] .slide-img.active .icons,
.tablet [data-slide='4'] .slide-img.active .cassette,
.tablet [data-slide='4'] .slide-img.active .sms,
.tablet [data-slide='4'] .slide-img.active .calc,
.mobile [data-slide='4'] .slide-img.active .lines,
.mobile [data-slide='4'] .slide-img.active .icons,
.mobile [data-slide='4'] .slide-img.active .cassette,
.mobile [data-slide='4'] .slide-img.active .sms,
.mobile [data-slide='4'] .slide-img.active .calc {
  opacity: 1; }

.desktop [data-slide='5'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='5'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 75px; }
  .desktop [data-slide='5'] .head {
    width: 80%; }
  .desktop [data-slide='5'] .title {
    color: #fff; }
    .desktop [data-slide='5'] .title span {
      display: block; }
    .desktop [data-slide='5'] .title .part2 {
      color: #b3b3b3; }
  .desktop [data-slide='5'] .subtitle {
    color: #bebebf;
    opacity: 0.8; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='5'] .subtitle {
        max-width: 70%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='5'] .subtitle {
        max-width: 80%; } }
  .desktop [data-slide='5'] .list {
    color: #fff;
    margin-top: 2vh; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='5'] .list {
        max-width: 70%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='5'] .list {
        max-width: 80%; } }
  .desktop [data-slide='5'] .tablet-light,
  .desktop [data-slide='5'] .slide-img {
    display: none; }

.tablet.landscape [data-slide='5'] {
  padding: 15vh 0 24vh; }
  .tablet.landscape [data-slide='5']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 1px;
    background-color: #fff;
    left: 50%;
    opacity: 0.2; }
  .tablet.landscape [data-slide='5'] .tablet-light {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 1px;
    right: 0;
    top: 20vh;
    z-index: 1;
    margin: -4px auto 0;
    animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='5'] .slide-right {
    display: flex;
    align-items: center;
    padding-left: 75px; }
  .tablet.landscape [data-slide='5'] .head {
    width: 80%; }
  .tablet.landscape [data-slide='5'] .title {
    color: #fff; }
    .tablet.landscape [data-slide='5'] .title span {
      display: block; }
    .tablet.landscape [data-slide='5'] .title .part2 {
      color: #b3b3b3; }
  .tablet.landscape [data-slide='5'] .subtitle {
    color: #bebebf;
    opacity: 0.8; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape [data-slide='5'] .subtitle {
        max-width: 70%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape [data-slide='5'] .subtitle {
        max-width: 80%; } }
  .tablet.landscape [data-slide='5'] .list {
    color: #fff;
    margin-top: 2vh; }
    @media screen and (min-width: 1481px) {
      .tablet.landscape [data-slide='5'] .list {
        max-width: 70%; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .tablet.landscape [data-slide='5'] .list {
        max-width: 80%; } }
  .tablet.landscape [data-slide='5'] .slide-img {
    position: absolute;
    left: 2%;
    top: 4%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tablet.landscape [data-slide='5'] .slide-img .people-box {
      width: 90%;
      position: relative;
      max-width: 65vh; }
    .tablet.landscape [data-slide='5'] .slide-img .part {
      width: 100%;
      padding-top: 92.476%; }
      .tablet.landscape [data-slide='5'] .slide-img .part svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .tablet.landscape [data-slide='5'] .slide-img .part.absolute {
        position: absolute;
        top: 0;
        left: 0; }
    .tablet.landscape [data-slide='5'] .slide-img .icons .group {
      transform-origin: 50% 50%;
      transform: rotate(20deg); }

.mobile [data-slide='5'],
.tablet.portrait [data-slide='5'] {
  padding: 20px;
  margin-bottom: 90px; }
  .mobile [data-slide='5']:after,
  .tablet.portrait [data-slide='5']:after {
    content: ""; }
  .mobile [data-slide='5'] .title,
  .tablet.portrait [data-slide='5'] .title {
    color: #fff; }
    .mobile [data-slide='5'] .title span,
    .tablet.portrait [data-slide='5'] .title span {
      display: block; }
    .mobile [data-slide='5'] .title .part2,
    .tablet.portrait [data-slide='5'] .title .part2 {
      color: #b3b3b3; }
  .mobile [data-slide='5'] .subtitle,
  .tablet.portrait [data-slide='5'] .subtitle {
    color: #bebebf;
    margin-bottom: 2vh; }
  .mobile [data-slide='5'] .list,
  .tablet.portrait [data-slide='5'] .list {
    color: #fff; }
  .mobile [data-slide='5'] .slide-img,
  .tablet.portrait [data-slide='5'] .slide-img {
    padding: 2% 0 10% 0;
    display: flex;
    justify-content: center; }
    .mobile [data-slide='5'] .slide-img .people-box,
    .tablet.portrait [data-slide='5'] .slide-img .people-box {
      width: 90%;
      position: relative; }
    .mobile [data-slide='5'] .slide-img .part,
    .tablet.portrait [data-slide='5'] .slide-img .part {
      width: 100%;
      padding-top: 92.476%; }
      .mobile [data-slide='5'] .slide-img .part svg,
      .tablet.portrait [data-slide='5'] .slide-img .part svg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .mobile [data-slide='5'] .slide-img .part.absolute,
      .tablet.portrait [data-slide='5'] .slide-img .part.absolute {
        position: absolute;
        top: 0;
        left: 0; }
    .mobile [data-slide='5'] .slide-img .icons .group,
    .tablet.portrait [data-slide='5'] .slide-img .icons .group {
      transform-origin: 50% 50%;
      transform: rotate(20deg); }
  .mobile [data-slide='5'] .tablet-light,
  .tablet.portrait [data-slide='5'] .tablet-light {
    display: none; }

.tablet [data-slide='5'] .man_left_p1,
.tablet [data-slide='5'] .man_left_p2,
.tablet [data-slide='5'] .man_left_p3,
.tablet [data-slide='5'] .man_left_p4,
.tablet [data-slide='5'] .man_left_p5,
.tablet [data-slide='5'] .key_p1,
.tablet [data-slide='5'] .key_p2,
.tablet [data-slide='5'] .key_p3,
.tablet [data-slide='5'] .key_p4,
.tablet [data-slide='5'] .key_p5,
.tablet [data-slide='5'] .key_p6,
.mobile [data-slide='5'] .man_left_p1,
.mobile [data-slide='5'] .man_left_p2,
.mobile [data-slide='5'] .man_left_p3,
.mobile [data-slide='5'] .man_left_p4,
.mobile [data-slide='5'] .man_left_p5,
.mobile [data-slide='5'] .key_p1,
.mobile [data-slide='5'] .key_p2,
.mobile [data-slide='5'] .key_p3,
.mobile [data-slide='5'] .key_p4,
.mobile [data-slide='5'] .key_p5,
.mobile [data-slide='5'] .key_p6 {
  transition: stroke-dashoffset 0.5s linear; }

.tablet [data-slide='5'] .lines,
.tablet [data-slide='5'] .icons,
.tablet [data-slide='5'] .gadget,
.mobile [data-slide='5'] .lines,
.mobile [data-slide='5'] .icons,
.mobile [data-slide='5'] .gadget {
  opacity: 0;
  transition: opacity 0.5s linear; }

.tablet [data-slide='5'] .man_left_p1,
.mobile [data-slide='5'] .man_left_p1 {
  stroke-dasharray: 209.985;
  stroke-dashoffset: -209.985; }

.tablet [data-slide='5'] .man_left_p2,
.mobile [data-slide='5'] .man_left_p2 {
  stroke-dasharray: 41.6786;
  stroke-dashoffset: 41.6786; }

.tablet [data-slide='5'] .man_left_p3,
.mobile [data-slide='5'] .man_left_p3 {
  stroke-dasharray: 196.337;
  stroke-dashoffset: 196.337; }

.tablet [data-slide='5'] .man_left_p4,
.mobile [data-slide='5'] .man_left_p4 {
  stroke-dasharray: 211.333;
  stroke-dashoffset: 211.333; }

.tablet [data-slide='5'] .man_left_p5,
.mobile [data-slide='5'] .man_left_p5 {
  stroke-dasharray: 68.885;
  stroke-dashoffset: 68.885; }

.tablet [data-slide='5'] .key_p1,
.mobile [data-slide='5'] .key_p1 {
  stroke-dasharray: 110.257;
  stroke-dashoffset: 110.257; }

.tablet [data-slide='5'] .key_p2,
.mobile [data-slide='5'] .key_p2 {
  stroke-dasharray: 66.565;
  stroke-dashoffset: 66.565; }

.tablet [data-slide='5'] .key_p3,
.mobile [data-slide='5'] .key_p3 {
  stroke-dasharray: 53.532;
  stroke-dashoffset: 53.532; }

.tablet [data-slide='5'] .key_p4,
.mobile [data-slide='5'] .key_p4 {
  stroke-dasharray: 21.219;
  stroke-dashoffset: 21.219; }

.tablet [data-slide='5'] .key_p5,
.mobile [data-slide='5'] .key_p5 {
  stroke-dasharray: 47.874;
  stroke-dashoffset: 47.874; }

.tablet [data-slide='5'] .key_p6,
.mobile [data-slide='5'] .key_p6 {
  stroke-dasharray: 28.155;
  stroke-dashoffset: 28.155; }

.tablet [data-slide='5'] .slide-img.active .man_left_p2,
.tablet [data-slide='5'] .slide-img.active .man_left_p1,
.tablet [data-slide='5'] .slide-img.active .man_left_p3,
.tablet [data-slide='5'] .slide-img.active .man_left_p4,
.tablet [data-slide='5'] .slide-img.active .man_left_p5,
.tablet [data-slide='5'] .slide-img.active .key_p1,
.tablet [data-slide='5'] .slide-img.active .key_p2,
.tablet [data-slide='5'] .slide-img.active .key_p3,
.tablet [data-slide='5'] .slide-img.active .key_p4,
.tablet [data-slide='5'] .slide-img.active .key_p5,
.tablet [data-slide='5'] .slide-img.active .key_p6,
.mobile [data-slide='5'] .slide-img.active .man_left_p2,
.mobile [data-slide='5'] .slide-img.active .man_left_p1,
.mobile [data-slide='5'] .slide-img.active .man_left_p3,
.mobile [data-slide='5'] .slide-img.active .man_left_p4,
.mobile [data-slide='5'] .slide-img.active .man_left_p5,
.mobile [data-slide='5'] .slide-img.active .key_p1,
.mobile [data-slide='5'] .slide-img.active .key_p2,
.mobile [data-slide='5'] .slide-img.active .key_p3,
.mobile [data-slide='5'] .slide-img.active .key_p4,
.mobile [data-slide='5'] .slide-img.active .key_p5,
.mobile [data-slide='5'] .slide-img.active .key_p6 {
  stroke-dashoffset: 0; }

.tablet [data-slide='5'] .slide-img.active .lines,
.tablet [data-slide='5'] .slide-img.active .icons,
.tablet [data-slide='5'] .slide-img.active .gadget,
.mobile [data-slide='5'] .slide-img.active .lines,
.mobile [data-slide='5'] .slide-img.active .icons,
.mobile [data-slide='5'] .slide-img.active .gadget {
  opacity: 1; }

.desktop [data-slide='6'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='6'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    margin-bottom: 3vh;
    position: absolute;
    bottom: 72vh; }
  .desktop [data-slide='6'] .title {
    color: #fff;
    position: relative;
    z-index: 2;
    transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='6'] .items-box {
    position: absolute;
    z-index: 1;
    bottom: 50px;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='6'] .items-box {
        left: 3vw;
        right: 3vw;
        top: 100px; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='6'] .items-box {
        left: 8vw;
        right: 8vw;
        top: 50px; } }
    @media screen and (max-width: 1200px) {
      .desktop [data-slide='6'] .items-box {
        top: 10vh;
        left: 10vw;
        right: 10vw; } }
  .desktop [data-slide='6'] .items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .desktop [data-slide='6'] .items .item {
      width: 100%;
      max-width: 33.3333%;
      padding: 25px 3vw;
      opacity: 0;
      text-align: center; }
      @media screen and (min-width: 1201px) {
        .desktop [data-slide='6'] .items .item {
          transform: translate3d(0, 20%, 0); } }
      @media screen and (max-width: 1200px) {
        .desktop [data-slide='6'] .items .item {
          transform: translate3d(0, 20%, 0); } }
      .desktop [data-slide='6'] .items .item-subtitle {
        opacity: 0.8; }
    .desktop [data-slide='6'] .items img {
      height: 20vh;
      width: auto;
      display: block;
      margin: 0 auto 3vh;
      max-width: 80%; }
  .desktop [data-slide='6'] .tablet-light {
    display: none; }

.tablet.landscape [data-slide='6'] {
  padding: 5vh 0 15vh;
  flex-direction: column; }
  .tablet.landscape [data-slide='6']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 1px;
    background-color: #fff;
    left: 50%;
    opacity: 0.2; }
  .tablet.landscape [data-slide='6'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    position: relative; }
  .tablet.landscape [data-slide='6'] .title {
    color: #fff;
    position: relative;
    z-index: 2; }
  .tablet.landscape [data-slide='6'] .items-box {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5vw;
    background-color: #17191f; }
    .tablet.landscape [data-slide='6'] .items-box .tablet-light {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
      position: absolute;
      left: 1px;
      right: 0;
      top: 0;
      z-index: 1;
      margin: -4px auto 0;
      animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='6'] .items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 80px; }
    .tablet.landscape [data-slide='6'] .items .item {
      width: 100%;
      max-width: 33.3333%;
      padding: 25px 3vw 40px;
      text-align: center; }
      .tablet.landscape [data-slide='6'] .items .item-subtitle {
        opacity: 0.8; }
    .tablet.landscape [data-slide='6'] .items img {
      height: 20vh;
      width: auto;
      display: block;
      margin: 0 auto 3vh;
      max-width: 80%; }

.mobile [data-slide='6'],
.tablet.portrait [data-slide='6'] {
  padding: 20px;
  margin-bottom: 90px; }
  .mobile [data-slide='6']:after,
  .tablet.portrait [data-slide='6']:after {
    content: ""; }
  .mobile [data-slide='6'] .title,
  .tablet.portrait [data-slide='6'] .title {
    color: #fff; }
  .mobile [data-slide='6'] .item,
  .tablet.portrait [data-slide='6'] .item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5% 0;
    text-align: center; }
    .mobile [data-slide='6'] .item:not(:last-child),
    .tablet.portrait [data-slide='6'] .item:not(:last-child) {
      margin-bottom: 50px; }
    .mobile [data-slide='6'] .item img,
    .tablet.portrait [data-slide='6'] .item img {
      width: 70vw;
      height: auto;
      margin: 0 auto 6vw; }
  .mobile [data-slide='6'] .tablet-light,
  .tablet.portrait [data-slide='6'] .tablet-light {
    display: none; }

.desktop [data-slide='7'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='7'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    margin-bottom: 3vh;
    position: absolute;
    bottom: 72vh; }
  .desktop [data-slide='7'] .title {
    color: #fff;
    position: relative;
    z-index: 2;
    transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='7'] .items-box {
    position: absolute;
    z-index: 1;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 1481px) {
      .desktop [data-slide='7'] .items-box {
        left: 3vw;
        right: 3vw;
        top: 100px; } }
    @media screen and (min-width: 1201px) and (max-width: 1480px) {
      .desktop [data-slide='7'] .items-box {
        left: 3vw;
        right: 3vw;
        top: 100px; } }
    @media screen and (max-width: 1200px) {
      .desktop [data-slide='7'] .items-box {
        left: 4vw;
        right: 4vw;
        top: 10vh; } }
  .desktop [data-slide='7'] .items {
    width: 94%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media screen and (min-width: 1921px) {
      .desktop [data-slide='7'] .items {
        width: 98%; } }
    .desktop [data-slide='7'] .items .item {
      width: 100%;
      max-width: 33.3333%;
      padding: 25px 4vw;
      opacity: 0;
      text-align: center; }
      @media screen and (min-width: 1201px) {
        .desktop [data-slide='7'] .items .item {
          transform: translate3d(0, 20%, 0); } }
      @media screen and (max-width: 1200px) {
        .desktop [data-slide='7'] .items .item {
          transform: translate3d(0, 20%, 0); } }
    .desktop [data-slide='7'] .items img {
      height: auto;
      width: 100%;
      display: block;
      margin: 0 auto 3vh;
      max-width: 70%; }
  .desktop [data-slide='7'] .tablet-light {
    display: none; }

.tablet.landscape [data-slide='7'] {
  padding: 5vh 0 15vh;
  flex-direction: column; }
  .tablet.landscape [data-slide='7']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    width: 1px;
    background-color: #fff;
    left: 50%;
    opacity: 0.2; }
  .tablet.landscape [data-slide='7'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    position: relative; }
  .tablet.landscape [data-slide='7'] .title {
    color: #fff;
    position: relative;
    z-index: 2; }
  .tablet.landscape [data-slide='7'] .items-box {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0 5vw;
    justify-content: center;
    background-color: #17191f; }
    .tablet.landscape [data-slide='7'] .items-box .tablet-light {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
      position: absolute;
      left: 1px;
      right: 0;
      top: 0;
      z-index: 1;
      margin: -4px auto 0;
      animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='7'] .items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 0;
    border-radius: 80px; }
    @media screen and (min-width: 1921px) {
      .tablet.landscape [data-slide='7'] .items {
        width: 98%; } }
    .tablet.landscape [data-slide='7'] .items .item {
      width: 100%;
      max-width: 33.3333%;
      padding: 25px 4vw;
      text-align: center; }
    .tablet.landscape [data-slide='7'] .items img {
      height: auto;
      width: 100%;
      display: block;
      margin: 0 auto 3vh;
      max-width: 70%; }

.mobile [data-slide='7'],
.tablet.portrait [data-slide='7'] {
  padding: 20px;
  margin-bottom: 90px; }
  .mobile [data-slide='7']:after,
  .tablet.portrait [data-slide='7']:after {
    content: ""; }
  .mobile [data-slide='7'] .title,
  .tablet.portrait [data-slide='7'] .title {
    color: #fff; }
  .mobile [data-slide='7'] .item,
  .tablet.portrait [data-slide='7'] .item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5% 0;
    text-align: center; }
    .mobile [data-slide='7'] .item:not(:last-child),
    .tablet.portrait [data-slide='7'] .item:not(:last-child) {
      margin-bottom: 50px; }
    .mobile [data-slide='7'] .item img,
    .tablet.portrait [data-slide='7'] .item img {
      width: 70vw;
      height: auto;
      margin: 0 auto 6vw; }
  .mobile [data-slide='7'] .tablet-light,
  .tablet.portrait [data-slide='7'] .tablet-light {
    display: none; }

.desktop [data-slide='8'] {
  transform: translate3d(0, 100%, 0);
  z-index: 2; }
  .desktop [data-slide='8'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    margin-bottom: 3vh;
    position: absolute;
    bottom: 72vh; }
  .desktop [data-slide='8'] .title {
    color: #fff;
    position: relative;
    z-index: 2;
    transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='8'] .carousel {
    position: absolute;
    top: 25vh;
    bottom: 17vh;
    left: 0;
    right: 0;
    margin: auto; }
    .desktop [data-slide='8'] .carousel .owl-carousel {
      padding: 0 10px; }
    .desktop [data-slide='8'] .carousel .owl-carousel,
    .desktop [data-slide='8'] .carousel .owl-stage-outer,
    .desktop [data-slide='8'] .carousel .owl-stage,
    .desktop [data-slide='8'] .carousel .owl-item {
      height: 100%; }
    .desktop [data-slide='8'] .carousel .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: center;
      padding: 0 2.2vw; }
      .desktop [data-slide='8'] .carousel .item .date {
        font-size: 1.4vw;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.65);
        padding: 15px 0; }
      .desktop [data-slide='8'] .carousel .item .text {
        font-size: 1.1vw;
        font-weight: 500;
        color: #fff;
        padding: 20px 0; }
      .desktop [data-slide='8'] .carousel .item-img {
        margin-bottom: 20px; }
        .desktop [data-slide='8'] .carousel .item-img img {
          display: block;
          max-height: 25vh;
          width: auto;
          margin: 0 auto; }
      .desktop [data-slide='8'] .carousel .item:not(.hard) .date {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end; }
      .desktop [data-slide='8'] .carousel .item:not(.hard) .text {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center; }
      .desktop [data-slide='8'] .carousel .item:not(.hard) .item-dot {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 40px 7px rgba(255, 255, 255, 0.2); }
      .desktop [data-slide='8'] .carousel .item.hard {
        justify-content: center; }
    .desktop [data-slide='8'] .carousel .mouse-scroll {
      position: absolute;
      top: 100%;
      left: 25%;
      width: 75px;
      max-width: 8vw; }
      .desktop [data-slide='8'] .carousel .mouse-scroll svg {
        width: 100%;
        fill: #fff; }
    .desktop [data-slide='8'] .carousel .mouse-group {
      animation: mouse_move 0.75s linear infinite alternate forwards; }
    .desktop [data-slide='8'] .carousel .finger-group {
      display: none; }
    .desktop [data-slide='8'] .carousel .mouse-click {
      opacity: 0.4; }
  .desktop [data-slide='8'] .tablet-light {
    display: none; }

.tablet.landscape [data-slide='8'] {
  z-index: 2;
  padding: 5vh 0 15vh;
  flex-direction: column; }
  .tablet.landscape [data-slide='8']:before {
    content: "";
    position: absolute;
    height: 8vh;
    top: 0;
    width: 1px;
    background-color: #fff;
    opacity: 0.2;
    left: 50%; }
  .tablet.landscape [data-slide='8'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    margin-bottom: 3vh;
    position: relative; }
  .tablet.landscape [data-slide='8'] .title {
    color: #fff;
    position: relative;
    z-index: 2; }
  .tablet.landscape [data-slide='8'] .tablet-light {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 1px;
    right: 0;
    top: 8vh;
    z-index: 1;
    margin: -4px auto 0;
    animation: small_light 2s infinite linear alternate; }
  .tablet.landscape [data-slide='8'] .carousel {
    position: relative;
    width: 100%;
    height: 45vh;
    margin: auto; }
    .tablet.landscape [data-slide='8'] .carousel:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #fff;
      top: 50%;
      opacity: 0.2; }
    .tablet.landscape [data-slide='8'] .carousel .owl-carousel {
      padding: 0 10px; }
    .tablet.landscape [data-slide='8'] .carousel .owl-carousel,
    .tablet.landscape [data-slide='8'] .carousel .owl-stage-outer,
    .tablet.landscape [data-slide='8'] .carousel .owl-stage,
    .tablet.landscape [data-slide='8'] .carousel .owl-item {
      height: 100%; }
    .tablet.landscape [data-slide='8'] .carousel .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: center;
      padding: 0 2.2vw; }
      .tablet.landscape [data-slide='8'] .carousel .item .date {
        font-size: 1.4vw;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.65);
        padding: 15px 0; }
      .tablet.landscape [data-slide='8'] .carousel .item .text {
        font-size: 1.1vw;
        font-weight: 500;
        color: #fff;
        padding: 20px 0; }
      .tablet.landscape [data-slide='8'] .carousel .item-img {
        margin-bottom: 20px; }
        .tablet.landscape [data-slide='8'] .carousel .item-img img {
          display: block;
          max-height: 25vh;
          width: auto;
          margin: 0 auto; }
      .tablet.landscape [data-slide='8'] .carousel .item:not(.hard) .date {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end; }
      .tablet.landscape [data-slide='8'] .carousel .item:not(.hard) .text {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center; }
      .tablet.landscape [data-slide='8'] .carousel .item:not(.hard) .item-dot {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 40px 7px rgba(255, 255, 255, 0.2); }
      .tablet.landscape [data-slide='8'] .carousel .item.hard {
        justify-content: center; }
    .tablet.landscape [data-slide='8'] .carousel .mouse-scroll {
      width: 60px;
      height: 30px;
      margin: 40px auto 0; }
      .tablet.landscape [data-slide='8'] .carousel .mouse-scroll svg {
        width: 100%;
        fill: #fff; }
    .tablet.landscape [data-slide='8'] .carousel .mouse-group {
      display: none; }
    .tablet.landscape [data-slide='8'] .carousel .finger-group {
      animation: mouse_move 0.75s linear infinite alternate forwards; }
    .tablet.landscape [data-slide='8'] .carousel .mouse-click {
      opacity: 0.4; }

.mobile [data-slide='8'],
.tablet.portrait [data-slide='8'] {
  padding: 20px 0;
  margin-bottom: 90px; }
  .mobile [data-slide='8']:after,
  .tablet.portrait [data-slide='8']:after {
    content: ""; }
  .mobile [data-slide='8'] .head,
  .tablet.portrait [data-slide='8'] .head {
    padding: 0 20px; }
  .mobile [data-slide='8'] .title,
  .tablet.portrait [data-slide='8'] .title {
    color: #fff; }
  .mobile [data-slide='8'] .carousel,
  .tablet.portrait [data-slide='8'] .carousel {
    max-width: 100vw; }
    .mobile [data-slide='8'] .carousel .owl-stage,
    .tablet.portrait [data-slide='8'] .carousel .owl-stage {
      display: flex;
      align-items: stretch; }
    .mobile [data-slide='8'] .carousel .item,
    .tablet.portrait [data-slide='8'] .carousel .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: center;
      padding: 0 4vw; }
      .mobile [data-slide='8'] .carousel .item:before,
      .tablet.portrait [data-slide='8'] .carousel .item:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        margin: auto;
        background-color: #45474c; }
      .mobile [data-slide='8'] .carousel .item .date,
      .tablet.portrait [data-slide='8'] .carousel .item .date {
        font-size: 1.5rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.65); }
      .mobile [data-slide='8'] .carousel .item .text,
      .tablet.portrait [data-slide='8'] .carousel .item .text {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff; }
      .mobile [data-slide='8'] .carousel .item-img,
      .tablet.portrait [data-slide='8'] .carousel .item-img {
        padding: 1rem 0;
        position: relative;
        z-index: 1; }
        .mobile [data-slide='8'] .carousel .item-img img,
        .tablet.portrait [data-slide='8'] .carousel .item-img img {
          display: block;
          height: auto;
          width: auto;
          max-width: 45%;
          margin: 0 auto; }
      .mobile [data-slide='8'] .carousel .item:not(.hard) .date,
      .tablet.portrait [data-slide='8'] .carousel .item:not(.hard) .date {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 20px 0; }
      .mobile [data-slide='8'] .carousel .item:not(.hard) .text,
      .tablet.portrait [data-slide='8'] .carousel .item:not(.hard) .text {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0; }
      .mobile [data-slide='8'] .carousel .item:not(.hard) .item-dot,
      .tablet.portrait [data-slide='8'] .carousel .item:not(.hard) .item-dot {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 40px 7px rgba(255, 255, 255, 0.2); }
      .mobile [data-slide='8'] .carousel .item.hard,
      .tablet.portrait [data-slide='8'] .carousel .item.hard {
        justify-content: center; }
        .mobile [data-slide='8'] .carousel .item.hard .date,
        .tablet.portrait [data-slide='8'] .carousel .item.hard .date {
          padding: 10px 0; }
        .mobile [data-slide='8'] .carousel .item.hard .text,
        .tablet.portrait [data-slide='8'] .carousel .item.hard .text {
          padding: 10px 0; }
    .mobile [data-slide='8'] .carousel .mouse-scroll,
    .tablet.portrait [data-slide='8'] .carousel .mouse-scroll {
      width: 60px;
      height: 30px;
      margin: 40px auto 0; }
      .mobile [data-slide='8'] .carousel .mouse-scroll svg,
      .tablet.portrait [data-slide='8'] .carousel .mouse-scroll svg {
        width: 100%;
        fill: #fff; }
    .mobile [data-slide='8'] .carousel .mouse-group,
    .tablet.portrait [data-slide='8'] .carousel .mouse-group {
      display: none; }
    .mobile [data-slide='8'] .carousel .finger-group,
    .tablet.portrait [data-slide='8'] .carousel .finger-group {
      animation: mouse_move 0.75s linear infinite alternate forwards; }
    .mobile [data-slide='8'] .carousel .mouse-click,
    .tablet.portrait [data-slide='8'] .carousel .mouse-click {
      opacity: 0.4; }
  .mobile [data-slide='8'] .tablet-light,
  .tablet.portrait [data-slide='8'] .tablet-light {
    display: none; }

@keyframes mouse_move {
  0% {
    transform: translate3d(-3%, 0, 0); }
  100% {
    transform: translate3d(3%, 0, 0); } }

.desktop [data-slide='9'] {
  transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='9'].has-footer {
    z-index: 1; }
  .desktop [data-slide='9'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    position: absolute;
    bottom: 72vh; }
  .desktop [data-slide='9'] .title {
    color: #fff;
    position: relative;
    z-index: 2;
    transform: translate3d(0, 100%, 0); }
  .desktop [data-slide='9'] .brands {
    position: absolute;
    top: 20vh;
    left: 50px;
    right: 50px;
    bottom: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 5vw; }
    .desktop [data-slide='9'] .brands .brand {
      width: 25%;
      max-width: 25%;
      padding: 0 25px;
      display: flex;
      justify-content: center;
      transform: translate3d(0, 50%, 0);
      opacity: 0; }
      .desktop [data-slide='9'] .brands .brand img {
        max-width: 100%;
        height: 22vh;
        width: auto; }
  .desktop [data-slide='9'] .tablet-light {
    display: none; }

.tablet.landscape [data-slide='9'] {
  padding: 15vh 0;
  flex-direction: column; }
  .tablet.landscape [data-slide='9']:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 10vh;
    width: 1px;
    background-color: #fff;
    opacity: 0.2;
    left: 50%; }
  .tablet.landscape [data-slide='9'].has-footer {
    z-index: 1;
    padding-bottom: 0;
    flex-direction: column; }
    .tablet.landscape [data-slide='9'].has-footer .brands {
      margin-bottom: 5vh; }
  .tablet.landscape [data-slide='9'] .head {
    width: 40%;
    left: 50%;
    padding-left: 75px;
    padding-right: 25px;
    position: relative; }
  .tablet.landscape [data-slide='9'] .title {
    color: #fff;
    position: relative;
    z-index: 2; }
  .tablet.landscape [data-slide='9'] .brands {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 7vw; }
    .tablet.landscape [data-slide='9'] .brands .brand {
      width: 25%;
      max-width: 25%;
      padding: 0 25px;
      display: flex;
      justify-content: center; }
      .tablet.landscape [data-slide='9'] .brands .brand img {
        max-width: 100%;
        height: 22vh;
        width: auto; }
  .tablet.landscape [data-slide='9'] .tablet-light {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 80px 7px rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 1px;
    right: 0;
    top: 10vh;
    z-index: 1;
    margin: -4px auto 0;
    animation: small_light 2s infinite linear alternate; }

.mobile [data-slide='9'],
.tablet.portrait [data-slide='9'] {
  padding: 20px;
  margin-bottom: 45px; }
  .mobile [data-slide='9'].has-footer,
  .tablet.portrait [data-slide='9'].has-footer {
    padding: 0;
    margin: 0; }
    .mobile [data-slide='9'].has-footer .title,
    .tablet.portrait [data-slide='9'].has-footer .title {
      padding: 20px 20px 0; }
    .mobile [data-slide='9'].has-footer .brands,
    .tablet.portrait [data-slide='9'].has-footer .brands {
      padding: 0 20px 40px; }
  .mobile [data-slide='9'] .title,
  .tablet.portrait [data-slide='9'] .title {
    color: #fff; }
  .mobile [data-slide='9'] .brands,
  .tablet.portrait [data-slide='9'] .brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .mobile [data-slide='9'] .brands .brand,
    .tablet.portrait [data-slide='9'] .brands .brand {
      width: 50%;
      padding: 0 10px;
      text-align: center; }
      .mobile [data-slide='9'] .brands .brand img,
      .tablet.portrait [data-slide='9'] .brands .brand img {
        width: 100%;
        max-width: 200px;
        height: auto; }
  .mobile [data-slide='9'] .tablet-light,
  .tablet.portrait [data-slide='9'] .tablet-light {
    display: none; }

.desktop [data-slide='10'] {
  transform: translate3d(0, 100%, 0);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2; }
  @media screen and (min-width: 1921px) {
    .desktop [data-slide='10'] .content--md {
      max-width: 90%; } }
  .desktop [data-slide='10'] .tech {
    position: absolute;
    left: 0;
    right: 0;
    top: 10vh;
    bottom: 20vh;
    display: flex;
    align-items: center; }
  .desktop [data-slide='10'] .section {
    margin: 0 auto; }
    @media screen and (max-width: 1200px) {
      .desktop [data-slide='10'] .section {
        padding-top: 50px; } }
  .desktop [data-slide='10'] .page-title {
    font-size: 3vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='10'] .page-title {
        font-size: 6.5vh; } }
  .desktop [data-slide='10'] .article--data {
    padding-left: 1.75vw; }
  .desktop [data-slide='10'] .article--date {
    font-size: 0.7vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='10'] .article--date {
        font-size: 1.4vh; } }
  .desktop [data-slide='10'] .article--tag {
    font-size: 0.8vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='10'] .article--tag {
        font-size: 1.5vh; } }
  .desktop [data-slide='10'] .article--title {
    font-size: 1.65vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='10'] .article--title {
        font-size: 3.5vh; } }
  .desktop [data-slide='10'] .article--descr {
    font-size: 0.85vw; }
    @media screen and (max-height: 46vw) {
      .desktop [data-slide='10'] .article--descr {
        font-size: 1.6vh; } }
  @media screen and (max-width: 1200px) {
    .desktop [data-slide='10'] .footer--main {
      min-height: 80px; } }

.tablet.landscape [data-slide='10'] {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2; }
  @media screen and (min-width: 1921px) {
    .tablet.landscape [data-slide='10'] .content--md {
      max-width: 90%; } }

.mobile [data-slide='10'],
.tablet.portrait [data-slide='10'] {
  background-color: #fff; }

.desktop .begin [data-slide='1'] .title .part1,
.tablet.landscape .begin [data-slide='1'] .title .part1 {
  transform: translate3d(4vw, 0, 0);
  opacity: 0; }

.desktop .begin [data-slide='1'] .title .part2,
.tablet.landscape .begin [data-slide='1'] .title .part2 {
  transform: translate3d(4.2vw, 0, 0);
  opacity: 0; }

.desktop .begin [data-slide='1'] .title .part3,
.tablet.landscape .begin [data-slide='1'] .title .part3 {
  transform: translate3d(4.4vw, 0, 0);
  opacity: 0; }

.desktop .begin [data-slide='1'] .subtitle,
.tablet.landscape .begin [data-slide='1'] .subtitle {
  opacity: 0;
  transform: translate3d(0, 90%, 0); }

.desktop .begin [data-slide='1'] .word,
.tablet.landscape .begin [data-slide='1'] .word {
  opacity: 0;
  transform: translate3d(0, 80%, 0); }

.desktop .begin [data-slide='1'] .brands .brand,
.tablet.landscape .begin [data-slide='1'] .brands .brand {
  transform: translate3d(0, 50%, 0);
  opacity: 0; }

.desktop .begin [data-slide='2'],
.desktop .begin [data-slide='3'],
.desktop .begin [data-slide='4'],
.desktop .begin [data-slide='5'],
.desktop .begin [data-slide='6'],
.desktop .begin [data-slide='7'],
.desktop .begin [data-slide='8'],
.desktop .begin [data-slide='9'],
.desktop .begin [data-slide='10'],
.tablet.landscape .begin [data-slide='2'],
.tablet.landscape .begin [data-slide='3'],
.tablet.landscape .begin [data-slide='4'],
.tablet.landscape .begin [data-slide='5'],
.tablet.landscape .begin [data-slide='6'],
.tablet.landscape .begin [data-slide='7'],
.tablet.landscape .begin [data-slide='8'],
.tablet.landscape .begin [data-slide='9'],
.tablet.landscape .begin [data-slide='10'] {
  display: none; }

.mobile .begin [data-slide='1'] #finger-mobile,
.tablet.portrait .begin [data-slide='1'] #finger-mobile {
  stroke-dashoffset: 516.1923; }

.mobile .begin [data-slide='1'] .switcher,
.tablet.portrait .begin [data-slide='1'] .switcher {
  transform: translate3d(-10%, -7%, 0);
  opacity: 0; }

.mobile .begin [data-slide='1'] .light,
.tablet.portrait .begin [data-slide='1'] .light {
  opacity: 0;
  box-shadow: 0 0 20vh 10vh rgba(179, 180, 182, 0.6);
  animation: none; }

.mobile .begin [data-slide='1'] .key,
.tablet.portrait .begin [data-slide='1'] .key {
  transform: translate3d(-19%, -9%, 0);
  opacity: 0; }
  .mobile .begin [data-slide='1'] .key .key-lines,
  .tablet.portrait .begin [data-slide='1'] .key .key-lines {
    opacity: 0; }

.mobile .begin [data-slide='1'] .key-digits,
.tablet.portrait .begin [data-slide='1'] .key-digits {
  opacity: 0; }

.mobile .begin [data-slide='1'] #key_mobile_line1,
.tablet.portrait .begin [data-slide='1'] #key_mobile_line1 {
  stroke-dashoffset: 102.4447; }

.mobile .begin [data-slide='1'] #key_mobile_line2,
.tablet.portrait .begin [data-slide='1'] #key_mobile_line2 {
  stroke-dashoffset: 142.1125; }

.mobile .begin [data-slide='1'] #key_mobile_line3,
.tablet.portrait .begin [data-slide='1'] #key_mobile_line3 {
  stroke-dashoffset: 199.2661; }

.mobile .begin [data-slide='1'] #key_mobile_line4,
.tablet.portrait .begin [data-slide='1'] #key_mobile_line4 {
  stroke-dashoffset: 134.7162; }

.desktop [data-current="2"] [data-slide='1'],
.desktop [data-current="3"] [data-slide='1'],
.desktop [data-current="4"] [data-slide='1'],
.desktop [data-current="5"] [data-slide='1'],
.desktop [data-current="6"] [data-slide='1'],
.desktop [data-current="7"] [data-slide='1'],
.desktop [data-current="8"] [data-slide='1'],
.desktop [data-current="9"] [data-slide='1'],
.desktop [data-current="10"] [data-slide='1'],
.tablet.landscape [data-current="2"] [data-slide='1'],
.tablet.landscape [data-current="3"] [data-slide='1'],
.tablet.landscape [data-current="4"] [data-slide='1'],
.tablet.landscape [data-current="5"] [data-slide='1'],
.tablet.landscape [data-current="6"] [data-slide='1'],
.tablet.landscape [data-current="7"] [data-slide='1'],
.tablet.landscape [data-current="8"] [data-slide='1'],
.tablet.landscape [data-current="9"] [data-slide='1'],
.tablet.landscape [data-current="10"] [data-slide='1'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="1"] [data-slide='1'] .title,
.tablet.landscape [data-current="1"] [data-slide='1'] .title {
  transition: transform 1.2s ease; }

.desktop [data-current="1"] [data-slide='1'] .subtitle,
.tablet.landscape [data-current="1"] [data-slide='1'] .subtitle {
  transition: transform 1.2s ease; }

.desktop [data-current="3"] [data-slide='2'],
.desktop [data-current="4"] [data-slide='2'],
.desktop [data-current="5"] [data-slide='2'],
.desktop [data-current="6"] [data-slide='2'],
.desktop [data-current="7"] [data-slide='2'],
.desktop [data-current="8"] [data-slide='2'],
.desktop [data-current="9"] [data-slide='2'],
.desktop [data-current="10"] [data-slide='2'],
.tablet.landscape [data-current="3"] [data-slide='2'],
.tablet.landscape [data-current="4"] [data-slide='2'],
.tablet.landscape [data-current="5"] [data-slide='2'],
.tablet.landscape [data-current="6"] [data-slide='2'],
.tablet.landscape [data-current="7"] [data-slide='2'],
.tablet.landscape [data-current="8"] [data-slide='2'],
.tablet.landscape [data-current="9"] [data-slide='2'],
.tablet.landscape [data-current="10"] [data-slide='2'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="2"] [data-slide='1'] .title,
.tablet.landscape [data-current="2"] [data-slide='1'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="2"] [data-slide='1'] .subtitle,
.tablet.landscape [data-current="2"] [data-slide='1'] .subtitle {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="2"] [data-slide='2'],
.tablet.landscape [data-current="2"] [data-slide='2'] {
  transform: translate3d(0, 0, 0); }

.desktop [data-current="2"] [data-slide='3'] .title,
.tablet.landscape [data-current="2"] [data-slide='3'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, -8vh, 0); }
  .desktop [data-current="2"] [data-slide='3'] .title .part1,
  .tablet.landscape [data-current="2"] [data-slide='3'] .title .part1 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }
  .desktop [data-current="2"] [data-slide='3'] .title .part2,
  .tablet.landscape [data-current="2"] [data-slide='3'] .title .part2 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }
  .desktop [data-current="2"] [data-slide='3'] .title .part3,
  .tablet.landscape [data-current="2"] [data-slide='3'] .title .part3 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }

.desktop [data-current="2"] [data-slide='3'] .subtitle,
.tablet.landscape [data-current="2"] [data-slide='3'] .subtitle {
  transition: all 0.2s ease 1.2s;
  transform: translate3d(0, 8vh, 0);
  opacity: 0; }

.desktop [data-current="2"] [data-slide='3'] .word,
.tablet.landscape [data-current="2"] [data-slide='3'] .word {
  transition: transform 0.2s ease 1.1s;
  transform: translate3d(0, 20vh, 0); }

.desktop [data-current="4"] [data-slide='3'],
.desktop [data-current="5"] [data-slide='3'],
.desktop [data-current="6"] [data-slide='3'],
.desktop [data-current="7"] [data-slide='3'],
.desktop [data-current="8"] [data-slide='3'],
.desktop [data-current="9"] [data-slide='3'],
.desktop [data-current="10"] [data-slide='3'],
.tablet.landscape [data-current="4"] [data-slide='3'],
.tablet.landscape [data-current="5"] [data-slide='3'],
.tablet.landscape [data-current="6"] [data-slide='3'],
.tablet.landscape [data-current="7"] [data-slide='3'],
.tablet.landscape [data-current="8"] [data-slide='3'],
.tablet.landscape [data-current="9"] [data-slide='3'],
.tablet.landscape [data-current="10"] [data-slide='3'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="3"] [data-slide='3'],
.tablet.landscape [data-current="3"] [data-slide='3'] {
  transform: translate3d(0, 0, 0); }
  .desktop [data-current="3"] [data-slide='3'] .title,
  .tablet.landscape [data-current="3"] [data-slide='3'] .title {
    transition: transform 1.2s ease; }
    .desktop [data-current="3"] [data-slide='3'] .title .part1,
    .tablet.landscape [data-current="3"] [data-slide='3'] .title .part1 {
      transition: all 0.8s ease 0.4s; }
    .desktop [data-current="3"] [data-slide='3'] .title .part2,
    .tablet.landscape [data-current="3"] [data-slide='3'] .title .part2 {
      transition: all 0.8s ease 0.5s; }
    .desktop [data-current="3"] [data-slide='3'] .title .part3,
    .tablet.landscape [data-current="3"] [data-slide='3'] .title .part3 {
      transition: all 0.8s ease 0.6s; }
  .desktop [data-current="3"] [data-slide='3'] .subtitle,
  .tablet.landscape [data-current="3"] [data-slide='3'] .subtitle {
    transition: all 0.8s ease 0.6s; }
  .desktop [data-current="3"] [data-slide='3'] .word,
  .tablet.landscape [data-current="3"] [data-slide='3'] .word {
    transition: transform 0.7s ease 0.7s; }

.desktop [data-current="3"] [data-slide='4'] .title,
.tablet.landscape [data-current="3"] [data-slide='4'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, -8vh, 0); }
  .desktop [data-current="3"] [data-slide='4'] .title .part1,
  .tablet.landscape [data-current="3"] [data-slide='4'] .title .part1 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }
  .desktop [data-current="3"] [data-slide='4'] .title .part2,
  .tablet.landscape [data-current="3"] [data-slide='4'] .title .part2 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }
  .desktop [data-current="3"] [data-slide='4'] .title .part3,
  .tablet.landscape [data-current="3"] [data-slide='4'] .title .part3 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }

.desktop [data-current="3"] [data-slide='4'] .subtitle,
.tablet.landscape [data-current="3"] [data-slide='4'] .subtitle {
  transition: all 0.2s ease 1.2s;
  transform: translate3d(0, 8vh, 0);
  opacity: 0; }

.desktop [data-current="3"] [data-slide='4'] .word,
.tablet.landscape [data-current="3"] [data-slide='4'] .word {
  transition: transform 0.2s ease 1.1s;
  transform: translate3d(0, 20vh, 0); }

.desktop [data-current="5"] [data-slide='4'],
.desktop [data-current="6"] [data-slide='4'],
.desktop [data-current="7"] [data-slide='4'],
.desktop [data-current="8"] [data-slide='4'],
.desktop [data-current="9"] [data-slide='4'],
.desktop [data-current="10"] [data-slide='4'],
.tablet.landscape [data-current="5"] [data-slide='4'],
.tablet.landscape [data-current="6"] [data-slide='4'],
.tablet.landscape [data-current="7"] [data-slide='4'],
.tablet.landscape [data-current="8"] [data-slide='4'],
.tablet.landscape [data-current="9"] [data-slide='4'],
.tablet.landscape [data-current="10"] [data-slide='4'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="4"] [data-slide='3'] .title,
.tablet.landscape [data-current="4"] [data-slide='3'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="4"] [data-slide='3'] .subtitle,
.tablet.landscape [data-current="4"] [data-slide='3'] .subtitle {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="4"] [data-slide='4'],
.tablet.landscape [data-current="4"] [data-slide='4'] {
  transform: translate3d(0, 0, 0); }
  .desktop [data-current="4"] [data-slide='4'] .title,
  .tablet.landscape [data-current="4"] [data-slide='4'] .title {
    transition: transform 1.2s ease; }
    .desktop [data-current="4"] [data-slide='4'] .title .part1,
    .tablet.landscape [data-current="4"] [data-slide='4'] .title .part1 {
      transition: all 0.8s ease 0.4s; }
    .desktop [data-current="4"] [data-slide='4'] .title .part2,
    .tablet.landscape [data-current="4"] [data-slide='4'] .title .part2 {
      transition: all 0.8s ease 0.5s; }
    .desktop [data-current="4"] [data-slide='4'] .title .part3,
    .tablet.landscape [data-current="4"] [data-slide='4'] .title .part3 {
      transition: all 0.8s ease 0.6s; }
  .desktop [data-current="4"] [data-slide='4'] .subtitle,
  .tablet.landscape [data-current="4"] [data-slide='4'] .subtitle {
    transition: all 0.8s ease 0.6s; }
  .desktop [data-current="4"] [data-slide='4'] .word,
  .tablet.landscape [data-current="4"] [data-slide='4'] .word {
    transition: transform 0.7s ease 0.7s; }

.desktop [data-current="4"] [data-slide='5'] .title,
.tablet.landscape [data-current="4"] [data-slide='5'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, -8vh, 0); }
  .desktop [data-current="4"] [data-slide='5'] .title .part1,
  .tablet.landscape [data-current="4"] [data-slide='5'] .title .part1 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }
  .desktop [data-current="4"] [data-slide='5'] .title .part2,
  .tablet.landscape [data-current="4"] [data-slide='5'] .title .part2 {
    transition: all 0.2s ease 1.2s;
    transform: translate3d(0, 8vh, 0);
    opacity: 0; }

.desktop [data-current="4"] [data-slide='5'] .subtitle,
.tablet.landscape [data-current="4"] [data-slide='5'] .subtitle {
  transition: all 0.2s ease 1.2s;
  transform: translate3d(0, 8vh, 0);
  opacity: 0; }

.desktop [data-current="4"] [data-slide='5'] .list,
.tablet.landscape [data-current="4"] [data-slide='5'] .list {
  transition: all 0.2s ease 1.2s;
  transform: translate3d(0, 8vh, 0);
  opacity: 0; }

.desktop [data-current="6"] [data-slide='5'],
.desktop [data-current="7"] [data-slide='5'],
.desktop [data-current="8"] [data-slide='5'],
.desktop [data-current="9"] [data-slide='5'],
.desktop [data-current="10"] [data-slide='5'],
.tablet.landscape [data-current="6"] [data-slide='5'],
.tablet.landscape [data-current="7"] [data-slide='5'],
.tablet.landscape [data-current="8"] [data-slide='5'],
.tablet.landscape [data-current="9"] [data-slide='5'],
.tablet.landscape [data-current="10"] [data-slide='5'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="5"] [data-slide='4'] .title,
.tablet.landscape [data-current="5"] [data-slide='4'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="5"] [data-slide='4'] .subtitle,
.tablet.landscape [data-current="5"] [data-slide='4'] .subtitle {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="5"] [data-slide='5'],
.tablet.landscape [data-current="5"] [data-slide='5'] {
  transform: translate3d(0, 0, 0); }
  .desktop [data-current="5"] [data-slide='5'] .title,
  .tablet.landscape [data-current="5"] [data-slide='5'] .title {
    transition: transform 1.2s ease; }
    .desktop [data-current="5"] [data-slide='5'] .title .part1,
    .tablet.landscape [data-current="5"] [data-slide='5'] .title .part1 {
      transition: all 0.8s ease 0.4s; }
    .desktop [data-current="5"] [data-slide='5'] .title .part2,
    .tablet.landscape [data-current="5"] [data-slide='5'] .title .part2 {
      transition: all 0.8s ease 0.5s; }
  .desktop [data-current="5"] [data-slide='5'] .subtitle,
  .tablet.landscape [data-current="5"] [data-slide='5'] .subtitle {
    transition: all 0.8s ease 0.6s; }
  .desktop [data-current="5"] [data-slide='5'] .list,
  .tablet.landscape [data-current="5"] [data-slide='5'] .list {
    transition: all 0.8s ease 0.6s; }

.desktop [data-current="5"] [data-slide='6'] .title,
.tablet.landscape [data-current="5"] [data-slide='6'] .title {
  transition: transform 0.1s ease 1.3s; }

.desktop [data-current="5"] [data-slide='6'] .items .item:nth-child(1),
.tablet.landscape [data-current="5"] [data-slide='6'] .items .item:nth-child(1) {
  transition: transform 0.8s ease, opacity 0.1s ease 1.3s; }

.desktop [data-current="5"] [data-slide='6'] .items .item:nth-child(2),
.tablet.landscape [data-current="5"] [data-slide='6'] .items .item:nth-child(2) {
  transition: transform 0.8s ease 0.1s, opacity 0.1s ease 1.3s; }

.desktop [data-current="5"] [data-slide='6'] .items .item:nth-child(3),
.tablet.landscape [data-current="5"] [data-slide='6'] .items .item:nth-child(3) {
  transition: transform 0.8s ease 0.2s, opacity 0.1s ease 1.3s; }

.desktop [data-current="8"] [data-slide='6'],
.desktop [data-current="9"] [data-slide='6'],
.desktop [data-current="10"] [data-slide='6'],
.tablet.landscape [data-current="8"] [data-slide='6'],
.tablet.landscape [data-current="9"] [data-slide='6'],
.tablet.landscape [data-current="10"] [data-slide='6'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="6"] [data-slide='5'] .title,
.tablet.landscape [data-current="6"] [data-slide='5'] .title {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="6"] [data-slide='5'] .subtitle,
.tablet.landscape [data-current="6"] [data-slide='5'] .subtitle {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="6"] [data-slide='5'] .list,
.tablet.landscape [data-current="6"] [data-slide='5'] .list {
  transition: transform 1.2s ease;
  transform: translate3d(0, 8vh, 0); }

.desktop [data-current="6"] [data-slide='6'],
.tablet.landscape [data-current="6"] [data-slide='6'] {
  transform: translate3d(0, 0, 0);
  transition: transform 1.2s ease-in-out; }
  .desktop [data-current="6"] [data-slide='6'] .title,
  .tablet.landscape [data-current="6"] [data-slide='6'] .title {
    transform: translate3d(0, 0, 0);
    transition: transform 0.8s ease 0.4s; }
  .desktop [data-current="6"] [data-slide='6'] .items .item,
  .tablet.landscape [data-current="6"] [data-slide='6'] .items .item {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
    .desktop [data-current="6"] [data-slide='6'] .items .item:nth-child(1),
    .tablet.landscape [data-current="6"] [data-slide='6'] .items .item:nth-child(1) {
      transition: transform 0.7s ease 0.8s, opacity 0.4s ease 0.7s; }
    .desktop [data-current="6"] [data-slide='6'] .items .item:nth-child(2),
    .tablet.landscape [data-current="6"] [data-slide='6'] .items .item:nth-child(2) {
      transition: transform 0.7s ease 0.9s, opacity 0.4s ease 0.8s; }
    .desktop [data-current="6"] [data-slide='6'] .items .item:nth-child(3),
    .tablet.landscape [data-current="6"] [data-slide='6'] .items .item:nth-child(3) {
      transition: transform 0.7s ease 1s, opacity 0.4s ease 0.9s; }

.desktop [data-current="6"] [data-slide='7'] .title,
.tablet.landscape [data-current="6"] [data-slide='7'] .title {
  transition: transform 0.1s ease 1.3s; }

.desktop [data-current="6"] [data-slide='7'] .items .item:nth-child(1),
.tablet.landscape [data-current="6"] [data-slide='7'] .items .item:nth-child(1) {
  transition: transform 0.8s ease, opacity 0.1s ease 1.3s; }

.desktop [data-current="6"] [data-slide='7'] .items .item:nth-child(2),
.tablet.landscape [data-current="6"] [data-slide='7'] .items .item:nth-child(2) {
  transition: transform 0.8s ease 0.1s, opacity 0.1s ease 1.3s; }

.desktop [data-current="6"] [data-slide='7'] .items .item:nth-child(3),
.tablet.landscape [data-current="6"] [data-slide='7'] .items .item:nth-child(3) {
  transition: transform 0.8s ease 0.2s, opacity 0.1s ease 1.3s; }

.desktop [data-current="8"] [data-slide='7'],
.desktop [data-current="9"] [data-slide='7'],
.desktop [data-current="10"] [data-slide='7'],
.tablet.landscape [data-current="8"] [data-slide='7'],
.tablet.landscape [data-current="9"] [data-slide='7'],
.tablet.landscape [data-current="10"] [data-slide='7'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="7"] [data-slide='6'],
.tablet.landscape [data-current="7"] [data-slide='6'] {
  transform: translate3d(0, -100%, 0);
  transition: transform 1.2s ease-in-out; }
  .desktop [data-current="7"] [data-slide='6'] .title,
  .tablet.landscape [data-current="7"] [data-slide='6'] .title {
    transition: transform 0.4s ease 0.4s; }
  .desktop [data-current="7"] [data-slide='6'] .items .item,
  .tablet.landscape [data-current="7"] [data-slide='6'] .items .item {
    opacity: 1;
    transition: transform 0.6s ease-in; }
    @media screen and (min-width: 1201px) {
      .desktop [data-current="7"] [data-slide='6'] .items .item,
      .tablet.landscape [data-current="7"] [data-slide='6'] .items .item {
        transform: translate3d(0, 20%, 0); } }
    @media screen and (max-width: 1200px) {
      .desktop [data-current="7"] [data-slide='6'] .items .item,
      .tablet.landscape [data-current="7"] [data-slide='6'] .items .item {
        transform: translate3d(0, 10%, 0); } }

.desktop [data-current="7"] [data-slide='7'],
.tablet.landscape [data-current="7"] [data-slide='7'] {
  transform: translate3d(0, 0, 0);
  transition: transform 1.4s ease-in-out; }
  .desktop [data-current="7"] [data-slide='7'] .title,
  .tablet.landscape [data-current="7"] [data-slide='7'] .title {
    transform: translate3d(0, 0, 0);
    transition: transform 0.8s ease 0.4s; }
  .desktop [data-current="7"] [data-slide='7'] .items .item,
  .tablet.landscape [data-current="7"] [data-slide='7'] .items .item {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
    .desktop [data-current="7"] [data-slide='7'] .items .item:nth-child(1),
    .tablet.landscape [data-current="7"] [data-slide='7'] .items .item:nth-child(1) {
      transition: transform 0.7s ease 0.8s, opacity 0.4s ease 0.7s; }
    .desktop [data-current="7"] [data-slide='7'] .items .item:nth-child(2),
    .tablet.landscape [data-current="7"] [data-slide='7'] .items .item:nth-child(2) {
      transition: transform 0.7s ease 0.9s, opacity 0.4s ease 0.8s; }
    .desktop [data-current="7"] [data-slide='7'] .items .item:nth-child(3),
    .tablet.landscape [data-current="7"] [data-slide='7'] .items .item:nth-child(3) {
      transition: transform 0.7s ease 1s, opacity 0.4s ease 0.9s; }

.desktop [data-current="7"] [data-slide='8'] .title,
.tablet.landscape [data-current="7"] [data-slide='8'] .title {
  transition: transform 0.1s ease 1.3s; }

.desktop [data-current="7"] [data-slide='8'] .carousel .owl-item,
.tablet.landscape [data-current="7"] [data-slide='8'] .carousel .owl-item {
  opacity: 0;
  transition: opacity 0.4s ease 0.1s; }

.desktop [data-current="7"] [data-slide='8'] .carousel .item:not(.hard),
.tablet.landscape [data-current="7"] [data-slide='8'] .carousel .item:not(.hard) {
  opacity: 0;
  transition: opacity 0.2s ease; }

.desktop [data-current="9"] [data-slide='8'],
.desktop [data-current="10"] [data-slide='8'],
.tablet.landscape [data-current="9"] [data-slide='8'],
.tablet.landscape [data-current="10"] [data-slide='8'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="8"] [data-slide='7'] .title,
.tablet.landscape [data-current="8"] [data-slide='7'] .title {
  transition: transform 0.4s ease 0.4s; }

.desktop [data-current="8"] [data-slide='7'] .items .item,
.tablet.landscape [data-current="8"] [data-slide='7'] .items .item {
  opacity: 1;
  transition: transform 0.6s ease-in; }
  @media screen and (min-width: 1201px) {
    .desktop [data-current="8"] [data-slide='7'] .items .item,
    .tablet.landscape [data-current="8"] [data-slide='7'] .items .item {
      transform: translate3d(0, 20%, 0); } }
  @media screen and (max-width: 1200px) {
    .desktop [data-current="8"] [data-slide='7'] .items .item,
    .tablet.landscape [data-current="8"] [data-slide='7'] .items .item {
      transform: translate3d(0, 10%, 0); } }

.desktop [data-current="8"] [data-slide='8'],
.tablet.landscape [data-current="8"] [data-slide='8'] {
  transform: translate3d(0, 0, 0);
  transition: transform 1.4s ease-in-out; }
  .desktop [data-current="8"] [data-slide='8'] .title,
  .tablet.landscape [data-current="8"] [data-slide='8'] .title {
    transform: translate3d(0, 0, 0);
    transition: transform 0.8s ease 0.4s; }
  .desktop [data-current="8"] [data-slide='8'] .carousel .owl-item.active,
  .tablet.landscape [data-current="8"] [data-slide='8'] .carousel .owl-item.active {
    transition: opacity 0.4s ease 1s; }
    .desktop [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active,
    .tablet.landscape [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active {
      transition: opacity 0.4s ease 1.05s; }
      .desktop [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active + .active,
      .tablet.landscape [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active + .active {
        transition: opacity 0.4s ease 1.1s; }
        .desktop [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active + .active + .active,
        .tablet.landscape [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active + .active + .active {
          transition: opacity 0.4s ease 1.15s; }
          .desktop [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active + .active + .active + .active,
          .tablet.landscape [data-current="8"] [data-slide='8'] .carousel .owl-item.active + .active + .active + .active + .active {
            transition: opacity 0.4s ease 1.2s; }

.desktop [data-current="8"] [data-slide='9'] .title,
.tablet.landscape [data-current="8"] [data-slide='9'] .title {
  transition: transform 0.1s ease 1.3s; }

.desktop [data-current="8"] [data-slide='9'] .brands .brand,
.tablet.landscape [data-current="8"] [data-slide='9'] .brands .brand {
  transition: all 0.01s ease 1.4s; }

.desktop [data-current="10"] [data-slide='9'],
.tablet.landscape [data-current="10"] [data-slide='9'] {
  transform: translate3d(0, -100%, 0); }

.desktop [data-current="9"] [data-slide='8'] .title,
.tablet.landscape [data-current="9"] [data-slide='8'] .title {
  transition: transform 0.4s ease 0.4s; }

.desktop [data-current="9"] [data-slide='9'],
.tablet.landscape [data-current="9"] [data-slide='9'] {
  transform: translate3d(0, 0, 0); }
  .desktop [data-current="9"] [data-slide='9'] .title,
  .tablet.landscape [data-current="9"] [data-slide='9'] .title {
    transform: translate3d(0, 0, 0);
    transition: transform 0.8s ease 0.4s; }
  .desktop [data-current="9"] [data-slide='9'] .brands .brand,
  .tablet.landscape [data-current="9"] [data-slide='9'] .brands .brand {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
    .desktop [data-current="9"] [data-slide='9'] .brands .brand:nth-child(1),
    .tablet.landscape [data-current="9"] [data-slide='9'] .brands .brand:nth-child(1) {
      transition: all 0.5s ease 0.8s; }
    .desktop [data-current="9"] [data-slide='9'] .brands .brand:nth-child(2),
    .tablet.landscape [data-current="9"] [data-slide='9'] .brands .brand:nth-child(2) {
      transition: all 0.5s ease 0.85s; }
    .desktop [data-current="9"] [data-slide='9'] .brands .brand:nth-child(3),
    .tablet.landscape [data-current="9"] [data-slide='9'] .brands .brand:nth-child(3) {
      transition: all 0.5s ease 0.9s; }
    .desktop [data-current="9"] [data-slide='9'] .brands .brand:nth-child(4),
    .tablet.landscape [data-current="9"] [data-slide='9'] .brands .brand:nth-child(4) {
      transition: all 0.5s ease 0.95s; }

.desktop [data-current="10"] [data-slide='9'] .title,
.tablet.landscape [data-current="10"] [data-slide='9'] .title {
  transition: transform 0.4s ease 0.4s; }

.desktop [data-current="10"] [data-slide='9'] .brands .brand,
.tablet.landscape [data-current="10"] [data-slide='9'] .brands .brand {
  opacity: 1;
  transition: all 0.01s ease 1.4s; }

.desktop [data-current="10"] [data-slide='10'],
.tablet.landscape [data-current="10"] [data-slide='10'] {
  transform: translate3d(0, 0, 0); }
