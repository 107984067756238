[data-elements="3"]{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  overflow: hidden;
  .line {
    height: 0;
    width: 1px;
    background-color: rgba($white, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 105vh;
    [data-current="1"] & {
      top: 105vh;
      height: 0;
      transition: top 0.7s linear, height 0.1s ease 1.3s;
    }
    [data-current="2"] & {
      top: 33vh;
      height: 100vh;
      transform: translate3d(0,0,0);
      transition: height 0.4s ease 1.1s, transform 1.1s ease;
    }
    [data-current="3"] & {
      height: 100vh;
      top: 33vh;
      transform: translate3d(0,-33vh,0);
      transition: transform 1.1s ease;
    }
    [data-current="4"] & {
      height: 100vh;
      top: 33vh;
      transform: translate3d(0,-33vh,0);
    }
    [data-current="5"] & {
      height: 100vh;
      top: 33vh;
      transform: translate3d(0,-33vh,0);
      transition: height 0.6s ease 0.6s;
    }
    [data-current="6"] & {
      top: 0;
      height: 24vh;
      opacity: 1;
      transition: all 0.8s ease-in, opacity 0.1s ease 0.8s;
    }
    [data-current="7"] & {
      opacity: 0;
    }
    [data-current="8"] & {
      top: auto;
      bottom: 0;
      height: 100vh;
      transform: translate3d(0,100vh,0);
      transition: transform 1.4s ease-in-out, opacity 0.01s ease 1.3s;
      &.not-complited {
        top: 0;
        bottom: auto;
        height: 20vh;
      }
    }
    [data-current="9"] & {
      top: auto;
      bottom: 0;
      height: 100vh;
      transition: transform 1.4s ease-in-out;
      &.not-complited {
        top: 0;
        bottom: auto;
        height: 20vh;
      }
    }
    [data-current="10"] & {
      top: auto;
      bottom: 0;
      height: 100vh;
    }
  }
  .light {
    opacity: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 0 80px 7px rgba($white,0.95);
    position: absolute;
    left: 0;
    right: 0;
    top: 33vh;
    z-index: 1;
    margin: -5px auto 0;
    animation: small_light 2s infinite linear alternate;
    [data-current="1"] & {
      top: 105vh;
      transition: top 0.7s linear, opacity 0.1s ease 1.3s;
    }
    [data-current="2"] & {
      opacity: 1;
      transition: opacity 1s ease 1s;
    }
    [data-current="3"] & {
      opacity: 1;
    }
    [data-current="4"] & {
      opacity: 1;
    }
    [data-current="5"] & {
      opacity: 1;
      transition: top 0.6s ease 0.6s;
    }
    [data-current="6"] & {
      opacity: 1;
      top: 24vh;
      transition: top 0.5s ease, opacity 0.2s ease 1s;
    }
    [data-current="7"] & {
      opacity: 0;
      top: 24vh;
      margin-top: -10vh;
      transition: opacity 0.2s ease, margin-top 0.65s ease;
    }
    [data-current="8"] & {
      opacity: 0;
      top: 120vh;
      margin-top: -10vh;
      transition: opacity 0.1s ease 0.3s, margin-top 0.65s ease, top 0.1s ease 1.4s;
      &.not-complited {
        top: 20vh;
      }
    }
    [data-current="9"] & {
      opacity: 1;
      top: 20vh;
      transition: top 0.9s ease-in-out 0.3s, opacity 0.1s ease 0.1s;
      &.not-complited {
        top: 20vh;
        transition: opacity 0.1s ease 0.8s, margin-top 0.65s ease, top 0.1s ease 1.4s;
      }
    }
    [data-current="10"] & {
      z-index: 2;
      opacity: 0;
      top: 20vh;
      transition: top 0.9s ease-in-out 0.1s, opacity 0.1s ease 0.8s;
    }
  }

  .boxes_application {
    position: absolute;
    top: 24vh;
    left: 50px;
    right: 50px;
    bottom: 16vh;
    background-color: $dark;
    opacity: 0;
    &:after {
      content: "";
      left: 0;
      right: 0;
      top: 100%;
      transform: translate3d(0,16vh,0);
      height: 24vh;
      width: 1px;
      background-color: rgba($white,.2);
      margin: auto;
      position: absolute;
    }
    [data-current="5"] & {
      transition: opacity 0.3s ease;
    }
    [data-current="6"] & {
      opacity: 1;
      transition: opacity 0.01s ease 0.9s, transform 1.2s ease-in-out;
    }
    [data-current="7"] & {
      opacity: 1;
      transform: translate3d(0,-100vh,0);
      transition: transform 1.4s ease-in-out, opacity 0.05s ease 1.3s;
    }
    [data-current="8"] & {
      transform: translate3d(0,-100vh,0);
    }
    .box1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
      background-color: $dark;
      border-width: 1px;
      border-style: solid solid none solid;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 21vh 21vh 0 0;
      }
      @include media(screen,1200){
        border-radius: 16vh 16vh 0 0;
      }
      @include media(hover,1921){
        .portrait & {
          border-radius: 10vh 10vh 0 0;
        }
      }
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .box2 {
      position: absolute;
      left: 0;
      top: 50%;
      right: 75%;
      bottom: 0;
      background-color: $dark;
      border-width: 1px;
      border-style: none none solid solid;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 0 0 21vh;
      }
      @include media(screen,1200){
        border-radius: 0 0 0 16vh;
      }
      @include media(hover,1921){
        .portrait & {
          border-radius: 0 0 0 10vh;
        }
      }
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .box3 {
      position: absolute;
      right: 0;
      top: 50%;
      left: 75%;
      bottom: 0;
      background-color: $dark;
      border-width: 1px;
      border-style: none solid solid none;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 0 21vh 0;
      }
      @include media(screen,1200){
        border-radius: 0 0 16vh 0;
      }
      @include media(hover,1921){
        .portrait & {
          border-radius: 0 0 10vh 0;
        }
      }
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .box4 {
      position: absolute;
      right: 50%;
      top: 100%;
      left: 25%;
      height: 100px;
      background-color: $dark;
      border-width: 1px;
      border-style: solid solid none none;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 100px 0 0;
      }
      @include media(screen,1200){
        border-radius: 0 50px 0 0;
      }
      margin-top: -1px;
      margin-right: -0.5px;
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .box5 {
      position: absolute;
      left: 50%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: $dark;
      border-width: 1px;
      border-style: solid none none solid;
      border-color: rgba($white,.2);
      margin-top: -1px;
      margin-left: -0.5px;
      @include media(hover,1201){
        border-radius: 100px 0 0 0;
      }
      @include media(screen,1200){
        border-radius: 50px 0 0 0;
      }
      [data-current="5"] & {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    .box6 {
      position: absolute;
      left: 25%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: $dark;
      margin-top: -1px;
      &:before {
        content: "";
        top: 100%;
        left: 0;
        right: 0;
        margin: auto;
        width: 1px;
        background-color: rgba($white, 0.2);
        position: absolute;
        height: 0;
        opacity: 0;
        [data-current="5"] & {
          transition: height 0.1s ease 1s, opacity 0.3s ease;
        }
        [data-current="6"] & {
          opacity: 1;
          height: calc(16vh - 100px);
          transition: height 0.2s ease 1.4s;
        }
        [data-current="7"] & {
          opacity: 1;
          height: calc(16vh - 100px);
        }
      }
    }

    .light1_block {
      position: absolute;
      opacity: 0;
      height: 21vh;
      width: 2px;
      background-color: transparent;
      margin-left: -1px;
      left: 50%;
      transition: all 0.2s;
      transform-origin: bottom center;
      top: 0;
      z-index: 2;
      [data-current="6"] & {
        opacity: 1;
        left: 35vh;
        transition: opacity 0.4s ease 1.1s, left 0.1s linear 1.2s;
      }
      [data-current="7"] & {
        left: 35vh;
        opacity: 0;
        transition: opacity 0.05s ease 0.3s;
        animation: leftLightAnimation linear 0.3s forwards;
      }
      .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 80px 7px rgba($white,0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px 0 0 -4px;
        z-index: 2;
      }
    }
    .light1_block_part2 {
      position: absolute;
      opacity: 0;
      height: 100px;
      width: 2px;
      background-color: transparent;
      margin-left: -1px;
      left: calc(50% - 100px);
      transition: all 0.1s;
      transform-origin: bottom center;
      top: 100%;
      z-index: 2;
      [data-current="6"] & {
        //opacity: 1;
        //left: 35vh;
        //transition: opacity 0.4s ease 1.1s, left 0.1s linear 1.2s;
      }
      [data-current="7"] & {
        opacity: 1;
        margin-top: calc(60vh - 100px);
        transition: opacity 0.05s ease 0.3s, transform 0.1s linear 0.3s, margin 0.2s ease 0.4s;
        transform: rotate(90deg);
      }
      .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 80px 7px rgba($white,0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px 0 0 -4px;
        z-index: 2;
        [data-current="7"] & {
          opacity: 0;
          transition: opacity 0.05s ease 0.55s;
        }
      }
    }
    .light2_block {
      position: absolute;
      opacity: 0;
      height: 21vh;
      width: 2px;
      background-color: transparent;
      margin-right: -1px;
      right: 50%;
      transition: all 0.2s;
      transform-origin: bottom center;
      top: 0;
      z-index: 2;
      [data-current="6"] & {
        opacity: 1;
        right: 35vh;
        transition: opacity 0.4s ease 1.1s, right 0.1s linear 1.2s;
      }
      [data-current="7"] & {
        right: 35vh;
        opacity: 0;
        transition: opacity 0.05s ease 0.3s;

        animation: rightLightAnimation linear 0.3s forwards;
      }
      .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 80px 7px rgba($white,0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px -4px 0 0;
        z-index: 2;
      }
    }
    .light2_block_part2 {
      position: absolute;
      opacity: 0;
      height: 100px;
      width: 2px;
      background-color: transparent;
      margin-right: -1px;
      right: calc(50% - 100px);
      transition: all 0.1s;
      transform-origin: bottom center;
      top: 100%;
      z-index: 2;
      [data-current="6"] & {
        //opacity: 1;
        //left: 35vh;
        //transition: opacity 0.4s ease 1.1s, left 0.1s linear 1.2s;
      }
      [data-current="7"] & {
        opacity: 1;
        margin-top: calc(60vh - 100px);
        transition: opacity 0.05s ease 0.3s, transform 0.1s linear 0.3s, margin 0.2s ease 0.4s;
        transform: rotate(-90deg);
      }
      .lighter {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 80px 7px rgba($white,0.95);
        animation: small_light 2s infinite linear alternate;
        top: 0;
        margin: -5px 0 0 -4px;
        z-index: 2;
        [data-current="7"] & {
          opacity: 0;
          transition: opacity 0.05s ease 0.55s;
        }
      }
    }

    .light1,
    .light2 {
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 80px 7px rgba($white,0.95);
      position: absolute;
      animation: small_light 2s infinite linear alternate;
      top: 0;
      margin: -5px 0 0;
      z-index: 2;
      [data-current="6"] & {
        opacity: 1;
      }
    }
    .light1 {
      left: 50%;
      margin-left: -5px;
      [data-current="6"] & {
        left: 18vw;
        transition: opacity 0.4s ease 1.1s, left 0.1s linear 1.2s;
      }
    }
    .light2 {
      right: 50%;
      margin-right: -5px;
      [data-current="6"] & {
        right: 18vw;
        transition: opacity 0.4s ease 1.1s, right 0.1s linear 1.2s;
      }
    }
  }
  .gates_application {
    position: absolute;
    top: 24vh;
    left: 50px;
    right: 50px;
    bottom: 16vh;
    opacity: 0;
    [data-current="6"] & {
      opacity: 1;
      transition: opacity 0.01s ease 0.9s;
    }
    [data-current="7"] & {
      opacity: 0;
    }
    .gate1 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      left: 32vh;
      [data-current="6"] & {
        width: 0;
        transition: width 0.1s linear 1.2s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
    .gate2 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      right: 32vh;
      [data-current="6"] & {
        width: 0;
        transition: width 0.1s linear 1.2s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
    .gate3 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right bottom;
      [data-current="6"] & {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.3s, opacity 0.05s linear 1.3s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
    .gate4 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: left bottom;
      [data-current="6"] & {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.3s, opacity 0.05s linear 1.3s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
    .gate5 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 32vh;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right top;
      [data-current="6"] & {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.35s, opacity 0.05s linear 1.35s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
    .gate6 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 32vh;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: right top;
      [data-current="6"] & {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.05s linear 1.35s, opacity 0.05s linear 1.35s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
    .gate7 {
      position: absolute;
      background-color: rgba($dark,1);
      bottom: -17vh;
      margin-bottom: 2px;
      left: 32vh;
      right: 32vh;
      height: 17vh;
      [data-current="6"] & {
        height: 0;
        opacity: 0;
        transition: height 0.1s linear 1.4s, opacity 0.05s linear 1.4s;
      }
      [data-current="7"] & {
        opacity: 0;
      }
    }
  }
  .boxes_offer {
    position: absolute;
    top: 24vh;
    left: 50px;
    right: 50px;
    bottom: 16vh;
    background-color: $dark;
    opacity: 0;

    [data-current="5"] & {
      opacity: 1;
      transform: translate3d(0,100vh,0);
    }
    [data-current="6"] & {
      transition: opacity 0.3s ease, transform 1.4s ease-in-out;
      opacity: 1;
      transform: translate3d(0,100vh,0);
    }
    [data-current="7"] & {
      opacity: 1;
      transition: opacity 0.01s ease 0.9s, transform 1.4s ease-in-out;
    }
    [data-current="8"] & {
      opacity: 1;
      transform: translate3d(0,-100vh,0);
      transition: transform 1.4s ease-in-out, opacity 0.05s ease 1.3s;
    }

    .box1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
      background-color: $dark;
      border-width: 1px;
      border-style: solid solid none solid;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 21vh 21vh 0 0;
      }
      @include media(screen,1200){
        border-radius: 16vh 16vh 0 0;
      }
      @include media(hover,1921){
        .portrait & {
          border-radius: 10vh 10vh 0 0;
        }
      }
    }
    .box2 {
      position: absolute;
      left: 0;
      top: 50%;
      right: 75%;
      bottom: 0;
      background-color: $dark;
      border-width: 1px;
      border-style: none none solid solid;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 0 0 21vh;
      }
      @include media(screen,1200){
        border-radius: 0 0 0 16vh;
      }
      @include media(hover,1921){
        .portrait & {
          border-radius: 0 0 0 10vh;
        }
      }
    }
    .box3 {
      position: absolute;
      right: 0;
      top: 50%;
      left: 75%;
      bottom: 0;
      background-color: $dark;
      border-width: 1px;
      border-style: none solid solid none;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 0 21vh 0;
      }
      @include media(screen,1200){
        border-radius: 0 0 16vh 0;
      }
      @include media(hover,1921){
        .portrait & {
          border-radius: 0 0 10vh 0;
        }
      }
    }
    .box4 {
      position: absolute;
      right: 50%;
      top: 100%;
      left: 25%;
      height: 100px;
      background-color: $dark;
      border-width: 1px;
      border-style: solid solid none none;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 100px 0 0;
      }
      @include media(screen,1200){
        border-radius: 0 50px 0 0;
      }
      margin-top: -1px;
      margin-right: -0.5px;
    }
    .box5 {
      position: absolute;
      left: 50%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: $dark;
      border-width: 1px;
      border-style: solid none none solid;
      border-color: rgba($white,.2);
      margin-top: -1px;
      margin-left: -0.5px;
      @include media(hover,1201){
        border-radius: 100px 0 0 0;
      }
      @include media(screen,1200){
        border-radius: 50px 0 0 0;
      }
    }
    .box6 {
      position: absolute;
      left: 25%;
      top: 100%;
      right: 25%;
      height: 100px;
      background-color: $dark;
      margin-top: -1px;
      &:before {
        content: "";
        //top: 100%;
        top: 100px;
        left: 0;
        right: 0;
        margin: auto;
        width: 1px;
        background-color: rgba($white, 0.2);
        position: absolute;
        height: 0;
        opacity: 0;
        [data-current="6"] & {
          transition: height 0.1s ease 1s, opacity 0.3s ease;
        }
        [data-current="7"] & {
          opacity: 1;
          //height: calc(16vh - 100px);
          height: 20vh;
          transition: height 0.2s ease 1.4s;
        }
        [data-current="8"] & {
          opacity: 1;
          //height: calc(16vh - 100px);
          height: 19vh;
        }
      }
    }


    .gate1 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      left: 32vh;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        width: 0;
        transition: width 0.15s linear 0.8s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }
    .gate2 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      width: calc(50% - 32vh);
      height: 1vh;
      right: 32vh;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        width: 0;
        transition: width 0.15s linear 0.8s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }
    .gate3 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right bottom;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.1s linear 0.95s, opacity 0.05s linear 1.05s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }
    .gate4 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 0;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: left bottom;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.1s linear 0.95s, opacity 0.05s linear 1.05s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }
    .gate5 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 32vh;
      height: 32vh;
      width: 32vh;
      left: 0;
      transform-origin: right top;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        transform: rotate(-90deg);
        opacity: 0;
        transition: transform 0.1s linear 1.05s, opacity 0.05s linear 1.15s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }
    .gate6 {
      position: absolute;
      background-color: rgba($dark,1);
      top: 32vh;
      height: 32vh;
      width: 32vh;
      right: 0;
      transform-origin: right top;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        transform: rotate(90deg);
        opacity: 0;
        transition: transform 0.1s linear 1.05s, opacity 0.05s linear 1.15s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }
    .gate7 {
      position: absolute;
      background-color: rgba($dark,1);
      bottom: -17vh;
      margin-bottom: 2px;
      left: 32vh;
      right: 32vh;
      height: 17vh;
      [data-current="6"] & {
        transition: all 0.01s linear 1.4s;
      }
      [data-current="7"] & {
        height: 0;
        opacity: 0;
        transition: height 0.2s linear 1.15s, opacity 0.05s linear 1.35s;
      }
      [data-current="8"] & {
        opacity: 0;
      }
    }

    .light0,
    .light1,
    .light2 {
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 80px 7px rgba($white,0.95);
      position: absolute;
      animation: small_light 2s infinite linear alternate;
      top: 0;
      margin: -5px 0 0;
      z-index: 2;
      [data-current="6"] & {
        transition: all 0.1s ease 1.4s;
      }
      [data-current="7"] &,
      [data-current="8"] & {
        opacity: 1;
      }
    }
    .light0 {
      left: 50%;
      margin-left: -5px;
      [data-current="6"] & {
        opacity: 1;
      }
    }
    .light1 {
      left: 50%;
      margin-left: -5px;
      [data-current="7"] & {
        left: 18vw;
        transition: opacity 0.4s ease 0.8s, left 0.1s linear 0.8s;
      }
      [data-current="8"] & {
        transition: opacity 0.1s ease 1.3s, left 0.1s linear 1.3s;
      }
    }
    .light2 {
      right: 50%;
      margin-right: -5px;
      [data-current="7"] & {
        right: 18vw;
        transition: opacity 0.4s ease 0.8s, right 0.1s linear 0.8s;
      }
      [data-current="8"] & {
        transition: opacity 0.1s ease 1.3s, right 0.1s linear 1.3s;
      }

    }
  }
  .boxes_timeline {
    position: absolute;
    top: 24vh;
    left: 0;
    right: 0;
    bottom: 16vh;
    opacity: 0;
    z-index: 1;
    &:before {
      content: "";
      left: 0;
      right: 0;
      bottom: 100%;
      height: 24vh;
      background-color: $dark;
      margin: auto;
      position: absolute;
    }
    [data-current="7"] & {
      opacity: 1;
      transform: translate3d(0,100vh,0);
      transition: transform 1.2s ease-in-out, opacity 0.1s ease 1.3s;
    }
    [data-current="8"] & {
      opacity: 1;
      transition: transform 1.4s ease-in-out;
    }
    [data-current="9"] & {
      z-index: 1;
      opacity: 1;
      transform: translate3d(0,-100vh,0);
      transition: transform 1.4s ease-in-out, opacity 0.1s ease-in-out 1.3s;
    }
    [data-current="10"] & {
      opacity: 0;
      transform: translate3d(0,-100vh,0);
      transition: opacity 0.6s ease-in-out 0.4s;
    }
    .box1 {
      position: absolute;
      left: -1px;
      margin-right: -0.5px;
      right: 50%;
      bottom: 100%;
      height: 24vh;
      background-color: $dark;
      border-width: 1px;
      border-style: none solid solid none;
      border-color: rgba($white,.2);
      @include media(hover,1201){
        border-radius: 0 0 100px 0;
      }
      @include media(screen,1200){
        border-radius: 0 0 50px 0;
      }
      .gate1 {
        position: absolute;
        background-color: rgba($dark,1);
        top: 0;
        height: 21vh;
        width: 21vh;
        right: -1vh;
        transform-origin: left top;
        [data-current="7"] & {
          transition: all 0.01s linear 1.4s;
        }
        [data-current="8"] & {
          transform: rotate(90deg);
          opacity: 0;
          transition: transform 0.5s linear 0.2s, opacity 0.01s ease 0.7s;
        }
        [data-current="9"] & {
          transform: rotate(90deg);
          opacity: 0;
        }
      }
      .gate2 {
        position: absolute;
        background-color: rgba($dark,1);
        bottom: -1vh;
        height: 2vh;
        left: 0;
        width: calc(100% - 20vh);
        [data-current="7"] & {
          transition: all 0.01s linear 1.4s;
        }
        [data-current="8"] & {
          width: 0;
          opacity: 0;
          transition: width 0.2s linear 0.7s, opacity 0.01s ease 1.3s;
        }
        [data-current="9"] & {
          width: 0;
          opacity: 0;
        }
      }
    }
    .box2 {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        margin: auto;
        background-color: rgba($white,.2);
        [data-current="7"] & {
          transition: all 0.01s linear 1.4s;
        }
        [data-current="8"] & {
          width: 100%;
          transition: width 0.3s linear 0.9s;
        }
        [data-current="9"] & {
          width: 100%;
        }
      }
    }
    .box3 {
      position: absolute;
      left: 50%;
      right: -1px;
      top: 100%;
      height: 20vh;
      border-width: 1px;
      border-style: solid none none solid;
      border-color: rgba($white,.2);
      margin-left: -0.5px;
      @include media(hover,1201){
        border-radius: 100px 0 0 0;
      }
      @include media(screen,1200){
        border-radius: 50px 0 0 0;
      }
      .gate3 {
        position: absolute;
        background-color: rgba($dark,1);
        top: -1vh;
        height: 2vh;
        left: 20vh;
        width: calc(100% - 20vh);
        [data-current="7"] & {
          transition: all 0.01s linear 1.4s;
        }
        [data-current="8"] & {
          width: 0;
          opacity: 0;
          transition: width 0.2s linear 1.2s, opacity 0.01s ease 1.4s;
        }
        [data-current="9"] & {
          width: 0;
          opacity: 0;
        }
      }
      .gate4 {
        position: absolute;
        background-color: rgba($dark,1);
        top: -1vh;
        height: 21vh;
        width: 21vh;
        left: -1vh;
        transform-origin: right bottom;
        [data-current="7"] & {
          transition: all 0.01s linear 1.4s;
        }
        [data-current="8"] & {
          transform: rotate(-90deg);
          opacity: 0;
          transition: transform 0.1s linear 1.4s, opacity 0.01s ease 1.5s;
        }
        [data-current="9"] & {
          transform: rotate(-90deg);
          opacity: 0;
        }
      }

    }
    .light0 {
      opacity: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 80px 7px rgba($white,0.95);
      position: absolute;
      animation: small_light 2s infinite linear alternate;
      top: -11vh;
      z-index: 2;
      left: 50%;
      margin-left: -5px;
      [data-current="7"] &,
      [data-current="8"] &,
      [data-current="9"] & {
        opacity: 1;
      }
    }
  }
  .boxes_before_footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 1.4s ease-in-out;
    z-index: 1;
    [data-current="10"] & {
      transform: translate3d(0,-100vh,0);
    }
  }
  .boxes-rounds {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 175px;
    background-color: $white;
    transform: translate3d(0,100%,0);
    z-index: 3;

    [data-current="9"] & {
      transform: translate3d(0,150px,0);
      transition: 1s ease-in-out;
    }
    [data-current="10"] & {
      transform: translate3d(0,25px,0);
      transition: 1s ease-in-out;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 75px;
      margin: auto;
      width: 1px;
      background-color: rgba($white, 0.2);
      z-index: 1;
    }
    .left {
      position: absolute;
      left: 0;
      top: 0;
      right: 50%;
      height: 125px;
      background-color: $dark;
      margin: 0 -0.5px 0 0;
      @include media(hover,1201){
        border-radius: 0 0 75px 0;
      }
      @include media(screen,1200){
        border-radius: 0 0 50px 0;
      }
    }
    .right {
      position: absolute;
      right: -1px;
      top: 0;
      left: 50%;
      height: 125px;
      background-color: $dark;
      margin: 0 0 0 -0.5px;
      @include media(hover,1201){
        border-radius: 0 0 0 75px;
      }
      @include media(screen,1200){
        border-radius: 0 0 0 50px;
      }
    }
  }
}

@keyframes small_light {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@keyframes leftLightAnimation {
  0% {
    left: 21vh;
    top: 0;
  }
  25% {
    top: 0;
    left: 21vh;
    transform: rotate(-90deg);
  }
  50% {
    top: 18vh;
    left: 21vh;
    transform: rotate(-90deg);
  }
  75% {
    top: 18vh;
    left: 21vh;
    transform: rotate(-180deg);
  }
  100% {
    top: 18vh;
    left: calc(50% - 100px);
    transform: rotate(-180deg);
  }
}

@keyframes rightLightAnimation {
  0% {
    right: 21vh;
    top: 0;
  }
  25% {
    top: 0;
    right: 21vh;
    transform: rotate(90deg);
  }
  50% {
    top: 18vh;
    right: 21vh;
    transform: rotate(90deg);
  }
  75% {
    top: 18vh;
    right: 21vh;
    transform: rotate(180deg);
  }
  100% {
    top: 18vh;
    right: calc(50% - 100px);
    transform: rotate(180deg);
  }
}