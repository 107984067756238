.header {
  background-color: $dark;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  transition: all 0.2s;
  &.no-bg {
    .desktop & {
      background-color: transparent;
      @include media(hover,1201){
        .logo {
          transform-origin: left center;
          transform: scale(1.5);
        }
      }
    }
    .tablet & {
      background-color: $black;
      &:not(.minimized) {
        background-color: transparent;
        .header--main {
          height: 120px;
        }
        .header--main:after {
          background-color: transparent;
        }
        .nav {
          background-color: transparent;
        }
        .logo {
          transform-origin: left center;
          transform: scale(1.5);
        }
      }
    }
    [data-current="1"] & {
      .desktop &,
      .tablet.landscape & {
        background-color: transparent;
      }
    }
    [data-current="2"] &,
    [data-current="3"] &,
    [data-current="4"] &,
    [data-current="5"] &,
    [data-current="6"] &,
    [data-current="7"] &,
    [data-current="8"] &,
    [data-current="9"] &,
    [data-current="10"] & {
      .desktop &,
      .tablet.landscape & {
        background-color: $black;
        @include media(hover,1201){
          .header--main {
            height: 60px;
          }
          .logo {
            transform: scale(1);
          }
        }
        @include media(screen,1200){
          box-shadow: 0 2px 15px 0 rgba($black,0.5);
        }
      }
    }
    .tablet.portrait &,
    .mobile & {
      background-color: $black;
      box-shadow: 0 2px 15px 0 rgba($black,0.5);
    }
    & ~ .main {
      & >:first-child {
        margin-top: 0;
      }
      .landing {
        .mobile & {
          @include media(hover,1201){
            padding-top: 140px;
          }
          @include media(screen,1200){
            padding-top: 80px;
          }
        }
      }
    }
    @include breakpoints(681,1200){
      transform: translateY(-45px);
      .header--main {
        padding-top: 45px;
        //&:after {
        //  .desktop &,
        //  .tablet.landscape & {
        //    background-color: transparent;
        //  }
        //}
      }

    }
    @include media(screen,680){
      transform: translateY(-25px);
      .header--main {
        padding-top: 25px;
        //&:after {
        //  .desktop &,
        //  .tablet.landscape & {
        //    background-color: transparent;
        //  }
        //}
      }
    }
    [data-current="10"] & {
      transform: translateY(-100%);
      transition: 1s ease-in-out;
    }
  }
  [data-current="9"] & {
    transition: 1s ease-in-out;
  }
  [data-current="10"] & {
    transform: translateY(-100%);
    transition: 1s ease-in-out;
  }
  .logo {
    width: 154px;
    height: 35px;
    position: relative;
    z-index: 2;
    img {
      @extend %fullBlock;
    }
    @include media(hover,1201){
      transform-origin: left center;
      transform: scale(1.5);
    }
  }
  .burger {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 2;
    span {
      width: 40px;
      height: 4px;
      position: absolute;
      border-radius: 4px;
      top: 8px;
      transition: background-color 0s linear .13s;
      background-color: $white;
      display: block;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: $white;
        will-change: top;
      }
      &:before{
        top: 10px;
        transition: top .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
      }
      &:after {
        top: 20px;
        transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
      }
    }
    @include media(hover,1025){display: none;}
    &--check {
      @include media(screen,1024) {
        &:checked {
          & ~ .burger {
            span {
              transition-delay: .22s;
              background-color: transparent;
              &:before {
                top: 0;
                transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
                transform: translate3d(0, 10px, 0) rotate(45deg);
              }
              &:after {
                top: 0;
                transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
                transform: translate3d(0, 10px, 0) rotate(-45deg);
              }
            }
          }
          & ~ .nav {
            transform: translate3d(0,0,0);
            .tablet & {
              background-color: #000;
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  &.minimized {
    transition: all 0.35s;
    background-color: $black;
    @include media(hover,681){
      transform: translateY(-45px);
    }
    @include media(screen,680){
      transform: translateY(-25px);
    }
    @include media(hover,1201){
      .logo {
        transform: scale(1);
      }
    }
  }
  &--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s;
    .minimized & {
      transition: all 0.35s;
    }
    @include media(hover,681){
      height: 105px;
      .minimized & {
        padding-top: 45px;
      }
    }
    @include media(screen,680){
      height: 85px;
      .minimized & {
        padding-top: 25px;
      }
    }
    @include media(screen,1024) {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        z-index: 1;
      }
    }
  }

}