.nav {
  display: flex;
  align-items: center;
  .header & {
    @include media(screen,1024) {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      right: 0;
      background-color: $black;
      transform: translate3d(0,-130%,0);
      transition: transform .2s cubic-bezier(.215, .61, .355, 1) .22s, background-color .2s ease 0.2s;
      flex-direction: column;
      padding: 5rem 0.65*$padding;
      .tablet & {
        &:before {
          opacity: 0;
          transition: all 0.2s ease 0.2s;
          content: "";
          position: absolute;
          bottom: 100%;
          left: 0;
          right: 0;
          height: 120px;
          background-color: #000;
          z-index: 1;
        }
      }
    }
  }
  .footer & {
    @include media(screen,680) {
      flex-direction: column;
      width: 100%;
      padding: 2rem 0;
    }
  }
  &--menu {
    display: flex;
    align-items: center;
    li {
      margin-right: 38px;
    }
    &:last-child {
      li:last-child {
        margin-right: 0;
      }
    }
    a {
      text-transform: uppercase;
      border-bottom: 1px solid transparent;
      @include media(hover,1025) {
        font-size: 1rem;
      }
      @include media(screen,1024) {
        font-size: 1.4rem;
      }
      .header & {
        color: $white;
        &.active {border-color: $white;}
        &:hover {
          @include media(hover,1025){
            border-color: $white;
          }
        }
      }
      .footer & {
        color: $dark;
        &.active {border-color: $dark;}
        &:hover {
          @include media(hover,1025){
            border-color: $dark;
          }
        }
      }
    }
    .header & {
      @include media(screen,1024) {
        flex-direction: column;
        li {
          margin: 0 0 2.1rem;
        }
      }
    }
    .footer & {
      @include media(screen,680) {
        flex-direction: column;
        li {
          margin: 0 0 1.5rem;
        }
      }
    }
  }
  .lang {
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    @include media(hover,1025){
      margin-right: 38px;
    }
    @include media(screen,1024) {
      margin-bottom: 15px;
      font-size: 1.4rem;
    }
    &-sign {
      color: $white;
      border: 1px solid $white;
      border-radius: 2px;
      min-width: 34px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
    }
    &-select {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      border-radius: 2px;
      border: 1px solid $dark;
      background-color: $white;
      color: $dark;
      transition: all 0.2s;
      a {
        min-width: 34px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        color: inherit;
        &.current {
          background-color: $dark;
          color: $white;
          box-shadow: 0 0 0 1px $white inset;
          border-radius: 2px;
        }
        &:not(.current):hover {
          background-color: $text;
          color: $white;
        }
      }
    }
    &:hover {
      .lang-select {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}