.desktop {
  [data-slide='6'] {
    transform: translate3d(0,100%,0);
    .head {
      width: 40%;
      left: 50%;
      padding-left: 75px;
      padding-right: 25px;
      margin-bottom: 3vh;
      position: absolute;
      bottom: 72vh;
    }
    .title {
      color: $white;
      position: relative;
      z-index: 2;
      transform: translate3d(0,100%,0);
    }
    .items-box {
      position: absolute;
      z-index: 1;
      bottom: 50px;
      display: flex;
      align-items: center;
      @include media(hover,1481){
        left: 3vw;
        right: 3vw;
        top: 100px;
      }
      @include breakpoints(1201,1480){
        left: 8vw;
        right: 8vw;
        top: 50px;
      }
      @include media(screen,1200){
        top: 10vh;
        left: 10vw;
        right: 10vw;
      }
    }
    .items {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .item {
        width: 100%;
        max-width: 33.3333%;
        padding: 25px 3vw;
        opacity: 0;
        text-align: center;
        @include media(hover,1201){
          transform: translate3d(0,20%,0);
        }
        @include media(screen,1200){
          transform: translate3d(0,20%,0);
        }
        &-subtitle {
          opacity: 0.8;
        }
      }
      img {
        height: 20vh;
        width: auto;
        display: block;
        margin: 0 auto 3vh;
        max-width: 80%;
      }
    }
    .tablet-light {
      display: none;
    }
  }
}

.tablet.landscape {
  [data-slide='6'] {
    padding: 5vh 0 15vh;
    flex-direction: column;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      width: 1px;
      background-color: $white;
      left: 50%;
      opacity: 0.2;
    }
    .head {
      width: 40%;
      left: 50%;
      padding-left: 75px;
      padding-right: 25px;
      position: relative;
    }
    .title {
      color: $white;
      position: relative;
      z-index: 2;
    }
    .items-box {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5vw;
      background-color: $dark;
      .tablet-light {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 80px 7px rgba($white,0.95);
        position: absolute;
        left: 1px;
        right: 0;
        top: 0;
        z-index: 1;
        margin: -4px auto 0;
        animation: small_light 2s infinite linear alternate;
      }
    }
    .items {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border: 1px solid rgba($white,0.2);
      border-radius: 80px;
      .item {
        width: 100%;
        max-width: 33.3333%;
        padding: 25px 3vw 40px;
        text-align: center;
        &-subtitle {
          opacity: 0.8;
        }
      }
      img {
        height: 20vh;
        width: auto;
        display: block;
        margin: 0 auto 3vh;
        max-width: 80%;
      }
    }
    .tab-lines {

    }
  }
}

.mobile,
.tablet.portrait {
  [data-slide='6'] {
    padding: 20px;
    margin-bottom: 90px;
    &:after {
      content: "";
    }
    .title {
      color: $white;
    }
    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 5% 0;
      text-align: center;
      &:not(:last-child){
        margin-bottom: 50px;
      }
      img {
        width: 70vw;
        height: auto;
        margin: 0 auto 6vw;
      }
    }
    .tablet-light {
      display: none;
    }
  }
}