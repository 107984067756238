.desktop,
.tablet.landscape {

  [data-current="4"],
  [data-current="5"],
  [data-current="6"],
  [data-current="7"],
  [data-current="8"],
  [data-current="9"],
  [data-current="10"] {
    [data-slide='3'] {
      transform: translate3d(0,-100%,0);
    }
  }

  [data-current="3"] {

    //===== slide 3

    [data-slide='3'] {
      transform: translate3d(0,0,0);
      .title {
        transition: transform 1.2s ease;
        .part1 {
          transition: all 0.8s ease 0.4s;
        }
        .part2 {
          transition: all 0.8s ease 0.5s;
        }
        .part3 {
          transition: all 0.8s ease 0.6s;
        }
      }
      .subtitle {
        transition: all 0.8s ease 0.6s;
      }
      .word {
        transition: transform 0.7s ease 0.7s;
      }
    }

    //===== slide 4

    [data-slide='4'] {
      .title {
        transition: transform 1.2s ease;
        transform: translate3d(0,-8vh,0);
        .part1 {
          transition: all 0.2s ease 1.2s;
          transform: translate3d(0,8vh,0);
          opacity: 0;
        }
        .part2 {
          transition: all 0.2s ease 1.2s;
          transform: translate3d(0,8vh,0);
          opacity: 0;
        }
        .part3 {
          transition: all 0.2s ease 1.2s;
          transform: translate3d(0,8vh,0);
          opacity: 0;
        }
      }
      .subtitle {
        transition: all 0.2s ease 1.2s;
        transform: translate3d(0,8vh,0);
        opacity: 0;
      }
      .word {
        transition: transform 0.2s ease 1.1s;
        transform: translate3d(0,20vh,0);
      }
    }

  }
}