html {

  height: 100%;
  &.ovh {
    overflow: hidden;
  }
  @include media(hover,1201){font-size: 14px;}
  @include breakpoints(1025,1200){font-size: 13px;}
  @include breakpoints(681,1024){font-size: 12px;}
  @include media(screen,680){font-size: 11px;}
}
body {
  line-height: 1.4;
  font-weight: 400;
  color: $text;
  font-family: 'Noto', sans-serif;
  height: 100%;
}
a {
  outline: none;
  transition: all 0.3s;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
}

#main {
  position: relative;
  width: 100%;
  display: table-row;
  height: 100%;
  //@include media(hover,681){
  //  padding-top: 105px;
  //}
  //@include media(screen,680){
  //  padding-top: 85px;
  //}
}
.main > *:first-child {
  @include media(hover,681){
    margin-top: 105px;
  }
  @include media(screen,680){
    margin-top: 85px;
  }
}
#wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  &.no-transition {
    .elements {
      * {
        transition: none !important;
      }
    }
  }
}

.container {
  width: 100%;
  //display: flex;
  display: table;
  //flex-direction: column;
  min-height: 100vh;
  @include media(hover,681){
    //padding-top: 105px;
  }
  @include media(screen,680){
    //padding-top: 85px;
  }
  & > .footer {
    //margin-top: auto;
    display: table-footer-group;
  }
}
.dark-theme {
  background-color: $dark;
}