.footer {
  background-color: $light;
  .landing & {
    margin-top: auto;
    width: 100%;
  }
  &--main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media(hover,1025){
      min-height: 160px;
      &.right-align {
        justify-content: flex-end;
      }
    }
    @include media(screen,1024){
      flex-direction: column;
      padding: 0.5*$padding 0;
    }
  }
  &--links {
    @include media(screen,1024){
      order: 1;
      margin: 0.5*$padding 0 0;
    }
    @include media(hover,481){
      font-size: 0.9rem;
    }
    @include media(screen,480){
      font-size: 0.8rem;
    }
    ul {
      display: flex;
      align-items: center;
    }
    li {
      &:not(:last-child){
        @include media(hover,481){
          margin-right: 0.5*$padding;
        }
        @include media(screen,480){
          margin-right: 0.3*$padding;
        }
      }
    }
    a {
      text-transform: uppercase;
      color: $dk_border;
      display: block;
      &:hover {
        @include media(hover,1025){
          color: $text;
        }
      }
    }
  }
}