.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  @include media(hover,1025){
    margin-bottom: 60px;
  }
  @include breakpoints(681,1024){
    margin-bottom: 2rem;
  }
  @include media(screen,680){
    margin-bottom: 1rem;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 5px;
    &.current {
      .page {
        border-color: $black;
        background-color: $black;
        color: $white;
      }
    }
  }
  span,
  .page {
    border: 1px solid $border;
    border-radius: 50%;
    color: $black;
    position: relative;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 30px;

    @include media(hover,1025){
      &:hover {
        border-color: $black;
        background-color: $black;
        color: $white;
      }
    }
  }
  .next,
  .prev {
    width: 30px;
    height: 30px;
    color: $text;
    span,
    .page {
      overflow: hidden;
      text-indent: 999px;
      white-space: nowrap;
      &:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-color: currentColor;
        border-width: 2px;
        border-style: solid solid none none;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    @include media(hover,1025){
      &:hover {
        .page {
          color: $white;
        }
      }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .prev {
    span,
    .page {
      &:before {
        right: 9px;
        transform: rotate(-135deg);
      }
    }
  }
  .next {
    span,
    .page {
      &:before {
        left: 9px;
        transform: rotate(45deg);
      }
    }
  }
}