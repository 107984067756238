.title {
  [data-slide='1'] &,
  [data-slide='3'] &,
  [data-slide='4'] &,
  [data-slide='5'] &,
  [data-slide='6'] &,
  [data-slide='7'] &,
  [data-slide='8'] &,
  [data-slide='9'] & {
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 600;
    .mobile &,
    .tablet & {
      margin-bottom: 4vh;
      @include media(hover,1481){
        font-size: 6.2vh;
      }
      @include breakpoints(1201,1480){
        font-size: 5vh;
      }
      @include breakpoints(768,1200){
        font-size: 4vh;
      }
      @include media(screen,767){
        font-size: 7.8vw;
      }
    }
    .tablet & {
      @include media(screen,767){
        font-size: 5.4vw;
      }
    }
    .desktop & {
      font-size: 3vw;
      margin-bottom: 2vw;
      @media screen and (max-height: 46vw) {
        font-size: 6.5vh;
        margin-bottom: 4vh;
      }

    }
  }
  [data-slide='3'] &,
  [data-slide='4'] &,
  [data-slide='5'] &,
  [data-slide='6'] &,
  [data-slide='7'] &,
  [data-slide='8'] &,
  [data-slide='9'] & {
    .tablet &,
    .mobile & {
      transition: all 0.5s;
      &:not(.active) {
        opacity: 0;
        transform: translateY(50%);
      }
    }
  }
}
.subtitle {
  [data-slide='1'] &,
  [data-slide='3'] &,
  [data-slide='4'] &,
  [data-slide='5'] & {
    line-height: 1.9;
    .mobile &,
    .tablet & {
      @include media(hover,1481){
        font-size: 1.7vh;
      }
      @include breakpoints(1201,1480){
        font-size: 1.7vh;
      }
      @include breakpoints(768,1200){
        font-size: 1.7vh;
      }
      @include media(screen,767){
        font-size: 3.8vw;
      }
    }
    .tablet.portrait & {
      @include media(screen,767){
        font-size: 3vw;
      }
    }
    .desktop & {
      font-size: 0.85vw;
      @media screen and (max-height: 46vw) {
        font-size: 1.6vh;
      }
    }
    @include media(screen,1200){
      line-height: 1.7;
    }
    @include media(screen,767){
      br {
        display: none;
      }
    }
  }
}
.word {
  [data-slide='1'] &,
  [data-slide='3'] &,
  [data-slide='4'] & {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 0.15;
    .mobile &,
    .tablet & {
      @include media(hover,1481){
        font-size: 19vh;
      }
      @include breakpoints(1201,1480){
        font-size: 14vh;
      }
      @include media(screen,1200){
        font-size: 10vh;
      }
    }
    .desktop & {
      font-size: 9.5vw;
      @media screen and (max-height: 46vw) {
        font-size: 19vh;
      }
    }
  }
  .mobile &,
  .tablet.portrait & {
    display: none;
  }
}
.list {
  [data-slide='2'] &,
  [data-slide='5'] & {
    line-height: 1.9;
    //opacity: 0.8;
    .mobile &,
    .tablet & {
      @include media(hover,1481){
        font-size: 1.7vh;
      }
      @include breakpoints(1201,1480){
        font-size: 1.7vh;
      }
      @include breakpoints(768,1200){
        font-size: 1.7vh;
      }
      @include media(screen,767){
        font-size: 3.8vw;
      }
    }
    .tablet.portrait & {
      @include media(screen,767){
        font-size: 3vw;
      }
    }
    .desktop & {
      font-size: 0.85vw;
      @media screen and (max-height: 46vw) {
        font-size: 1.6vh;
      }
    }
    @include media(hover,1481){
      max-width: 80%;
    }
    @include breakpoints(1201,1480){
      max-width: 80%;
    }
    @include breakpoints(768,1200){
      line-height: 1.7;
      max-width: 80%;
    }
    @include media(screen,767){
      line-height: 1.7;
    }
    li {
      position: relative;
      .tablet.portrait & {
        margin: 0.5rem 0;
        @include media(hover,768){
          padding-left: 35px;
          &:before {
            width: 22px;
            top: 1.4vh;
          }
        }
        @include media(screen,767){
          padding-left: 20px;
          &:before {
            width: 15px;
            top: 2.5vw;
          }
        }
      }
      .mobile & {
        margin: 0.5rem 0;
        @include media(hover,768){
          padding-left: 35px;
          &:before {
            width: 22px;
            top: 1vh;
          }
        }
        @include media(screen,767){
          padding-left: 20px;
          &:before {
            width: 15px;
            top: 3vw;
          }
        }
      }
      .tablet.landscape & {
        margin: 0.55vw 0;
        padding-left: 1vw;
        &:before {
          width: 0.3vw;
          top: 1.5vh;
          height: 0.3vw;
          border-radius: 50%;
        }
        @media screen and (max-height: 46vw) {
          margin: 1.2vh 0;
          &:before {
            top: 1.5vh;
          }
        }
      }
      .desktop & {
        margin: 0.55vw 0;
        padding-left: 1vw;
        &:before {
          width: 0.3vw;
          top: .65vw;
          height: 0.3vw;
          border-radius: 50%;
        }
        @media screen and (max-height: 46vw) {
          margin: 1.2vh 0;
          &:before {
            top: 1.5vh;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 1px;
        background-color: #bebebf;
      }
    }
  }
}
.item-title {
  font-weight: 700;
  color: $white;
  .mobile &,
  .tablet.portrait & {
    font-size: 5vw;
    margin-bottom: 2vw;
  }
  .tablet.landscape & {
    margin-bottom: 1.6vh;
    @include media(hover,1481){
      font-size: 3.2vh;
    }
    @include breakpoints(1201,1480){
      font-size: 2.4vh;
    }
    @include media(screen,1200){
      font-size: 2.2vh;
    }
  }
  .desktop & {
    font-size: 1.65vw;
    margin-bottom: 0.7vw;
    @media screen and (max-height: 46vw) {
      font-size: 3.5vh;
      margin-bottom: 2vh;
    }
  }
}
.item-subtitle {
  color: #bebebf;
  .mobile &,
  .tablet.portrait & {
    font-size: 3.8vw;
    line-height: 1.7;
  }
  .tablet.landscape & {
    line-height: 1.9;
    @include media(hover,1481){
      font-size: 1.7vh;
    }
    @include breakpoints(1201,1480){
      font-size: 1.7vh;
    }
    @include media(screen,1200){
      font-size: 1.7vh;
      line-height: 1.7;
    }
  }
  .desktop & {
    line-height: 1.9;
    font-size: 0.85vw;
    @media screen and (max-height: 46vw) {
      font-size: 1.6vh;
    }
  }
}
