.login-form {
  &--title {
    color: $white;
    font-size: 2.56rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.2;
  }
  .submit {
    display: flex;
    justify-content: center;
  }
}