.desktop,
.tablet.landscape {

  [data-current="8"],
  [data-current="9"],
  [data-current="10"] {
    [data-slide='6'] {
      transform: translate3d(0,-100%,0);
    }
  }

  [data-current="6"] {

    //===== slide 5

    [data-slide='5'] {
      .title {
        transition: transform 1.2s ease;
        transform: translate3d(0,8vh,0);
      }
      .subtitle {
        transition: transform 1.2s ease;
        transform: translate3d(0,8vh,0);
      }
      .list {
        transition: transform 1.2s ease;
        transform: translate3d(0,8vh,0);
      }
    }

    //===== slide 6

    [data-slide='6'] {
      transform: translate3d(0,0,0);
      transition: transform 1.2s ease-in-out;
      .title {
        transform: translate3d(0,0,0);
        transition: transform 0.8s ease 0.4s;
      }
      .items {
        .item {
          opacity: 1;
          transform: translate3d(0,0,0);
          &:nth-child(1){
            transition: transform 0.7s ease 0.8s, opacity 0.4s ease 0.7s;
          }
          &:nth-child(2){
            transition: transform 0.7s ease 0.9s, opacity 0.4s ease 0.8s;
          }
          &:nth-child(3){
            transition: transform 0.7s ease 1s, opacity 0.4s ease 0.9s;
          }
        }
      }
    }

    //===== slide 7

    [data-slide='7'] {
      .title {
        transition: transform 0.1s ease 1.3s;
      }
      .items {
        .item {
          &:nth-child(1){
            transition: transform 0.8s ease, opacity 0.1s ease 1.3s;
          }
          &:nth-child(2){
            transition: transform 0.8s ease 0.1s, opacity 0.1s ease 1.3s;
          }
          &:nth-child(3){
            transition: transform 0.8s ease 0.2s, opacity 0.1s ease 1.3s;
          }
        }
      }
    }

  }
}