.about {
  background-color: $dark;
  background-attachment: fixed;
  //background-image: url('../images/about/about-bg.png');
  background-position: center 20rem;
  background-size: 100% auto;
  background-repeat: no-repeat;

  &-head {
    position: relative;
    @include media(hover,1025){
      margin-bottom: 10rem;
      &.team-head {
        margin-bottom: 8rem;
      }
    }
    @include media(screen,1024){
      margin-bottom: 5rem;
      margin-top: 2rem;
      &.team-head {
        margin-bottom: 8rem;
      }
    }
    .page-title {
      color: $white;
    }
    .bg-word {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba($white,0.05);
      line-height: 1;
      transform: translateY(-45%);
      @include media(hover,1025){
        font-size: 13rem;
      }
      @include breakpoints(681,1024){
        font-size: 8rem;
      }
      @include media(screen,680){
        font-size: 6rem;
      }
    }
    .moving-word {
      transition: opacity 0.6s ease;
      &.opacity {
        opacity: 0;
      }
    }
  }
  &-text {
    font-size: 1.6rem;
    line-height: 2;
    @include media(hover,1025){
      margin-bottom: 8rem;
    }
    @include media(screen,1024){
      margin-bottom: 5rem;
    }
    .content {
      position: relative;
      max-width: 720px;
      color: rgba($white,0.8);
    }
    .fingerprint {
      position: absolute;
      opacity: 0.6;
      right: 101%;
      top: 5rem;
      height: 22.5rem;
      width: auto;
    }
    p {
      margin-bottom: 3rem;
    }
  }
  &-team {
    position: relative;
    &--list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &--item {
      @include media(hover,1201){
        width: 33.3333%;
        max-width: 33.3333%;
        padding: 0 55px;
        margin-bottom: 8rem;
      }
      @include breakpoints(768,1200){
        width: 50%;
        max-width: 50%;
        padding: 0 30px;
        margin-bottom: 6rem;
      }
      @include media(screen,767){
        width: 100%;
        max-width: 480px;
        padding: 0 30px;
        margin: 0 auto 5rem;
      }
      .figure {
        width: 100%;
        margin-bottom: 2rem;
        img {
          display: block;
          width: 100%;
        }
      }
    }
    &--person {
      &-name {
        color: $white;
        font-weight: 600;
        margin-bottom: 1rem;
        @include media(hover,1025){
          font-size: 2.16rem;
        }
        @include media(screen,1024){
          font-size: 2rem;
        }
      }
      &-position {
        font-size: 1.152rem;
        color: #b3b3b3;
        line-height: 1.75;
      }
      &-text {
        font-size: 1.152rem;
        color: #b3b3b3;
        line-height: 1.75;
      }
    }
  }
}