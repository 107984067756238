.elements {
  .desktop & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .mobile &,
  .tablet & {
    display: none;
  }
}
.elements-fullwidth {
  .desktop & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .mobile &,
  .tablet & {
    display: none;
  }
}
@import "element1";
@import "element2";
@import "element3";
