.desktop {
  [data-slide='5'] {
    transform: translate3d(0,100%,0);
    .slide-right {
      display: flex;
      align-items: center;
      padding-left: 75px;
    }
    .head {
      width: 80%;
    }
    .title {
      color: $white;
      span {
        display: block;
      }
      .part1 {

      }
      .part2 {
        color: #b3b3b3;
      }
    }
    .subtitle {
      color: #bebebf;
      opacity: 0.8;
      @include media(hover,1481){
        max-width: 70%;
      }
      @include breakpoints(1201,1480){
        max-width: 80%;
      }
    }
    .list {
      color: $white;
      margin-top: 2vh;
      @include media(hover,1481){
        max-width: 70%;
      }
      @include breakpoints(1201,1480){
        max-width: 80%;
      }
    }
    .tablet-light,
    .slide-img {
      display: none;
    }
  }
}

.tablet.landscape {
  [data-slide='5'] {
    padding: 15vh 0 24vh;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 0;
      width: 1px;
      background-color: $white;
      left: 50%;
      opacity: 0.2;
    }
    .tablet-light {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 80px 7px rgba($white,0.95);
      position: absolute;
      left: 1px;
      right: 0;
      top: 20vh;
      z-index: 1;
      margin: -4px auto 0;
      animation: small_light 2s infinite linear alternate;
    }
    .slide-right {
      display: flex;
      align-items: center;
      padding-left: 75px;
    }
    .head {
      width: 80%;
    }
    .title {
      color: $white;
      span {
        display: block;
      }
      .part1 {

      }
      .part2 {
        color: #b3b3b3;
      }
    }
    .subtitle {
      color: #bebebf;
      opacity: 0.8;
      @include media(hover,1481){
        max-width: 70%;
      }
      @include breakpoints(1201,1480){
        max-width: 80%;
      }
    }
    .list {
      color: $white;
      margin-top: 2vh;
      @include media(hover,1481){
        max-width: 70%;
      }
      @include breakpoints(1201,1480){
        max-width: 80%;
      }
    }
    .slide-img {
      position: absolute;
      left: 2%;
      top: 4%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .people-box {
        width: 90%;
        position: relative;
        max-width: 65vh;
      }
      .part {
        width: 100%;
        padding-top: 92.476%;
        svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &.absolute {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .icons {
        .group {
          transform-origin: 50% 50%;
          transform: rotate(20deg);
        }
      }
    }
  }
}

.mobile,
.tablet.portrait {
  [data-slide='5'] {
    padding: 20px;
    margin-bottom: 90px;
    &:after {
      content: "";
    }
    .title {
      color: $white;
      span {
        display: block;
      }
      .part2 {
        color: #b3b3b3;
      }
    }
    .subtitle {
      color: #bebebf;
      margin-bottom: 2vh;
    }
    .list {
      color: $white;
    }
    .slide-img {
      padding: 2% 0 10% 0;
      display: flex;
      justify-content: center;
      .people-box {
        width: 90%;
        position: relative;
      }
      .part {
        width: 100%;
        padding-top: 92.476%;
        svg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        &.absolute {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .icons {
        .group {
          transform-origin: 50% 50%;
          transform: rotate(20deg);
        }
      }
    }
    .tablet-light {
      display: none;
    }
  }
}

.tablet,
.mobile {
  [data-slide='5'] {
    .man_left_p1,
    .man_left_p2,
    .man_left_p3,
    .man_left_p4,
    .man_left_p5,
    .key_p1,
    .key_p2,
    .key_p3,
    .key_p4,
    .key_p5,
    .key_p6 {
      transition: stroke-dashoffset 0.5s linear;
    }
    .lines,
    .icons,
    .gadget {
      opacity: 0;
      transition: opacity 0.5s linear;
    }
    .man_left_p1 {
      stroke-dasharray: 209.985;
      stroke-dashoffset: -209.985;
    }
    .man_left_p2 {
      stroke-dasharray: 41.6786;
      stroke-dashoffset: 41.6786;
    }
    .man_left_p3 {
      stroke-dasharray: 196.337;
      stroke-dashoffset: 196.337;
    }
    .man_left_p4 {
      stroke-dasharray: 211.333;
      stroke-dashoffset: 211.333;
    }
    .man_left_p5 {
      stroke-dasharray: 68.885;
      stroke-dashoffset: 68.885;
    }
    .key_p1 {
      stroke-dasharray: 110.257;
      stroke-dashoffset: 110.257;
    }
    .key_p2 {
      stroke-dasharray: 66.565;
      stroke-dashoffset: 66.565;
    }
    .key_p3 {
      stroke-dasharray: 53.532;
      stroke-dashoffset: 53.532;
    }
    .key_p4 {
      stroke-dasharray: 21.219;
      stroke-dashoffset: 21.219;
    }
    .key_p5 {
      stroke-dasharray: 47.874;
      stroke-dashoffset: 47.874;
    }
    .key_p6 {
      stroke-dasharray: 28.155;
      stroke-dashoffset: 28.155;
    }

    .slide-img.active {
      .man_left_p2,
      .man_left_p1,
      .man_left_p3,
      .man_left_p4,
      .man_left_p5,
      .key_p1,
      .key_p2,
      .key_p3,
      .key_p4,
      .key_p5,
      .key_p6 {
        stroke-dashoffset: 0;
      }
      .lines,
      .icons,
      .gadget {
        opacity: 1;
      }
    }

  }
}