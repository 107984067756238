.landing {
  width: 100%;
  overflow: hidden;
  background-color: $dark;
  .desktop & {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  .tablet.portrait & {
    padding: 80px 0 0;
  }
  .mobile & {
    padding: 60px 0 0;
  }
  .slide {
    .desktop & {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      transition: transform 1.4s ease-in-out;
      &[data-slide='10'] {
        max-width: none;
      }
      &-left {
        width: 50%;
        height: 100%;
        padding: 25px;
      }
      &-right {
        width: 50%;
        height: 100%;
        padding: 1.4vw;
      }
    }
    .tablet.landscape & {
      position: relative;
      margin: auto;
      width: 100vw;
      //overflow: hidden;
      display: flex;
      transition: transform 1.4s ease-in-out;
      &[data-slide='10'] {
        max-width: none;
      }
      &-left {
        width: 50%;
        height: 100%;
        padding: 25px;
      }
      &-right {
        width: 50%;
        height: 100%;
        padding: 1.4vw;
      }
    }
    .mobile &,
    .tablet.portrait & {
      position: relative;
      &:after {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 100%;
        margin-top: 45px;
        height: 1px;
        background-color: rgba($white,0.5);
      }
    }
  }
}