.desktop,
.tablet.landscape {

  [data-current="6"],
  [data-current="7"],
  [data-current="8"],
  [data-current="9"],
  [data-current="10"] {
    [data-slide='5'] {
      transform: translate3d(0,-100%,0);
    }
  }

  [data-current="5"] {

    //===== slide 4

    [data-slide='4'] {
      .title {
        transition: transform 1.2s ease;
        transform: translate3d(0,8vh,0);
      }
      .subtitle {
        transition: transform 1.2s ease;
        transform: translate3d(0,8vh,0);
      }
    }

    //===== slide 5

    [data-slide='5'] {
      transform: translate3d(0,0,0);
      .title {
        transition: transform 1.2s ease;
        .part1 {
          transition: all 0.8s ease 0.4s;
        }
        .part2 {
          transition: all 0.8s ease 0.5s;
        }
      }
      .subtitle {
        transition: all 0.8s ease 0.6s;
      }
      .list {
        transition: all 0.8s ease 0.6s;
      }
    }

    //===== slide 6

    [data-slide='6'] {
      .title {
        transition: transform 0.1s ease 1.3s;
      }
      .items {
        .item {
          &:nth-child(1){
            transition: transform 0.8s ease, opacity 0.1s ease 1.3s;
          }
          &:nth-child(2){
            transition: transform 0.8s ease 0.1s, opacity 0.1s ease 1.3s;
          }
          &:nth-child(3){
            transition: transform 0.8s ease 0.2s, opacity 0.1s ease 1.3s;
          }
        }
      }
    }

  }
}