.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transition: all 0.35s;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &-wrap {
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    .close {
      color: $white;
      position: absolute;
      top: 25px;
      right: 25px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 40px;
      font-weight: 300;
      cursor: pointer;
      .mobile & {
        top: 5px;
        right: 5px;
      }
    }
    .mobile & {
      align-items: flex-start;
      overflow: auto;
    }
  }
  &[data-modal="request"]{
    .modal-wrap {
      background-color: #24262f;
      @include media(hover,601){
        top: 10px;
        right: 10px;
        left: 10px;
        bottom: 10px;
      }
      @include media(screen,600){
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }

}