.desktop {
  [data-slide='9'] {
    transform: translate3d(0,100%,0);
    &.has-footer {
      z-index: 1;
    }
    .head {
      width: 40%;
      left: 50%;
      padding-left: 75px;
      padding-right: 25px;
      position: absolute;
      bottom: 72vh;
    }
    .title {
      color: $white;
      position: relative;
      z-index: 2;
      transform: translate3d(0,100%,0);
    }
    .brands {
      position: absolute;
      top: 20vh;
      left: 50px;
      right: 50px;
      bottom: 20vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2vw 5vw;
      .brand {
        width: 25%;
        max-width: 25%;
        padding: 0 25px;
        display: flex;
        justify-content: center;
        transform: translate3d(0,50%,0);
        opacity: 0;
        img {
          max-width: 100%;
          height: 22vh;
          width: auto;
        }
      }
    }
    .tablet-light {
      display: none;
    }
  }
}

.tablet.landscape {
  [data-slide='9'] {
    padding: 15vh 0;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 10vh;
      width: 1px;
      background-color: $white;
      opacity: 0.2;
      left: 50%;
    }
    flex-direction: column;
    &.has-footer {
      z-index: 1;
      padding-bottom: 0;
      flex-direction: column;
      .brands {
        margin-bottom: 5vh;
      }
    }
    .head {
      width: 40%;
      left: 50%;
      padding-left: 75px;
      padding-right: 25px;
      position: relative;
    }
    .title {
      color: $white;
      position: relative;
      z-index: 2;
    }
    .brands {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2vw 7vw;
      .brand {
        width: 25%;
        max-width: 25%;
        padding: 0 25px;
        display: flex;
        justify-content: center;
        img {
          max-width: 100%;
          height: 22vh;
          width: auto;
        }

      }
    }
    .tablet-light {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 80px 7px rgba($white,0.95);
      position: absolute;
      left: 1px;
      right: 0;
      top: 10vh;
      z-index: 1;
      margin: -4px auto 0;
      animation: small_light 2s infinite linear alternate;
    }
  }
}

.mobile,
.tablet.portrait {
  [data-slide='9'] {
    padding: 20px;
    margin-bottom: 45px;
    &.has-footer {
      padding: 0;
      margin: 0;
      .title {
        padding: 20px 20px 0;
      }
      .brands {
        padding: 0 20px 40px;
      }
    }
    .title {
      color: $white;
    }
    .brands {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .brand {
        width: 50%;
        padding: 0 10px;
        text-align: center;
        img {
          width: 100%;
          max-width: 200px;
          height: auto;
        }
      }
    }
    .tablet-light {
      display: none;
    }
  }
}