.desktop,
.tablet.landscape {

  [data-current="10"] {
    [data-slide='9'] {
      transform: translate3d(0,-100%,0);
    }
  }

  [data-current="9"] {


    //===== slide 8

    [data-slide='8'] {
      .title {
        transition: transform 0.4s ease 0.4s;
      }
    }

    //===== slide 9

    [data-slide='9'] {
      transform: translate3d(0,0,0);
      .title {
        transform: translate3d(0,0,0);
        transition: transform 0.8s ease 0.4s;
      }
      .brands {
        .brand {
          transform: translate3d(0,0,0);
          opacity: 1;
          &:nth-child(1){
            transition: all 0.5s ease 0.8s;
          }
          &:nth-child(2){
            transition: all 0.5s ease 0.85s;
          }
          &:nth-child(3){
            transition: all 0.5s ease 0.9s;
          }
          &:nth-child(4){
            transition: all 0.5s ease 0.95s;
          }
        }
      }
    }

  }
}