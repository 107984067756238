.desktop {
  [data-slide='10'] {
    transform: translate3d(0,100%,0);
    background-color: $white;
    display: flex;
    flex-direction: column;
    z-index: 2;
    .content--md {
      @include media(hover,1921){
        max-width: 90%;
      }
    }
    .tech {
      position: absolute;
      left: 0;
      right: 0;
      top: 10vh;
      bottom: 20vh;
      display: flex;
      align-items: center;
    }
    .section {
      margin: 0 auto;
      @include media(screen,1200){
        padding-top: 50px;
      }
    }
    .page-title {
      font-size: 3vw;
      @media screen and (max-height: 46vw) {
        font-size: 6.5vh;
      }
    }
    .article--data {
      padding-left: 1.75vw;
    }
    .article--date {
      font-size: 0.7vw;
      @media screen and (max-height: 46vw) {
        font-size: 1.4vh;
      }
    }
    .article--tag {
      font-size: 0.8vw;
      @media screen and (max-height: 46vw) {
        font-size: 1.5vh;
      }
    }
    .article--title {
      font-size: 1.65vw;
      @media screen and (max-height: 46vw) {
        font-size: 3.5vh;
      }
    }
    .article--descr {
      font-size: 0.85vw;
      @media screen and (max-height: 46vw) {
        font-size: 1.6vh;
      }
    }
    .footer--main {
      @include media(screen,1200){
        min-height: 80px;
      }
    }
  }
}

.tablet.landscape {
  [data-slide='10'] {
    background-color: $white;
    display: flex;
    flex-direction: column;
    z-index: 2;
    .content--md {
      @include media(hover,1921){
        max-width: 90%;
      }
    }
  }
}

.mobile,
.tablet.portrait {
  [data-slide='10'] {
    background-color: $white;
  }
}
