.blog {
  &--filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media(screen,1024){
      flex-direction: column;
    }
  }
  &--tags {

    @include media(hover,1025){
      margin-bottom: 1.32*$padding;
    }
    @include breakpoints(681,1024){
      margin-bottom: $padding;
    }
    @include media(screen,680){
      margin-bottom: 0.75*$padding;
    }

    ul {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
    }
    li {
      white-space: nowrap;
      @include media(hover,1201){
        margin: 0 $padding/2.5 $padding/2.5 0;
      }
      @include breakpoints(681,1200){
        margin: 0 $padding/4 $padding/4 0;
      }
      @include media(screen,680){
        margin: 0 $padding/6 $padding/6 0;
      }
    }
    @include media(screen,1024){
      order: 1;
    }
    @include media(screen,680){
      .btn--dark {
        padding: 7px 10px;
        font-size: 0.85rem;
      }
    }
  }
  &--search {
    min-width: 250px;
    @include media(hover,1025){
      margin-left: $padding/2;
    }
    @include media(screen,1024){
      margin-bottom: $padding/2;
      margin-left: auto;
    }
    @include media(hover,681){
      width: 30%;
    }
    @include media(screen,680){
      width: 100%;
    }
    form {
      width: 100%;
    }
    &-field {
      width: 100%;
      display: flex;
      position: relative;
      input {
        width: 100%;
        border-color: $dark;
        border-width: 1px;
        border-style: solid;
        border-radius: 50px;
        outline: none;
        font-size: 1rem;
        line-height: 1;
        padding: 8px 50px 8px 20px;
        color: $dark;
        .has-text & {
          padding-right: 70px;
        }
      }
      .submit {
        position: absolute;
        top: 0;
        margin: auto;
        bottom: 0;
        right: 0;
        display: flex;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        border-radius: 50%;
        width: calc(1rem + 27px);
        height: calc(1rem + 20px);
        color: $dark;
        padding: 3px 10px 3px 3px;
      }
      .reset {
        position: absolute;
        background: transparent;
        border: none;
        right: calc(1rem + 30px);
        margin: auto;
        top: 0;
        bottom: 0;
        width: 25px;
        height: 25px;
        line-height: 22px;
        font-size: 2.2rem;
        color: $text;
        opacity: 0;
        outline: none;
        pointer-events: none;
      }
      .has-text & {
        .reset {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  &--articles {
    .grid {
      position: relative;
      flex-flow: row wrap;
      @include media(hover,1025){
        margin: 0 -0.8*$padding;
      }
      @include breakpoints(681,1024){
        margin: 0 -0.65*$padding;
      }
    }
    &.more {
      .grid {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      .article {
        margin-bottom: 45px;
      }
    }
  }
}